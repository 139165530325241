import React from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { MainButton, QueueClock, ScreenLoader, SecondaryButton, TextInput } from "../../components";
import { CpfFormatter, CheckoutOrder, View, HOME_ID, SIGNIN_ID, PIX_PAYMENT_ID, QUEUE_ID, ApiCheckoutError, EVENT_ID, REVIEW_SUMMARY_ID } from "../../shared";
import { QRCodeSVG } from "qrcode.react";
import { AccountBox, Person } from "@mui/icons-material";
import { ApiContext, NavigationContext, NotificationsContext, QueueContext, ThemeContext } from "../../contexts";
import "./paymentview.scss";

class PixPaymentView extends View {
    id = PIX_PAYMENT_ID;
    route = "/checkout/payment/:eventId/pix";
    defaultRoute = false;
    authNeeded = true;
    backClick = () => {};
    header = {
        backClick: () => { this.backClick(); },
        supportClick: () => {}
    };
    render = () => {
        const params = this.params = useParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [queueExpiration, setQueueExpiration] = React.useState<number>();
        const [queueExpired, setQueueExpired] = React.useState<boolean>(false);
        const [invoiceCode, setInvoiceCode] = React.useState<string>();
        const [invoiceExpiration, setInvoiceExpiration] = React.useState<number>();
        const [invoiceExpired, setInvoiceExpired] = React.useState<boolean>(false);
        const [copied, setCopied] = React.useState<boolean>(false);

        const [name, setName] = React.useState<string>("");
        const nameRef = React.useRef<HTMLInputElement>(null);

        const [cpf, setCpf] = React.useState<string>("");
        const [cpfCursor, setCpfCursor] = React.useState<number>(0);
        const cpfRef = React.useRef<HTMLInputElement>(null);
        const cpfFormatter = new CpfFormatter();

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { getParsedQueueToken, abandonQueue } = React.useContext(QueueContext);
        const { navigate, views, goTo } = this.navigation = React.useContext(NavigationContext);
        const { theme } = React.useContext(ThemeContext);

        let timer: NodeJS.Timer;

        const getIds = () => {
            let eventId = params["eventId"];

            if(eventId === undefined) {
                navigate(-1);
            }

            return { eventId: eventId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            const { eventId } = getIds();

            return (error: ApiCheckoutError) => {
                switch(error.errorType) {
                    case "QUEUE_WAITING":
                        goTo(views[QUEUE_ID], { eventId }, { ref: this.parsedRoute });
                        break;
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID]);
                        break;
                    case "FETCH_GENERIC_ERROR":
                        // what to do?
                        break;
                }
                return response;
            }
        }

        const processOrderStatus = (order: CheckoutOrder | null) => {
            const { eventId } = getIds();

            if(!!order) {
                switch(order.status) {
                    case "PaymentApproved":
                    case "Completed":
                        window.localStorage.removeItem(`order-${eventId}`);
                        pushNotification("Pagamento aprovado! Seu passe está na sua carteira.");
                        abandonQueue(params["eventId"]!);
                        if(!!timer) {
                            clearInterval(timer);
                        }
                        goTo(views[HOME_ID]);
                        break;
                }
            }
        }

        const loadCustomerData = async () => {
            const failableCustomerData = await api.customer.getCustomerData();
            return failableCustomerData.match({
                success: customerData => customerData,
                failure: handleError(null)
            });
        }

        const loadOrder = async (eventId: string) => {
            const failableCheckoutOrder = await api.checkout.getCheckoutOrder(eventId);
            return failableCheckoutOrder.match({
                success: order => order,
                failure: handleError(null)
            });
        }

        const loadOrderById = async (orderId: string) => {
            const failableCheckoutOrder = await api.checkout.getCheckoutOrderById(orderId);
            return failableCheckoutOrder.match({
                success: order => order,
                failure: handleError(null)
            });
        }

        const init = () => {
            const { eventId } = getIds();

            loadCustomerData().then(customerData => {
                if(!!customerData) {
                    setName(customerData.name || "");
                    setCpf(cpfFormatter.mask(customerData.taxId || "") ?? "");
                }

                const handleOrder = (order: CheckoutOrder | null) => {
                    if(!!order) {
                        if(!!order.invoiceCode && !!order.invoiceExpiration) {
                            setInvoiceCode(order.invoiceCode);
                            setInvoiceExpiration(new Date(Date.parse(order.invoiceExpiration)).getTime() / 1000);
                        }
                        if(!!timer) {
                            clearInterval(timer);
                        }
                        timer = setInterval(() => {
                            loadOrderById(order.id).then(processOrderStatus);
                        }, 1500);
                        setQueueExpiration(getParsedQueueToken(eventId)?.exp);
                        setFirstLoad(false);
                    } else {
                        goTo(views[EVENT_ID], { eventId })
                    }
                }

                const storedOrderId = window.localStorage.getItem(`order-${eventId}`);
                if(!!storedOrderId) {
                    loadOrderById(storedOrderId).then(order => {
                        if(!!order && ["Canceled", "Expired", "PaymentApproved", "Completed"].indexOf(order.status) === -1) {
                            handleOrder(order);
                        } else {
                            window.localStorage.removeItem(`order-${eventId}`);
                            loadOrder(eventId).then(handleOrder);
                        }
                    });
                } else {
                    loadOrder(eventId).then(handleOrder);
                }
            });

            return () => {
                if(timer != undefined) {
                    clearInterval(timer);
                }
            }
        }

        const expire = () => {
            const { eventId } = getIds();
            if(invoiceExpired && !queueExpired) {
                giveUpPurchase();
            } else if(queueExpired && (!invoiceExpiration || invoiceExpired)) {
                goTo(views[EVENT_ID], {eventId});
            }
        }

        React.useEffect(init, []);
        React.useEffect(expire, [queueExpired, invoiceExpired]);

        const changeNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setName(e.currentTarget.value);
            e.preventDefault();
        }

        const changeCpfHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = cpfFormatter.updateValue({text: cpf, cursor: cpfCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setCpf(updatedValue.text);
            setCpfCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = cpfRef.current;
            if(input != null) {
                input.setSelectionRange(cpfCursor, cpfCursor);
            }
        }, [cpfRef, cpfCursor, cpf]);

        const generatePix = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const { eventId } = getIds();

            const failablePixPayment = await api.checkout.choosePixPayment(eventId, cpfFormatter.unmask(cpf), name);
            const success = failablePixPayment.match({
                success: pendingOrder => {
                    window.localStorage.setItem(`order-${eventId}`, pendingOrder.id);
                    if(!!pendingOrder.invoiceCode && !!pendingOrder.invoiceExpiration) {
                        setInvoiceCode(pendingOrder.invoiceCode);
                        setInvoiceExpiration(new Date(Date.parse(pendingOrder.invoiceExpiration)).getTime() / 1000);
                    }
                    return true;
                },
                failure: handleError(false)
            });
            if(!success) {
                pushNotification("CPF inválido.");
            }
            e.preventDefault();
        }

        const copyPix = async (e: React.MouseEvent<HTMLButtonElement>) => {
            navigator.clipboard.writeText(invoiceCode ?? "");
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 3000)
            e.preventDefault();
        }

        const giveUpPurchase = this.backClick = async (e?: React.MouseEvent<HTMLButtonElement>) => {
            const { eventId } = getIds();
            const storedOrderId = window.localStorage.getItem(`order-${eventId}`);
            if(!!storedOrderId) {
                const failableRemoveOrder = await api.checkout.removeCheckoutOrderById(storedOrderId);
                failableRemoveOrder.match({
                    success: () => {
                        window.localStorage.removeItem(`order-${eventId}`);
                    },
                    failure: () => {}
                });
            }
            navigate(-1);
            e?.preventDefault();
        }

        const queueExpire = () => {
            setQueueExpired(true);
        }

        const invoiceExpire = () => {
            setInvoiceExpired(true);
        }

        return firstLoad ? <ScreenLoader /> : <div id="payment">
            {invoiceExpiration === undefined ?
                <QueueClock expirationTime={queueExpiration} onExpire={queueExpire} /> :
                <div id="payment-deadline">
                    Efetue o pagamento via PIX dentro do prazo abaixo:
                    <QueueClock expirationTime={invoiceExpiration} onExpire={invoiceExpire} />
                </div>
            }
            <form id="payment-form">
                {!invoiceCode ? <>
                    <div className="payment-form-field">
                        <div className="label">Name</div>
                        <TextInput ref={nameRef} id="payment-name" name="name" value={name} prefix={<Person sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Seu nome" onChange={changeNameHandle} />
                    </div>
                    <div className="payment-form-field">
                        <div className="label">CPF do pagador</div>
                        <TextInput ref={cpfRef} id="payment-cpf" name="cpf" inputMode="numeric" value={cpf} prefix={<AccountBox sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000.000.000-00" onChange={changeCpfHandle} />
                    </div>
                    <div className="pay-button-container">
                        <MainButton content="Gerar PIX" onClick={generatePix} />
                    </div>
                </> : <>
                    <div className="qr-code-explain">
                        <b>PIX para pagamento:</b>
                        <span className="light">(a confirmação pode demorar até 3 minutos)</span>
                    </div>
                    <div className="qr-code-holder">
                        <QRCodeSVG className="qr-code" fgColor={theme === "dark" ? "#fff" : "#18201b"} bgColor="transparent" value={invoiceCode} />
                        <MainButton enabled={!copied} content={<><FontAwesomeIcon icon={copied ? faCheck : faCopy} /> {copied ? "Código PIX copiado!" : "Copiar código PIX"}</>} onClick={copyPix} />
                    </div>
                </>}
            </form>
        </div>;
    }
}

export { PixPaymentView };