import { MaskFormatter } from "./maskformatter";

class PhoneFormatter extends MaskFormatter {
    unmask(maskedText: string): string {
        return maskedText.replaceAll(/[\(\)\- ]/g, "");
    }

    // Change to be generic for all countries
    mask(unmaskedText: string): string | null {
        if(unmaskedText == "") {
            return unmaskedText;
        }
    
        let match;
    
        // US
        match = unmaskedText.match(/^\+1([0-9]{3})([0-9]{3})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
        }
    
        match = unmaskedText.match(/^\+1([0-9]{3})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+1 (${match[1]}) ${match[2]}`;
        }
    
        match = unmaskedText.match(/^\+1([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+1 (${match[1]}`;
        }
    
        // Spain
        match = unmaskedText.match(/^\+34([0-9]{3})([0-9]{3})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+34 (${match[1]}) ${match[2]}-${match[3]}`;
        }
    
        match = unmaskedText.match(/^\+34([0-9]{3})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+34 (${match[1]}) ${match[2]}`;
        }
    
        match = unmaskedText.match(/^\+34([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+34 (${match[1]}`;
        }
    
        // Portugal
        match = unmaskedText.match(/^\+351([0-9]{2})([0-9]{3})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+351 (${match[1]}) ${match[2]}-${match[3]}`;
        }
    
        match = unmaskedText.match(/^\+351([0-9]{2})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+351 (${match[1]}) ${match[2]}`;
        }
    
        match = unmaskedText.match(/^\+351([0-9]{1,2})$/);
        if(match != null && match.length != 0) {
            return `+351 (${match[1]}`;
        }
    
        // Australia
        match = unmaskedText.match(/^\+61([0-9]{1})([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+61 ${match[1]} ${match[2]}-${match[3]}`;
        }
    
        match = unmaskedText.match(/^\+61([0-9]{1})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+61 ${match[1]} ${match[2]}`;
        }
    
        match = unmaskedText.match(/^\+61([0-9]{1})$/);
        if(match != null && match.length != 0) {
            return `+61 ${match[1]}`;
        }
    
        // Brasil
        match = unmaskedText.match(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/);
        if(match != null && match.length != 0) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
    
        match = unmaskedText.match(/^([0-9]{2})([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
    
        match = unmaskedText.match(/^([0-9]{2})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `(${match[1]}) ${match[2]}`;
        }
    
        match = unmaskedText.match(/^([0-9]{1,2})$/);
        if(match != null && match.length != 0) {
            return `(${match[1]}`;
        }
    
    
        match = unmaskedText.match(/^\+55([0-9]{2})([0-9]{4,5})([0-9]{4})$/);
        if(match != null && match.length != 0) {
            return `+55 (${match[1]}) ${match[2]}-${match[3]}`;
        }
    
        match = unmaskedText.match(/^\+55([0-9]{2})([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+55 (${match[1]}) ${match[2]}-${match[3]}`;
        }
    
        match = unmaskedText.match(/^\+55([0-9]{2})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+55 (${match[1]}) ${match[2]}`;
        }
    
        match = unmaskedText.match(/^\+55([0-9]{1,2})$/);
        if(match != null && match.length != 0) {
            return `+55 (${match[1]}`;
        }
    
        // General
        match = unmaskedText.match(/^(\+[0-9]{0,3})$/);
        if(match != null && match.length != 0) {
            return match[1];
        }
    
        return null;
    }
}

export { PhoneFormatter };