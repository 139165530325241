import React from "react";
import md5 from "md5";
import { QRCodeSVG } from "qrcode.react";
import { DateTime } from "luxon";
import { AccessTime, CancelOutlined, LocationOn, PlayArrow, WhatsApp } from "@mui/icons-material";
import { HOME_ID, TRANSFER_SEND_ID, TransferOrder, View } from "../../shared";
import { MainButton, ScreenLoader, SecondaryButton } from "../../components";
import { ApiContext, CustomerContext, NavigationContext, NotificationsContext, ThemeContext } from "../../contexts";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import "./transfersendview.scss";

interface TransferringItems {
    [eventSessionId: string]: {
        eventSessionName: string,
        image?: string,
        venueName: string,
        startDate: string,
        endDate: string,
        tickets: Array<{
            batchId: string,
            batchName: string,
            quantity: number,
            price: number,
        }>,
    }
}

class TransferSendView extends View {
    id = TRANSFER_SEND_ID;
    route = "/wallet/transfer/send/:transferId";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[HOME_ID]); }
    };
    render = () => {
        const params = this.params = useParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [transferOrder, setTransferOrder] = React.useState<TransferOrder>();
        const [copied, setCopied] = React.useState<boolean>(false);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { customer } = React.useContext(CustomerContext);
        const { goTo, views } = this.navigation = React.useContext(NavigationContext);
        const { theme } = React.useContext(ThemeContext);

        const getIds = () => {
            const transferId = params["transferId"];

            if(!transferId) {
                goTo(views[HOME_ID]);
            }

            return { transferId: transferId ?? "" };
        }

        const loadTransfer = async (transferId: string) => {
            const failableTransfer = await api.transfer.getTransfer(transferId);
            return failableTransfer.match({
                success: transfer => transfer,
                failure: () => null,
            });
        }

        const init = () => {
            const { transferId } = getIds();
            loadTransfer(transferId).then(transferOrder => {
                if(!!transferOrder) {
                    setTransferOrder(transferOrder);
                } else {
                    pushNotification("Transferência inexistente ou expirada.");
                    goTo(views[HOME_ID]);
                }
                setFirstLoad(false);
            });
        }

        React.useEffect(init, []);

        const getTransferringItems = () => {
            const items: TransferringItems = {};
            if(!!transferOrder) {
                for(let i = 0; i < transferOrder.passes.length; i++) {
                    const pass = transferOrder.passes[i];
                    if(Object.keys(items).indexOf(pass.eventSession.id) === -1) {
                        items[pass.eventSession.id] = {
                            eventSessionName: pass.eventSession.name,
                            image: pass.event.smallImageUrl,
                            venueName: pass.event.venue.name,
                            startDate: pass.eventSession.startDate,
                            endDate: pass.eventSession.endDate,
                            tickets: [],
                        };
                    }

                    if(!items[pass.eventSession.id].tickets.some(ticket => ticket.batchId === pass.batch.id)) {
                        items[pass.eventSession.id].tickets.push({
                            batchId: pass.batch.id,
                            batchName: pass.batch.name,
                            quantity: 1,
                            price: pass.batch.price,
                        });
                    } else {
                        items[pass.eventSession.id].tickets = items[pass.eventSession.id].tickets.map(ticket => {
                            if(ticket.batchId === pass.batch.id) {
                                return { ...ticket, quantity: ticket.quantity + 1 };
                            }
                            return ticket;
                        });
                    }
                }
            }
            return items;
        }

        const cancelTransfer = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const { transferId } = getIds();

            const failableCancel = await api.transfer.cancelTransfer(transferId);
            failableCancel.match({
                success: received => {
                    if(received) {
                        pushNotification("Transferência cancelada!");
                        goTo(views[HOME_ID]);
                    }
                },
                failure: () => {
                    pushNotification("Erro ao cancelar transferência.");
                }
            });

            e.preventDefault();
        }

        const receiveTransferUrl = `https://${process.env.NODE_ENV === "test" ? "uat." : ""}m.passify.co/wallet/transfer/receive/${getIds()["transferId"]}`;

        const copyTransferLink = async (e: React.MouseEvent<HTMLButtonElement>) => {
            navigator.clipboard.writeText(receiveTransferUrl);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 3000)
            e.preventDefault();
        }

        const sendTransferLink = async (e: React.MouseEvent<HTMLButtonElement>) => {
            window.open(`https://wa.me/?text=${encodeURIComponent(`Abra o link para receber o(s) ingresso(s) que estou lhe enviando: ${receiveTransferUrl}`)}`);
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="transfer-send">
            {!!transferOrder?.recipient ?
                <>
                    <div id="transfer-send-caption"><span>Solicitação de transferência enviada para <span className="recipient">{transferOrder.recipient}</span>!</span></div>
                    <div id="transfer-send-peers">
                        <div id="transfer-send-peers-sender" className="transfer-send-peer">
                            <div className="transfer-send-peer-image">
                                {!!transferOrder?.senderPicture ?
                                    <img src={transferOrder.senderPicture} /> :
                                    !!transferOrder?.canCancel && !!customer?.email ?
                                        <img src={`https://gravatar.com/avatar/${md5(customer.email)}?d=retro`} /> :
                                        <span className="unknown-peer">?</span>
                                }
                            </div>
                            <div className="transfer-send-peer-name">
                                {!!transferOrder?.sender ?
                                    transferOrder.sender :
                                    (!!transferOrder?.canCancel && !!customer?.email) ?
                                        customer?.name ?? customer?.email :
                                        <span className="unknown-sender">Remetente desconhecido</span>
                                }
                            </div>
                        </div>
                        <PlayArrow />
                        <div id="transfer-send-peers-recipient" className="transfer-send-peer">
                            <div className="transfer-send-peer-image">
                                {!!transferOrder?.recipientPicture ?
                                    <img src={transferOrder.recipientPicture} /> :
                                    !!transferOrder?.canReceive && !!customer?.email ?
                                        <img src={`https://gravatar.com/avatar/${md5(customer.email)}?d=retro`} /> :
                                        <span className="unknown-peer">?</span>
                                }
                            </div>
                            <div className="transfer-send-peer-name">
                                {!!transferOrder?.recipient ?
                                    transferOrder.recipient :
                                    (!!transferOrder?.canReceive && !!customer?.email) ?
                                        customer?.name ?? customer?.email :
                                        <span className="unknown-recipient">Destinatário desconhecido</span>
                                }
                            </div>
                        </div>
                    </div>
                    {Object.values(getTransferringItems()).map(item =>
                        <div className="transfer-send-item">
                            <div className="transfer-send-item-session">
                                <div className="transfer-send-item-image">
                                    <img src={item.image} />
                                </div>
                                <div className="transfer-send-item-session-info">
                                    <div className="transfer-send-item-name">{item.eventSessionName}</div>
                                    <div className="transfer-send-item-date">
                                        <span className="icon"><AccessTime sx={{width: 16, height: 16}} /></span> {DateTime.fromMillis(Date.parse(item.startDate)).toFormat("ccc, d LLL yyyy", { locale: "pt-br" })}
                                    </div>
                                    <div className="transfer-send-item-location">
                                        <span className="icon"><LocationOn sx={{width: 16, height: 16}} /></span> {item.venueName}
                                    </div>
                                </div>
                            </div>
                            <div className="transfer-send-item-batches">
                                {item.tickets.map(ticket => <div className="transfer-send-item-batch">{ticket.quantity}x {ticket.batchName}</div>)}
                            </div>
                        </div>
                    )}
                    {!!transferOrder?.canCancel && <MainButton content={"Cancelar transferência"} onClick={cancelTransfer} />}
                </> :
                <>
                    <div id="transfer-send-caption">Compartilhe o link abaixo<br />para transferir seu(s) ingresso(s).</div>
                    <div id="transfer-send-qr-code-holder">
                        <QRCodeSVG className="qr-code" fgColor={theme === "dark" ? "#fff" : "#18201b"} bgColor="transparent" value={receiveTransferUrl} />
                        <MainButton enabled={!copied} content={<><FontAwesomeIcon icon={copied ? faCheck : faCopy} /> {copied ? "Endereço do link copiado!" : "Copiar endereço do link"}</>} onClick={copyTransferLink} />
                        <SecondaryButton content={<><WhatsApp /> Enviar por WhatsApp</>} onClick={sendTransferLink} />
                        <SecondaryButton content={<><CancelOutlined /> Cancelar transferência</>} onClick={cancelTransfer} />
                    </div>
                </>
            }
        </div>;
    }
}

export { TransferSendView };