import React from "react";
import { IFailable, failableAsync } from "ts-failable";
import { ApiAuthError, Auth, PassifyApi, executeRecaptcha } from "../../shared";
import { AuthContext } from "../auth";

type F<T> = IFailable<T, ApiAuthError>;
type P<T> = Promise<F<T>>;

export type ApiAuth = {
    signin: (recaptchaToken: string, email: string, password: string, rememberMe?: boolean) => P<Auth | null>,
    signinWithFacebook: (recaptchaToken: string, facebookAccessToken: string, rememberMe?: boolean) => P<Auth | null>,
    signinWithGoogle: (recaptchaToken: string, googleCode: string, rememberMe?: boolean) => P<Auth | null>,
    register: (recaptchaToken: string, email: string, password?: string) => P<boolean>,
    confirmEmail: (email: string, code: string, password?: string, rememberMe?: boolean) => P<boolean>,
    forgotPassword: (recaptchaToken: string, email: string) => P<boolean>,
    resetPassword: (email: string, code: string, password: string, rememberMe?: boolean) => P<boolean>,
    changePassword: (oldPassword: string, newPassword: string) => P<boolean>,
}

const ApiAuthProvider = (): ApiAuth => {
    const { getAuth, setAuth, refreshAuth } = React.useContext(AuthContext);

    const signin = async (recaptchaToken: string, email: string, password: string, rememberMe?: boolean): Promise<F<Auth | null>> => failableAsync(
        async ({ success, failure }) => {
            const response = await PassifyApi.connect(recaptchaToken, email, password);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if("access_token" in responseJSON && "refresh_token" in responseJSON) {
                    const auth = {
                        accessToken: responseJSON["access_token"],
                        refreshToken: responseJSON["refresh_token"],
                        rememberMe: rememberMe || true
                    } as Auth;
                    setAuth(auth);
                    return success(auth);
                } else {
                    return success(null);
                }
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const signinWithFacebook = async (recaptchaToken: string, facebookAccessToken: string, rememberMe?: boolean): Promise<F<Auth | null>> => failableAsync(
        async ({ success, failure }) => {
            const response = await PassifyApi.facebookSignin(recaptchaToken, facebookAccessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if("access_token" in responseJSON && "refresh_token" in responseJSON) {
                    const auth = {
                        accessToken: responseJSON["access_token"],
                        refreshToken: responseJSON["refresh_token"],
                        rememberMe: rememberMe || true
                    } as Auth;
                    setAuth(auth);
                    return success(auth);
                } else {
                    return success(null);
                }
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const signinWithGoogle = async (recaptchaToken: string, googleCode: string, rememberMe?: boolean): Promise<F<Auth | null>> => failableAsync(
        async ({ success, failure }) => {
            const response = await PassifyApi.googleSignin(recaptchaToken, googleCode);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if("access_token" in responseJSON && "refresh_token" in responseJSON) {
                    const auth = {
                        accessToken: responseJSON["access_token"],
                        refreshToken: responseJSON["refresh_token"],
                        rememberMe: rememberMe || true
                    } as Auth;
                    setAuth(auth);
                    return success(auth);
                } else {
                    return success(null);
                }
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const register = async (recaptchaToken: string, email: string, password?: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure }) => {
            const response = await PassifyApi.register(recaptchaToken, email, password);
            if(response.status == 200) {
                const responseJSON = await response.json();
                return success(responseJSON["succeeded"]);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const confirmEmail = async (email: string, code: string, password?: string, rememberMe?: boolean): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            const response = await PassifyApi.confirmEmail(email, code, password);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    if(password !== undefined) {
                        await executeRecaptcha(async (token: any) => run(await signin(token, email, password, rememberMe)));
                    }
                    return success(true);
                } else {
                    return success(false);
                }
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const forgotPassword = async (recaptchaToken: string, email: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure }) => {
            const response = await PassifyApi.forgotPassword(recaptchaToken, email);
                if(response.status == 200) {
                    const responseJSON = await response.json();
                    return success(responseJSON["succeeded"]);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const resetPassword = async (email: string, code: string, password: string, rememberMe?: boolean): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            const response = await PassifyApi.resetPassword(email!, code, password!);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    if(password !== undefined) {
                        await executeRecaptcha(async (token: any) => run(await signin(token, email, password, rememberMe)));
                    }
                    return success(true);
                } else {
                    return success(false);
                }
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const changePassword = async (oldPassword: string, newPassword: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.changePassword(auth.accessToken, oldPassword, newPassword);
            if(response.status == 200) {
                const responseJSON = await response.json();
                return success(responseJSON["succeeded"]);
            } else if(response.status === 400) {
                const responseJSON = await response.json();
                if(responseJSON["errors"].length > 0) {
                    switch(responseJSON["errors"][0]["code"]) {
                        case "PasswordMismatch":
                            return failure({ errorType: "PASSWORD_MISMATCH" });
                        case "PasswordTooShort":
                            return failure({ errorType: "PASSWORD_TOO_SHORT" });
                    }
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return changePassword(oldPassword, newPassword);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    return { signin, signinWithFacebook, signinWithGoogle, register, confirmEmail, forgotPassword, resetPassword, changePassword };
};

export { ApiAuthProvider };