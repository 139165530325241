import { MaskFormatter } from "./maskformatter";

class PriceFormatter extends MaskFormatter {
    unmask(maskedText: string): string {
        return maskedText.replaceAll(",", "");
    }
    
    mask(unmaskedText: string): string | null {
        if(unmaskedText == "") {
            return unmaskedText;
        }

        let match;

        match = unmaskedText.match(/^[0]*([0-9]{1,})([0-9]{2})$/);
        if(match != null && match.length != 0) {
            return `${match[1] || "0"},${match[2]}`;
        }

        match = unmaskedText.match(/^[0]*([0-9]{2})$/);
        if(match != null && match.length != 0) {
            return `0,${match[1] || "00"}`;
        }

        match = unmaskedText.match(/^[0]*([0-9]{1})$/);
        if(match != null && match.length != 0) {
            return `0,0${match[1] || "0"}`;
        }

        return null;
    }
}

export { PriceFormatter };