import React from "react";
import { Search } from "@mui/icons-material";
import { CategoryList, EVENT_ID, EventList, Event, EVENTS_ID, PROFILE_ID, View } from "../../shared";
import { EventCard, ScreenLoader, SecondarySmallButton, SingleFeaturedEventCard, SmallButton, TextInput } from "../../components";
import { ApiContext, NavigationContext, ThemeContext } from "../../contexts";
import "./eventsview.scss";

class EventsView extends View {
    id = EVENTS_ID;
    route = "/event";
    defaultRoute = true;
    authNeeded = false;
    header = {
        profileClick: () => { this.navigation!.goTo(this.navigation!.views[PROFILE_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [events, setEvents] = React.useState<EventList>();
        const [eventCategories, setEventCategories] = React.useState<CategoryList>();
        const [selectedCategory, setSelectedCategory] = React.useState<string>("");
        const [searchText, setSearchText] = React.useState<string>("");

        const api = React.useContext(ApiContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);
        const { theme } = React.useContext(ThemeContext);

        let eventsLoading = false;
        let eventCategoriesLoading = false;

        const handleError = <T,>(response: T) => {
            return () => {
                return response;
            }
        }

        const loadEvents = async () => {
            eventsLoading = true;
            const failableEvents = await api.event.getEvents();
            return failableEvents.match({
                success: events => {
                    eventsLoading = false;
                    return events;
                },
                failure: handleError(undefined),
            });
        }

        const loadCategories = async () => {
            eventCategoriesLoading = true;
            const failableEventCategories = await api.event.getEventCategories();
            return failableEventCategories.match({
                success: eventCategories => {
                    eventCategoriesLoading = false;
                    return eventCategories;
                },
                failure: handleError([]),
            });
        }

        const init = () => { 
            Promise.all([
                loadEvents().then(setEvents),
                loadCategories().then(setEventCategories),
            ]).then(() => {
                setFirstLoad(false);
            })
        }

        React.useEffect(init, []);

        const changeSearchTextHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setSearchText(e.currentTarget.value);
            e.preventDefault();
        }

        const goToEvent = (event: Event) => {
            return (e: React.MouseEvent<HTMLDivElement>) => {
                goTo(views[EVENT_ID], { eventId: event.slug || event.id});
                e.preventDefault();
            }
        }

        const selectCategory = (categoryId: string) => {
            return async (e: React.MouseEvent<HTMLButtonElement>) => {
                setSelectedCategory(categoryId);
                e.preventDefault();
            }
        }

        const featuredEventCardSize = "220px";

        const featuredEventCardStyle = {
            "--card-size": featuredEventCardSize,
            "--font-size": "16px"
        };

        const eventCardStyle = {
            "--card-size": "calc(50vw - 28px)",
            "--font-size": "12px"
        };

        const filterFeaturedEvents = (events: EventList) => {
            return events
                .filter(event => event.isFeatured)
                .filter(event => searchText === "" || event.name.toLowerCase().indexOf(searchText.toLowerCase()) != -1);
        }

        const filterEvents = (events: EventList) => {
            return events
                .filter(event => selectedCategory === "" || event.category.id === selectedCategory)
                .filter(event => searchText === "" || event.name.toLowerCase().indexOf(searchText.toLowerCase()) != -1);
        }

        const buildPrompt = (title: string, subtitle: string) => {
            return (
                <div className="events-prompt">
                    <img src={`/images/${theme}/not-found.svg`} />
                    <h3>{title}</h3>
                    <p>{subtitle}</p>
                </div>
            );
        }

        return firstLoad ? <ScreenLoader /> : <div id="events">
            <div className="events-search-container"><TextInput id="input-events-search" name="events-search" prefix={<Search sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Procure um evento..." value={searchText} onChange={changeSearchTextHandle} /></div>
            {eventsLoading ?
                <></>
                : events != undefined && filterFeaturedEvents(events).length > 0 &&
                    <>
                        <div className="events-title">Em destaque</div>
                        <div className={`events-scrollable ${filterFeaturedEvents(events).length === 1 ? "single-event-featured" : "events-featured"}`} style={{"--featured-size": featuredEventCardSize} as React.CSSProperties}>
                                    {filterFeaturedEvents(events).length === 1 ? <SingleFeaturedEventCard
                                        imageUrl={filterFeaturedEvents(events)[0].smallImageUrl}
                                        name={filterFeaturedEvents(events)[0].name}
                                        date={filterFeaturedEvents(events)[0].startDate}
                                        location={filterFeaturedEvents(events)[0].venue.name}
                                        onClick={goToEvent(filterFeaturedEvents(events)[0])}
                                     /> : filterFeaturedEvents(events).map(event => <EventCard
                                        imageUrl={event.smallImageUrl}
                                        name={event.name}
                                        date={event.startDate}
                                        location={event.venue.name}
                                        style={featuredEventCardStyle}
                                        onClick={goToEvent(event)}
                                    />)}
                        </div>
                    </>
            }
            <div className="events-title">Eventos</div>
            <div className="events-scrollable">
                {eventCategoriesLoading ?
                    <div className="events-prompt"><h3>Carregando...</h3></div>
                    : eventCategories != undefined ?
                        <>
                            {selectedCategory === "" ? <SmallButton content="Todos" onClick={selectCategory("")}  /> : <SecondarySmallButton content="Todos" onClick={selectCategory("")}  />}
                            {eventCategories.map(category => {
                                if(selectedCategory === category.id) {
                                    return <SmallButton key={category.id} content={category.name} onClick={selectCategory(category.id)}  />;
                                } else {
                                    return <SecondarySmallButton key={category.id} content={category.name} onClick={selectCategory(category.id)}  />
                                }
                            })}
                        </> : <div className="events-prompt">"Não foi possível carregar as categorias"</div>
                }
            </div>
            {eventsLoading ?
                <div className="events-prompt"><h3>Carregando...</h3></div>
                : events != undefined ?
                    (filterEvents(events).length > 0 ? <div className="events-grid">
                            {filterEvents(events).map(event => <EventCard
                                imageUrl={event.smallImageUrl}
                                name={event.name}
                                date={event.startDate}
                                location={event.venue.name}
                                style={eventCardStyle}
                                onClick={goToEvent(event)}
                            />)}
                        </div>
                        : buildPrompt("Nenhum evento encontrado", "Infelizmente não consegui encontrar nenhum evento."))
                    : buildPrompt("Não foi possível carregar os eventos", "Infelizmente não consegui carregar os eventos, tente atualizar a página ou volte mais tarde.")
            }
        </div>;
    }
}

export { EventsView };