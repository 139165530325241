import React from "react";
import { IFailable, failableAsync } from "ts-failable";
import { ApiCheckoutError, AvailableTickets, CheckoutOrder, MercadoPagoApi, PassifyApi, Reservation, ReservationList } from "../../shared";
import { AuthContext } from "../auth";
import { QueueContext } from "../queue";

type F<T> = IFailable<T, ApiCheckoutError>;
type P<T> = Promise<F<T>>;

interface CardPaymentParameters {
    cardNumber: string,
    cardHolderName: string,
    cardHolderIdType?: string,
    cardHolderId: string,
    securityCode: string,
    expirationMonth: string,
    expirationYear: string,
}

export type ApiCheckout = {
    getAvailableTickets: (eventId: string, eventSessionId: string, code?: string) => P<AvailableTickets>,
    getReservations: (eventId: string) => P<ReservationList>,
    updateReservation: (recaptchaToken: string, eventId: string, batchId: string, quantity: number, code?: string) => P<Reservation>,
    getCheckoutOrderPreview: (eventId: string) => P<CheckoutOrder>,
    getCheckoutOrder: (eventId: string) => P<CheckoutOrder>,
    getCheckoutOrderById: (orderId: string) => P<CheckoutOrder>,
    removeCheckoutOrderById: (orderId: string) => P<CheckoutOrder>,
    createOrder: (eventId: string, reservations: Array<Reservation>, email?: string) => P<CheckoutOrder>,
    choosePixPayment: (eventId: string, taxId: string, name: string) => P<CheckoutOrder>,
    getPaymentToken: (cardPaymentParameters: CardPaymentParameters) => P<string>,
    chooseCardPaymentAndPay: (eventId: string, cardPaymentParameters: CardPaymentParameters, cardBrand: string, installments?: number) => P<CheckoutOrder>,
}

export const ApiCheckoutProvider = (): ApiCheckout => {
    const { getAuth, refreshAuth } = React.useContext(AuthContext);
    const { refreshQueueToken, getQueuePosition, abandonQueue } = React.useContext(QueueContext);

    const queued = async <T>(eventId: string, func: (queueAccessCode: string) => P<T>): Promise<F<T>> => failableAsync(
        async ({ failure, run }) => {
            const queuePosition = run(await getQueuePosition(eventId));
            if(queuePosition.position !== 0) {
                return failure({ errorType: "QUEUE_WAITING" });
            } else if(!!queuePosition.accessCode) {
                return func(queuePosition.accessCode);
            } else if(run(await abandonQueue(eventId))) {
                return queued(eventId, func);
            } else {
                return failure({ errorType: "NO_QUEUE_ACCESS_CODE" });
            }
        }
    );

    const getAvailableTickets = async (eventId: string, eventSessionId: string, code?: string, retry?: number): Promise<F<AvailableTickets>> => failableAsync(
        async ({ success, failure, run }) => {
            if(retry ?? 0 >= 5) {
                return failure({ errorType: "MAXIMUM_ATTEMPTS" });
            }
            return await queued(eventId, async (queueAccessCode) => {
                const auth = run(getAuth());
                const response = await PassifyApi.getAvailableTickets(queueAccessCode, auth.accessToken, eventSessionId, code);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        return success(responseJSON["value"] as AvailableTickets);
                    }
                } else if(response.status === 401 || response.status === 403) {
                    if(response.status === 401) { run(await refreshAuth()); }
                    if(response.status === 403) { run(await abandonQueue(eventId)); }
                    return getAvailableTickets(eventId, eventSessionId, code, (retry ?? 0) + 1);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
            });
        }
    );

    const getReservations = async (eventId: string): Promise<F<ReservationList>> => failableAsync(
        async ({ success, failure, run }) => {
            return queued(eventId, async (queueAccessCode) => {
                const auth = run(getAuth());
                const response = await PassifyApi.getReservations(queueAccessCode, auth.accessToken);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        return success(responseJSON["value"] as ReservationList);
                    }
                } else if(response.status === 401 || response.status === 403) {
                    if(response.status === 401) { run(await refreshAuth()); }
                    if(response.status === 403) { run(await abandonQueue(eventId)); }
                    return getReservations(eventId);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
            });
        }
    );

    const updateReservation = async (recaptchaToken: string, eventId: string, batchId: string, quantity: number, code?: string): Promise<F<Reservation>> => failableAsync(
        async ({ success, failure, run }) => {
            return queued(eventId, async (queueAccessCode) => {
                const auth = run(getAuth());
                const response = await PassifyApi.updateReservation(recaptchaToken, queueAccessCode, auth.accessToken, batchId, quantity, code);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        return success(responseJSON["value"] as Reservation);
                    }
                } else if(response.status === 401 || response.status === 403) {
                    if(response.status === 401) { run(await refreshAuth()); }
                    if(response.status === 403) { run(await abandonQueue(eventId)); }
                    return updateReservation(recaptchaToken, eventId, batchId, quantity, code);
                } else {
                    const responseJSON = await response.json();
                    if(!responseJSON["succeeded"] && responseJSON["errors"].length > 0) {
                        return failure({ errorType: "TICKET_RESERVATION_ERROR", code: responseJSON["errors"][0]["code"]});
                    }
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
            });
        }
    );

    const getCheckoutOrderPreview = async (eventId: string): Promise<F<CheckoutOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            return queued(eventId, async (queueAccessCode) => {
                const auth = run(getAuth());
                const response = await PassifyApi.getCheckoutOrderPreview(queueAccessCode, auth.accessToken);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        return success(responseJSON["value"] as CheckoutOrder);
                    }
                } else if(response.status === 404) {
                    return failure({ errorType: "EMPTY_ORDER" });
                } else if(response.status === 401 || response.status === 403) {
                    if(response.status === 401) { run(await refreshAuth()); }
                    if(response.status === 403) { run(await abandonQueue(eventId)); }
                    return getCheckoutOrderPreview(eventId);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
            });
        }
    );

    const getCheckoutOrder = async (eventId: string): Promise<F<CheckoutOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            return queued(eventId, async (queueAccessCode) => {
                const auth = run(getAuth());
                const response = await PassifyApi.getCheckoutOrder(queueAccessCode, auth.accessToken);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        const newQueueToken = response.headers.get("X-Passify-QueueGateway") || response.headers.get("X-Passify-Queuegateway");
                        if(!!newQueueToken) {
                            refreshQueueToken(eventId, newQueueToken);
                        }
                        return success(responseJSON["value"] as CheckoutOrder);
                    }
                } else if(response.status === 404) {
                    return failure({ errorType: "EMPTY_ORDER" });
                } else if(response.status === 401 || response.status === 403) {
                    if(response.status === 401) { run(await refreshAuth()); }
                    if(response.status === 403) { run(await abandonQueue(eventId)); }
                    return getCheckoutOrder(eventId);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
            });
        }
    );

    const getCheckoutOrderById = async (orderId: string): Promise<F<CheckoutOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.getCheckoutOrderById(auth.accessToken, orderId);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as CheckoutOrder);
                }
            } else if(response.status === 404) {
                return failure({ errorType: "EMPTY_ORDER" });
            } else if(response.status === 401) {
                run(await refreshAuth());
                return getCheckoutOrderById(orderId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const removeCheckoutOrderById = async (orderId: string): Promise<F<CheckoutOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.removeCheckoutOrderById(auth.accessToken, orderId);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as CheckoutOrder);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return getCheckoutOrderById(orderId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const createOrder = async (eventId: string, reservations: Array<Reservation>, email?: string): Promise<F<CheckoutOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            return queued(eventId, async (queueAccessCode) => {
                const auth = run(getAuth());
                const response = await PassifyApi.createCheckoutOrder(queueAccessCode, auth.accessToken, reservations.map(reservation => reservation.id), email);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        return success(responseJSON["value"] as CheckoutOrder);
                    }
                } else if(response.status === 401 || response.status === 403) {
                    if(response.status === 401) { run(await refreshAuth()); }
                    if(response.status === 403) { run(await abandonQueue(eventId)); }
                    return createOrder(eventId, reservations, email);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
            });
        }
    );

    const choosePixPayment = async (eventId: string, taxId: string, name: string): Promise<F<CheckoutOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            return queued(eventId, async (queueAccessCode) => {
                const auth = run(getAuth());
                const response = await PassifyApi.choosePixPayment(queueAccessCode, auth.accessToken, taxId, name);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        return success(responseJSON["value"] as CheckoutOrder);
                    }
                } else if(response.status === 401 || response.status === 403) {
                    if(response.status === 401) { run(await refreshAuth()); }
                    if(response.status === 403) { run(await abandonQueue(eventId)); }
                    return choosePixPayment(eventId, taxId, name);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
            });
        }
    );

    const getPaymentToken = async (cardPaymentParameters: CardPaymentParameters): Promise<F<string>> => failableAsync(
        async ({ success, failure }) => {
            const response = await MercadoPagoApi.createCardToken(
                cardPaymentParameters.cardNumber,
                cardPaymentParameters.cardHolderName,
                cardPaymentParameters.cardHolderId.length == 11 ? "CPF" :
                cardPaymentParameters.cardHolderId.length == 14 ? "CNPJ" : "",
                cardPaymentParameters.cardHolderId,
                cardPaymentParameters.securityCode,
                cardPaymentParameters.expirationMonth,
                cardPaymentParameters.expirationYear
            );
            if(!!response) {
                return success(response.id);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const chooseCardPaymentAndPay = async (eventId: string, cardPaymentParameters: CardPaymentParameters, cardBrand: string, installments?: number): Promise<F<CheckoutOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            return queued(eventId, async (queueAccessCode) => {
                const auth = run(getAuth());
                const paymentToken = run(await getPaymentToken(cardPaymentParameters));
                const response = await PassifyApi.chooseCardPayment(queueAccessCode, auth.accessToken, cardPaymentParameters.cardHolderName, cardPaymentParameters.cardHolderId, paymentToken, cardBrand, installments ?? 1);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        return success(responseJSON["value"] as CheckoutOrder);
                    }
                } else if(response.status === 401 || response.status === 403) {
                    if(response.status === 401) { run(await refreshAuth()); }
                    if(response.status === 403) { run(await abandonQueue(eventId)); }
                    return chooseCardPaymentAndPay(eventId, cardPaymentParameters, cardBrand, installments);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
            });
        }
    );

    return {
        getAvailableTickets,
        getReservations,
        updateReservation,
        getCheckoutOrderPreview,
        getCheckoutOrder,
        getCheckoutOrderById,
        removeCheckoutOrderById,
        createOrder,
        choosePixPayment,
        getPaymentToken,
        chooseCardPaymentAndPay
    };
};