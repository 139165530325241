import React from "react";
import { IFailable, failable, failableAsync } from "ts-failable";
import { Auth, AuthError, PassifyApi } from "../shared";
import { useSearchParams } from "react-router-dom";

type Dispatch<A> = (value: A) => void;
export type AuthState = {
    auth: Auth | null,
    setAuth: Dispatch<React.SetStateAction<Auth | null>>,
    refreshAuth: () => Promise<IFailable<Auth, AuthError>>,
    getAuth: () => IFailable<Auth, AuthError>,
};

const localAuth = localStorage.getItem("auth");
const sessionAuth = sessionStorage.getItem("auth");
let parsedAuth: Auth | null;

try {
    parsedAuth = (!!localAuth && localAuth !== sessionAuth) ?
        JSON.parse(localAuth) as Auth :
        !!sessionAuth ?
            JSON.parse(sessionAuth) as Auth :
            null;
} catch(_) {
    parsedAuth = null;
}

const initialAuthState: AuthState = {
    auth: parsedAuth,
    setAuth: () => {},
    refreshAuth: async () => failableAsync<Auth, AuthError>(async ({ failure }) => failure({ errorType: "NO_AUTH" })),
    getAuth: () => failable<Auth, AuthError>(({ failure }) => failure({ errorType: "NO_AUTH" })),
};

export const AuthContext = React.createContext(initialAuthState);

export const AuthProvider = ({ children } : { children: React.ReactNode}) => {
    const [auth, setAuth] = React.useState(initialAuthState.auth);

    const [searchParams, setSearchParams] = useSearchParams();
    
    let currentAuth = auth;

    const storeAuth = (accessToken: string, refreshToken: string, rememberMe: boolean) => {
        const auth: Auth = {
            accessToken: accessToken,
            refreshToken: refreshToken,
            rememberMe: rememberMe,
        };
    
        sessionStorage.setItem("auth", JSON.stringify(auth));
        if(rememberMe) {
            localStorage.setItem("auth", JSON.stringify(auth));
        }
    }

    const clearAuth = () => {
        localStorage.removeItem("auth");
        sessionStorage.removeItem("auth");
        localStorage.removeItem("account");
    }

    const refreshAuth = async () => failableAsync<Auth, AuthError>(
        async ({ success, failure }) => {
            if(!!auth) {
                const response = await PassifyApi.refreshToken(auth.refreshToken);
                if(response.status == 200) {
                    const responseJSON = await response.json();
                    currentAuth = {
                        accessToken: responseJSON["access_token"],
                        refreshToken: responseJSON["refresh_token"],
                        rememberMe: auth.rememberMe
                    } as Auth;
                    setAuth(currentAuth);
                    return success(currentAuth);
                } else {
                    setAuth(null);
                    return failure({ errorType: "AUTH_EXPIRED" });
                }
            } else {
                return failure({ errorType: "NO_AUTH" });
            }
        }
    );
    
    const getAuth = () => failable<Auth, AuthError>(
        ({ success, failure }) => {
            if(!!currentAuth) {
                return success(currentAuth);
            } else {
                return failure({ errorType: "NO_AUTH" });
            }
        }
    );

    const syncAuth = () => {
        if(!!auth) {
            storeAuth(auth.accessToken, auth.refreshToken, auth.rememberMe);
        } else {
            clearAuth();
        }
    }

    const setReferencedAuth = () => {
        const refAuthToken = searchParams.get("token");
        if(!!refAuthToken) {
            setAuth({
                accessToken: refAuthToken,
                refreshToken: "",
                rememberMe: true,
            });
            searchParams.delete("token");
            setSearchParams(searchParams);
        }
    }

    React.useEffect(syncAuth, [auth]);
    React.useEffect(setReferencedAuth, [searchParams]);

    return (
        <AuthContext.Provider value={{ auth, setAuth, refreshAuth, getAuth }}>
            {children}
        </AuthContext.Provider>
    );
};