import React from "react";
import md5 from "md5";
import { useParams } from "react-router-dom";
import { ArrowDropDown, ArrowDropUp, AttachMoney, Check, PersonSearch, Reply } from "@mui/icons-material";
import { Customer, EmailFormatter, EventDetails, HOME_ID, Pass, PhoneFormatter, PriceFormatter, TRANSFER_ID, TRANSFER_SEND_ID, View } from "../../shared";
import { ButtonSliderSwitch, CheckboxInput, MainButton, ScreenLoader } from "../../components";
import { ApiContext, CustomerContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./transferview.scss";

enum TargetUserMask {
    phone,
    email
}

enum TransferType {
    send,
    sell
}

class TransferView extends View {
    id = TRANSFER_ID;
    route = "/wallet/transfer/:batchId";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => { this.navigation!.navigate(-1); },
        supportClick: () => {}
    };
    render = () => {
        const params = this.params = useParams();

        // REMOVE:
        const mockedUsers: Array<Customer> = [{
            email: "ricocavalieri@gmail.com",
            name: "Ricardo Cavalieri",
            phone: "+5511976117631"
        }, {
            email: "rogerioyuuki@gmail.com",
            name: "Rogério Motisuki",
            phone: "+5511998283424"
        }].filter(x => false);
        
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [feeRate, setFeeRate] = React.useState<number>(0.1);
        const [transferablePass, setTransferablePass] = React.useState<Pass>();
        const [event, setEvent] = React.useState<EventDetails>();

        const [transferType, setTransferType] = React.useState<TransferType>(TransferType.send);
        const [selectUser, setSelectUser] = React.useState<boolean>(false);
        const [quantity, setQuantity] = React.useState<number>(1);
        const [allowSplit, setAllowSplit] = React.useState<boolean>(false);

        const [targetUser, setTargetUser] = React.useState<string>();
        const [targetUserMask, setTargetUserMask] = React.useState<TargetUserMask>();
        const [targetUserCursor, setTargetUserCursor] = React.useState<number>(0);
        const targetUserRef = React.useRef<HTMLInputElement>(null);
        const phoneFormatter = new PhoneFormatter();
        const emailFormatter = new EmailFormatter();

        const [priceText, setPriceText] = React.useState<string>("0,00");
        const [priceCursor, setPriceCursor] = React.useState<number>(0);
        const priceRef = React.useRef<HTMLInputElement>(null);
        const priceFormatter = new PriceFormatter();

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { customer } = React.useContext(CustomerContext);
        const { views, goTo, navigate } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const batchId = params["batchId"];

            if(!batchId) {
                goTo(views[HOME_ID]);
            }

            return { batchId: batchId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            return () => {
                return response;
            }
        }

        const loadPasses = async (batchId: string) => {
            const failablePasses = await api.customer.getPassesByBatch(batchId, true);
            return failablePasses.match({
                success: passes => passes,
                failure: handleError(null)
            });
        }

        const loadEvent = async (eventId: string) => {
            const failableEvent = await api.event.getEventDetails(eventId);
            return failableEvent.match({
                success: event => event,
                failure: handleError(undefined)
            });
        }

        const init = () => {
            const { batchId } = getIds();
            
            loadPasses(batchId).then(passes => {
                if(!!passes) {
                    setTransferablePass(passes.find(pass => !pass.transferOrderId));
                } else {
                    navigate(-1);
                }
                setFirstLoad(false);
            });
        }

        const onLoadPass = () => {
            if(!!transferablePass) {
                setFirstLoad(true);
                loadEvent(transferablePass.eventId).then(event => {
                    setEvent(event);
                    setFirstLoad(false);
                });
            }
        }

        React.useEffect(init, []);
        React.useEffect(onLoadPass, [transferablePass]);

        React.useEffect(() => {
            if(selectUser) {
                const elem = targetUserRef.current;
                if(!!elem) {
                    elem.focus();
                }
            }
        }, [selectUser]);

        React.useEffect(() => {
            if(transferType === TransferType.send) {
                setPriceText("0,00");
            }
        }, [transferType]);

        const targetCustomer = (targetUser !== undefined) ? mockedUsers.find(user => emailFormatter.unmask(user.email) === emailFormatter.unmask(targetUser.trim()) || phoneFormatter.unmask(user.phone || "") === phoneFormatter.unmask(targetUser)) : undefined;
        const price = parseFloat(priceText.replace(",", "."));
        const maxPasses = !!transferablePass && !!transferablePass.passes ? transferablePass.passes.length : 0;
        const fee = Math.max(feeRate * price, 1);

        const openSelectUser = (e: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => {
            setSelectUser(true);
            e.preventDefault();
        }

        const closeSelectUser = (e: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => {
            setSelectUser(false);
            e.preventDefault();
        }

        const changeTargetUserHandle = (e: React.FormEvent<HTMLInputElement>) => {
            let textCandidate = e.currentTarget.value;

            if(textCandidate === "") {
                setTargetUser(undefined);
                setTargetUserCursor(0);
            } else {
                if(targetUserMask === TargetUserMask.phone) {
                    textCandidate = phoneFormatter.unmask(textCandidate);
                } else if(targetUserMask === TargetUserMask.email) {
                    textCandidate = emailFormatter.unmask(textCandidate);
                }

                let updatedText;
                let updatedCursor;

                const updatedValueAsPhone = phoneFormatter.updateValue({text: targetUser || "", cursor: targetUserCursor}, {text: textCandidate, cursor: e.currentTarget.selectionEnd || 0});
                if(updatedValueAsPhone.text !== (targetUser || "")) {
                    updatedText = updatedValueAsPhone.text;
                    updatedCursor = updatedValueAsPhone.cursor;
                    setTargetUserMask(TargetUserMask.phone);
                } else {
                    const updatedValueAsEmail = emailFormatter.updateValue({text: targetUser || "", cursor: targetUserCursor}, {text: textCandidate, cursor: e.currentTarget.selectionEnd || 0});
                    updatedText = updatedValueAsEmail.text;
                    updatedCursor = updatedValueAsEmail.cursor;
                    setTargetUserMask(TargetUserMask.email);
                }

                setTargetUser(updatedText);
                setTargetUserCursor(updatedCursor);
            }

            e.preventDefault();
        }

        React.useEffect(() => {
            const input = targetUserRef.current;
            if(!!input) {
                input.setSelectionRange(targetUserCursor, targetUserCursor);
            }
        }, [targetUserRef, targetUserCursor, targetUser]);

        const increaseQuantity = (e: React.MouseEvent<SVGSVGElement>) => {
            if(quantity < maxPasses) {
                setQuantity(oldQuantity => oldQuantity + 1);
            }
            e.preventDefault();
        }

        const decreaseQuantity = (e: React.MouseEvent<SVGSVGElement>) => {
            if(quantity > 1) {
                setQuantity(oldQuantity => oldQuantity - 1);
            }
            e.preventDefault();
        }

        const changePriceHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = priceFormatter.updateValue({text: priceText, cursor: priceCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setPriceText(updatedValue.text);
            setPriceCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = priceRef.current;
            if(!!input) {
                input.setSelectionRange(priceCursor, priceCursor);
            }
        }, [priceRef, priceCursor, priceText]);

        const changeAllowSplitHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setAllowSplit(oldValue => !oldValue);
            e.preventDefault();
        }

        const createTransfer = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const target = !!targetUser ? (
                targetUserMask === TargetUserMask.email ?
                    {email: targetUser.trim()} :
                    {phone: targetUser.trim()}
            ) : {};

            const failableTransfer = await api.transfer.createTransfer(transferablePass?.passes?.slice(0, quantity) ?? [], target);
            const transfer = failableTransfer.match({
                success: transfer => transfer,
                failure: () => null,
            });

            if(!!transfer) {
                goTo(views[TRANSFER_SEND_ID], { transferId: transfer.id });
            } else {
                pushNotification("Erro ao criar transferência.")
            }
            e.preventDefault();
        }

        return firstLoad || !customer || event === undefined ? <ScreenLoader /> : <div id="transfer">
            {selectUser && <div id="transfer-overlay" onClick={closeSelectUser}></div>}
            <div id="transfer-peers">
                <div className="transfer-peer">
                    <div className="transfer-peer-definition">De:</div>
                    <div className="transfer-peer-container">
                        <div className="transfer-peer-image">
                            <img src={`https://gravatar.com/avatar/${md5(customer.email)}?d=retro`} />
                        </div>
                        <div className="transfer-peer-details">
                            <div className="transfer-peer-name">{customer.name}</div>
                            <div className="transfer-peer-contact">{customer.email}</div>
                        </div>
                    </div>
                </div>
                <div className={`transfer-peer ${selectUser ? "bring-front" : ""}`}>
                    <div className="transfer-peer-definition">Para:</div>
                    <div className="transfer-peer-container">
                        {selectUser ?
                            <>
                                <div className="transfer-peer-image">
                                    {targetCustomer !== undefined ? <img src={`https://gravatar.com/avatar/${md5(targetCustomer.email)}?d=retro`} /> : "?"}
                                </div>
                                <div className="transfer-peer-details relative">
                                    {targetCustomer !== undefined && <div className="transfer-peer-name">{targetCustomer.name}</div>}
                                    <input ref={targetUserRef} className={`transfer-peer-details-input ${targetCustomer !== undefined ? "subtitle" : ""}`} type="text" value={targetUser} placeholder="E-mail ou WhatsApp" onChange={changeTargetUserHandle} />
                                    {targetUser !== undefined && <div className="transfer-peer-details-suggestions">
                                        {mockedUsers.filter(user =>
                                            user.name?.toLowerCase().indexOf(targetUser.toLowerCase()) != -1 ||
                                            user.email?.toLowerCase().indexOf(targetUser.toLowerCase()) != -1 ||
                                            user.phone?.toLowerCase().indexOf(phoneFormatter.unmask(targetUser)) != -1
                                        ).map(user => <div className="transfer-peer-details-suggestions-item" onClick={(e) => { setTargetUser(user.email); closeSelectUser(e); }}>
                                            <div className="transfer-peer-details-suggestions-item-image">
                                                <img src={`https://gravatar.com/avatar/${md5(user.email)}?d=retro`} />
                                            </div>
                                            <div className="transfer-peer-details-suggestions-item-name">
                                                {user.name}
                                            </div>
                                        </div>)}
                                    </div>}
                                </div>
                                <div className="transfer-peer-search-user">
                                    <Check sx={{fontSize: 20}} onClick={closeSelectUser} />
                                </div>
                            </> :
                            <>
                                <div className="transfer-peer-image">
                                    {targetCustomer !== undefined ? <img src={`https://gravatar.com/avatar/${md5(targetCustomer.email)}?d=retro`} /> : "?"}
                                </div>
                                <div className="transfer-peer-details"  onClick={openSelectUser}>
                                    <div className="transfer-peer-name">{targetUser !== undefined ? targetCustomer?.name || "Pessoa desconhecida" : true ? "E-mail ou WhatsApp" : "Qualquer pessoa"}</div>
                                    {targetUser !== undefined && <div className="transfer-peer-contact">{(targetUserMask === TargetUserMask.email ? targetCustomer?.email : phoneFormatter.mask(targetCustomer?.phone || "invalid-mask")) || targetUser}</div>}
                                </div>
                                <div className="transfer-peer-search-user">
                                    <PersonSearch sx={{fontSize: 20}} onClick={openSelectUser} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <h3>Ingresso</h3>
            <div id="transfer-object">
                <div id="transfer-object-image">
                    <img src={event.smallImageUrl} />
                </div>
                <div id="transfer-object-details">
                    <div id="transfer-object-event-name">{event.name}</div>
                    <div id="transfer-object-event-batch">{transferablePass?.batchName!}</div>
                </div>
                <div id="transfer-object-quantity">
                    <div id="transfer-object-quantity-selector">
                        <ArrowDropUp onClick={increaseQuantity} />
                        <div id="transfer-object-quantity-display">
                            {quantity}
                            <span className="light"> de {maxPasses}</span>
                        </div>
                        <ArrowDropDown onClick={decreaseQuantity} />
                    </div>
                </div>
            </div>
            <h3 className="transfer-type">
                <span>{transferType === TransferType.sell ? "Venda" : "Transferência"}</span>
                {false && <ButtonSliderSwitch items={[{
                    content: <Reply sx={{height: 16, transform: "scaleX(-1)"}} />,
                    onClick: (e) => {
                        setTransferType(TransferType.send);
                        e.preventDefault();
                    }
                },{
                    content: <AttachMoney sx={{height: 16}} />,
                    onClick: (e) => {
                        setTransferType(TransferType.sell);
                        e.preventDefault();
                    }
                }]} />}
            </h3>
            <div id="transfer-request">
                {transferType === TransferType.sell && <div id="transfer-request-price">R$ <input ref={priceRef} type="tel" value={priceText} onChange={changePriceHandle} /></div>}
                {(quantity > 1 && false) && <div id="transfer-request-allow-split">
                    <CheckboxInput id="input-transfer-request-allow-split" name="allow-split" checked={allowSplit} text={`${transferType === TransferType.send ? "Transferir" : "Vender"} ingressos separadamente`} onClick={changeAllowSplitHandle} />
                </div>}
                {transferType === TransferType.send && (quantity === 1 || true) && <div id="transfer-request-no-parameters">Transferência simples de {/*um único*/} ingresso</div>}
            </div>
            <h3>Resumo</h3>
            <div id="transfer-summary">
                {allowSplit ?
                    Array(quantity).fill(<div className="transfer-summary-item">
                        <div>
                            1x {event.name}<br />
                            <span className="light">{transferablePass?.batchName!}</span>
                        </div>
                        <div>{transferType === TransferType.sell ? `R$ ${(price / quantity).toFixed(2)}` : "Transferência"}</div>
                    </div>) :
                    <div className="transfer-summary-item">
                        <div>
                            {quantity}x {event.name}<br />
                            <span className="light">{transferablePass?.batchName!}</span>
                        </div>
                        <div>{transferType === TransferType.sell ? `R$ ${price.toFixed(2)}` : "Transferência"}</div>
                    </div>
                }
                {transferType === TransferType.sell && <>
                    <div className="transfer-summary-item">
                        <div>Taxa de serviço</div>
                        <div>R$ {fee.toFixed(2)}</div>
                    </div>
                    <div id="transfer-summary-total" className="transfer-summary-item">
                        <div>Total</div>
                        <div>R$ {(price + fee).toFixed(2)}</div>
                    </div>
                </>}
            </div>
            <div id="transfer-button-container">
                <MainButton enabled={(transferType === TransferType.sell && price > 0) || transferType === TransferType.send} content={true ? "Solicitar transferência" : 
                    targetUser !== undefined ?
                        (transferType === TransferType.sell ? "Iniciar venda" : "Transferir") :
                        (transferType === TransferType.sell ? "Link para venda" : "Link para transferência")
                } onClick={createTransfer} />
            </div>
        </div>;
    }
}

export { TransferView };