import React from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { ArrowBackIos, CalendarMonth, LocationOn, QrCode } from "@mui/icons-material";
import { EventDetails, HOME_ID, PASS_ID, PassList, TRANSFER_ID, TRANSFER_SEND_ID, View, WALLET_EVENT_ID } from "../../shared";
import { ListTile, MainButton, ScreenLoader, SecondarySmallButton } from "../../components";
import { ApiContext, NavigationContext } from "../../contexts";
import "./walleteventview.scss";

interface SessionPassList {
    [sessionId: string]: PassList
}

class WalletEventView extends View {
    id = WALLET_EVENT_ID;
    route = "/wallet/event/:eventId";
    defaultRoute = false;
    authNeeded = true;
    header = undefined;
    render = () => {
        const params = this.params = useParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [passes, setPasses] = React.useState<SessionPassList>({});
        const [event, setEvent] = React.useState<EventDetails>();

        const api = React.useContext(ApiContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const eventId = params["eventId"];

            if(!eventId) {
                goTo(views[HOME_ID]);
            }

            return { eventId: eventId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            return () => {
                return response;
            }
        }

        const loadEvent = async (eventId: string) => {
            const failableEventDetails = await api.event.getEventDetails(eventId);
            return failableEventDetails.match({
                success: eventDetails => eventDetails,
                failure: handleError(null)
            });
        }

        const loadSessionPasses = async (sessionId: string) => {
            const failablePasses = await api.customer.getPassesBySession(sessionId);
            return failablePasses.match({
                success: passes => passes,
                failure: handleError(null)
            });
        }

        const loadPasses = async (event: EventDetails) => {
            const loadingPasses: SessionPassList = {};
            for(let i = 0; i < event.sessions!.length; i++) {
                const sessionId = event.sessions![i].id;
                const sessionPasses = await loadSessionPasses(sessionId);
                if(!!sessionPasses) {
                    loadingPasses[sessionId] = sessionPasses;
                } else {
                    goTo(views[HOME_ID]);
                }
            }
            return loadingPasses;
        }

        const init = () => {
            const { eventId } = getIds();

            loadEvent(eventId).then(eventDetails => {
                if(!!eventDetails) {
                    setEvent(eventDetails);
                }
                setFirstLoad(false);
            });
        }

        const onEventLoaded = () => {
            if(!!event) {
                loadPasses(event).then(setPasses);
            }
        }

        React.useEffect(init, []);
        React.useEffect(onEventLoaded, [event]);

        const humanizeDate = (stringDate: string) => {
            const now = DateTime.now();
            const date = DateTime.fromMillis(Date.parse(stringDate));
            if(date.hasSame(now, "day")) {
                return "Hoje";
            } else if(now > date) {
                return date.toRelative({ locale: "pt-br" });
            } else {
                return date.toFormat("dd 'de' LLLL", { locale: "pt-br" });
            }
        }

        const sortPasses = (passes: PassList) => {
            return passes.sort((a, b) => {
                if(a.isActive === b.isActive) {
                    if(!a.transferOrderId && !!b.transferOrderId) {
                        return -1;
                    }
                    return 0;
                } else if(a.isActive) {
                    return -1;
                } else {
                    return 1;
                }
            });
        }

        const viewTransfer = (transferOrderId: string) => {
            return async (e: React.MouseEvent<HTMLButtonElement>) => {
                goTo(views[TRANSFER_SEND_ID], { transferId: transferOrderId });
                e.preventDefault();
            }
        }

        return firstLoad || event === undefined ? <ScreenLoader /> : <div id="wallet-event">
            <div id="wallet-event-back-button"><a onClick={() => goTo(views[HOME_ID])}><ArrowBackIos /></a></div>
            <div id="wallet-event-image-container">
                <img src={event.bigImageUrl} />
            </div>
            <div id="wallet-event-name">{event.name}</div>
            <ListTile separator={false} leading={<div className="circle-icon-container"><CalendarMonth /></div>} title={DateTime.fromMillis(Date.parse(event.startDate)).toFormat("ccc, d LLL yyyy", { locale: "pt-br" })} subtitle={`${DateTime.fromMillis(Date.parse(event.startDate)).toFormat("HH:mm", { locale: "pt-br" })} - ${DateTime.fromMillis(Date.parse(event.endDate)).toFormat("HH:mm", { locale: "pt-br" })} (GMT -03:00)`} />
            <ListTile separator={false} leading={<div className="circle-icon-container"><LocationOn /></div>} title={event.venue.name} subtitle={event.venue.street1} />
            <div className="divider"><hr /></div>
            <ListTile separator={false} leading={<div className="circle-icon-container"><img src={event.producer.imageUrl} /></div>} title={event.producer.name} subtitle={"Organizador"} />
            <div id="wallet-event-about">
                <h3>Seus ingressos para este evento</h3>
                {event !== undefined && event.sessions !== undefined && (event.sessions.length > 0 || Object.keys(passes).length === 0) ?
                    event.sessions.map(session => <>
                        {passes[session.id] !== undefined && passes[session.id].length > 0 && <div className="wallet-tickets">
                            {event!.sessions!.length > 1 && <div className="wallet-tickets-session">{session.name} <span className="light">{humanizeDate(session.startDate)}</span></div>}
                            {sortPasses(passes[session.id]).map(pass => <div key={`${pass.ticketBatchId}-${pass.transferOrderId ?? "no-transfer"}`} className="wallet-tickets-batches">
                                <div className="wallet-event-ticket-description">{pass.quantity}x {pass.segmentName}: {pass.batchName}</div>
                                {!!pass.transferOrderId ?
                                    <SecondarySmallButton content="Ver transf." onClick={viewTransfer(pass.transferOrderId)} /> :
                                    pass.isActive ?
                                        <SecondarySmallButton content="Transferir" onClick={async () => goTo(views[TRANSFER_ID], { batchId: pass.ticketBatchId! })} /> :
                                        <span className="wallet-tickets-batches-used">Usado</span>
                                }
                            </div>)}
                        </div>}
                    </>) :
                    <p>Você não possui nenhum ingresso para este evento.</p>
                }
                <h3>Sobre o evento</h3>
                <p>{event.longDescription.split("\n").map((item, i) => <span key={i}>{item}<br /></span>)}</p>
            </div>
            <div id="wallet-event-button-container">
                <MainButton content={<><QrCode />Meu passe</>} onClick={async () => goTo(views[PASS_ID], undefined, { eventId: event.id })} />
            </div>
        </div>;
    }
}

export { WalletEventView };