import React from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { HOME_ID, RouteBuilder, RouteNavigator, RouteParams, SIGNIN_ID, View } from "../shared";
import { CardPaymentView, CreatePasswordView, EventView, ForgotPasswordView, EventsView, PassView, PaymentView, PixPaymentView, ProfileView, ReviewSummaryView, SettingsView, SigninView, SignupView, TicketSelectionView, VerificationView, HomeView, ContactView, SecurityView, TransferView, QueueView, WalletEventView, TransferSendView, ConfirmEmailView, ChangePhoneView, NotificationsSettingsView, ResetPasswordView, ChangeEmailView, RegisterNameView, RegisterPhoneView, TransferReceiveView } from "../views";
import { AuthContext } from "./auth";

interface ViewList {
    [key: string]: View
}

export type NavigationState = {
    navigate: NavigateFunction,
    location: Location,
    views: ViewList,
    goTo: (view: View, params?: RouteParams, searchParams?: RouteParams) => void,
    routeBuilder?: RouteBuilder,
    currentView?: View,
}

const initialNavigationState: NavigationState = {
    navigate: () => {},
    location: {
        state: "",
        key: "",
        pathname: "",
        search: "",
        hash: ""
    },
    views: {},
    goTo: () => {}
};

export const NavigationContext = React.createContext(initialNavigationState);

export const NavigationProvider = ({ children } : { children: React.ReactNode}) => {
    const navigate = useNavigate()
    const location = useLocation();

    const [currentView, setCurrentView] = React.useState<View>();
    const [routeBuilder, setRouteBuilder] = React.useState<RouteBuilder>();
    const [views, setViews] = React.useState<ViewList>({});

    const { auth } = React.useContext(AuthContext);

    const goTo = (section: View, params?: RouteParams, searchParams?: RouteParams) => {
        if(process.env.NODE_ENV !== "production") {
            console.log(RouteNavigator.ParseRoute(section, params, searchParams));
        }
        navigate(RouteNavigator.ParseRoute(section, params, searchParams));
    }

    const initViews = () => {
        const newViews: ViewList = {};
        [
            new HomeView(),
            new WalletEventView(),
            new TransferView(),
            new TransferSendView(),
            new TransferReceiveView(),
            new EventsView(),
            new EventView(),
            new QueueView(),
            new TicketSelectionView(),
            new ReviewSummaryView(),
            new PaymentView(),
            new CardPaymentView(),
            new PixPaymentView(),
            new PassView(),
            new SettingsView(),
            new ProfileView(),
            new ContactView(),
            new ChangeEmailView(),
            new ChangePhoneView(),
            new SecurityView(),
            new NotificationsSettingsView(),
            new SigninView(),
            new SignupView(),
            new CreatePasswordView(),
            new ForgotPasswordView(),
            new ResetPasswordView(),
            new ConfirmEmailView(),
            new RegisterPhoneView(),
            new RegisterNameView(),
            new VerificationView(),
        ].forEach(view => {
            newViews[view.id] = view;
        });
        setViews(newViews);
        setRouteBuilder(new RouteBuilder(newViews[HOME_ID], ...Object.values(newViews)));
        setCurrentView(newViews[HOME_ID]);
    }

    const loadView = () => {
        if(routeBuilder !== undefined) {
            const routeTarget = routeBuilder.find(location.pathname);

            if(typeof routeTarget === "string") {
                navigate(routeTarget);
            } else {
                setCurrentView(routeTarget);
                if(!auth && routeTarget.authNeeded) {
                    goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
                }
            }
        }
	}

    React.useEffect(initViews, []);
    React.useEffect(loadView, [routeBuilder, location]);

    return (
        <NavigationContext.Provider value={{ navigate, location, views, goTo, routeBuilder, currentView }}>
            {children}
        </NavigationContext.Provider>
    );
};