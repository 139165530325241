import { MaskFormatter } from "./maskformatter";

class CardExpirationFormatter extends MaskFormatter {
    unmask(maskedText: string): string {
        return maskedText.replaceAll(/[\/]/g, "");
    }
    
    mask(unmaskedText: string): string | null {
        if(unmaskedText == "") {
            return unmaskedText;
        }

        let match;

        match = unmaskedText.match(/^([0-9]{2})([0-9]{1,2})$/);
        if(match != null && match.length != 0 && (parseInt(match[1]) >= 1 && parseInt(match[1]) <= 12)) {
            return `${match[1]}/${match[2]}`;
        }

        match = unmaskedText.match(/^([0-9]{1,2})$/);
        if(match != null && match.length != 0 && ((parseInt(match[1]) >= 1 && parseInt(match[1]) <= 12) || match[1] == "0")) {
            return `${match[1]}`;
        }

        return null;
    }
}

export { CardExpirationFormatter };