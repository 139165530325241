import React from "react";
import { Lock, LockOutlined } from "@mui/icons-material";
import { MainButton, PasswordForm, PasswordRequirement, ScreenLoader, TextInput } from "../../components";
import { View, SETTINGS_ID, SECURITY_ID, SIGNIN_ID } from "../../shared";
import { ApiContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./securityview.scss";

class SecurityView extends View {
    id = SECURITY_ID;
    route = "/settings/security";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => this.navigation!.goTo(this.navigation!.views[SETTINGS_ID]),
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [password, setPassword] = React.useState<string>("");
        const [newPassword, setNewPassword] = React.useState<string>("");
        const [confirmNewPassword, setConfirmNewPassword] = React.useState<string>("");
        const [requirementsFulfilled, setRequirementsFulfilled] = React.useState<boolean>(false);
        const [showPasswordRequirements, setShowPasswordRequirements] = React.useState<boolean>(false);
        const [waitForClose, setWaitForClose] = React.useState<boolean>(false);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);

        React.useEffect(() => { setFirstLoad(false); }, []);
        React.useEffect(() => {
            if(waitForClose) {
                toggleRequirements(false);
            }
        }, [newPassword, confirmNewPassword]);

        const changePasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setPassword(e.currentTarget.value);
            e.preventDefault();
        }

        const toggleRequirements = (focus: boolean) => {
            if(focus) {
                setShowPasswordRequirements(true);
                setWaitForClose(false);
            } else if(requirementsFulfilled) {
                setShowPasswordRequirements(false);
                setWaitForClose(false);
            } else {
                setWaitForClose(true);
            }
        }

        const save = async (e: React.MouseEvent<HTMLButtonElement>) => {
            if(password !== "" && requirementsFulfilled) {
                const failableChangePassword = await api.auth.changePassword(password, newPassword);
                failableChangePassword.match({
                    success: () => {
                        pushNotification("Senha alterada com sucesso!");
                        goTo(views[SETTINGS_ID]);
                    },
                    failure: error => {
                        switch(error.errorType) {
                            case "NO_AUTH":
                            case "AUTH_EXPIRED":
                                goTo(views[SIGNIN_ID]);
                                break;
                            case "PASSWORD_MISMATCH":
                                pushNotification("Senha atual está incorreta.");
                                break;
                            case "PASSWORD_TOO_SHORT":
                                pushNotification("Nova senha possui menos de 6 caracteres.");
                                break;
                            default:
                                pushNotification("Erro ao tentar atualizar a senha.");
                                break;
                        }
                    }
                });
            }
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="security">
            <div className="security-prompt">
                <h2><LockOutlined />Segurança</h2>
                <p>Altere sua senha regularmente para manter sua conta segura e JAMAIS compartilhe sua senha.</p>
            </div>
            <div className="security-form-field">
                <div className="label">Senha atual</div>
                <TextInput id="security-password" name="password" password={true} value={password} prefix={<Lock sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Senha atual" onChange={changePasswordHandle} />
            </div>
            <PasswordForm showLabels={true} onPasswordChanged={setNewPassword} onConfirmPasswordChanged={setConfirmNewPassword} onFocus={toggleRequirements} showPasswordRequirements={showPasswordRequirements} required={[PasswordRequirement.length]} recommended={[PasswordRequirement.lowercase, PasswordRequirement.uppercase, PasswordRequirement.digit, PasswordRequirement.specialchar]} onRequirementsChanged={setRequirementsFulfilled} />
            <MainButton enabled={password !== "" && requirementsFulfilled} content="Alterar senha" onClick={save} />
        </div>;
    }
}

export { SecurityView };