import React from "react";
import { useSearchParams } from "react-router-dom";
import { Pin } from "@mui/icons-material";
import { SIGNIN_ID, SIGNUP_ID, View, CONFIRM_EMAIL_ID, REGISTER_NAME_ID } from "../../shared";
import { MainButton, ScreenLoader, TextInput } from "../../components";
import { ApiContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./verificationview.scss";

class ConfirmEmailView extends View {
    id = CONFIRM_EMAIL_ID;
    route = "/auth/confirm-email";
    defaultRoute = false;
    authNeeded = false;
    header = {
        backClick: () => { this.navigation!.navigate(-1); }
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [email, setEmail] = React.useState<string>();
        const [password, setPassword] = React.useState<string>();
        const [rememberMe, setRememberMe] = React.useState<boolean>(false);
        const [code, setCode] = React.useState<string>("");

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { navigate, views, goTo } = this.navigation = React.useContext(NavigationContext);

        const [searchParams] = useSearchParams();

        React.useEffect(() => {
            const rawRegisterData = sessionStorage.getItem("register");
            if(rawRegisterData == null) {
                navigate(-1);
            } else {
                const registerData = JSON.parse(rawRegisterData);
                setEmail(registerData["email"]);
                setPassword(registerData["password"]);
                setRememberMe(registerData["rememberMe"]);
            }
            setFirstLoad(false);
        }, []);

        const changeCodeHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const codeCandidate = e.currentTarget.value;
            if(codeCandidate.length <= 6 && (codeCandidate === "" || !isNaN(parseInt(codeCandidate)))) {
                setCode(codeCandidate);
            }
            e.preventDefault();
        }

        const verifyEmail = async () => {
            const failableEmailConfirm = await api.auth.confirmEmail(email!.trim(), code, password, rememberMe);
            failableEmailConfirm.match({
                success: emailConfirmed => {
                    if(emailConfirmed) {
                        sessionStorage.removeItem("register");
                        pushNotification("Conta criada com sucesso!");
                        goTo(views[REGISTER_NAME_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined);
                    } else {
                        pushNotification("Código de verificação inválido.");
                    }
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined);
                            break;
                        case "FETCH_GENERIC_ERROR":
                            pushNotification("Código de verificação inválido.");
                            break;
                    }
                }
            });
        }

        const verify = async (e: React.MouseEvent<HTMLButtonElement>) => {
            await verifyEmail();
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="verification">
            <div id="verification-explanation">
                Enviamos um código para seu e-mail<br /><br /><b>{email}</b><br /><small>(<a onClick={() => goTo(views[SIGNUP_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined)}>Esse não é o meu e-mail</a>)</small><br /><br />Insira-o na caixa abaixo para validá-lo:
            </div>
            <div className="verification-input-container">
                <div className="label">Código de verificação</div>
                <TextInput id="verification-code" name="verification-code" inputMode="numeric" value={code} prefix={<Pin sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000000" onChange={changeCodeHandle} />
            </div>
            <div className="verification-button-container">
                <MainButton enabled={code.length == 6} content="Verificar" onClick={verify} />
            </div>
        </div>
    }
}

export { ConfirmEmailView };