import React from "react";
import { ApiContext } from "./api";
import { Customer } from "../shared";
import { AuthContext } from "./auth";

type Dispatch<A> = (value: A) => void;
export type CustomerState = {
    customer: Customer | null,
    setCustomer: Dispatch<React.SetStateAction<Customer | null>>,
    skipWhatsApp: boolean,
    setSkipWhatsApp: Dispatch<React.SetStateAction<boolean>>,
};

const initialCustomerState: CustomerState = {
    customer: null,
    setCustomer: () => {},
    skipWhatsApp: false,
    setSkipWhatsApp: () => {},
};

export const CustomerContext = React.createContext(initialCustomerState);

export const CustomerProvider = ({ children } : { children: React.ReactNode}) => {
    const [customer, setCustomer] = React.useState(initialCustomerState.customer);
    const [skipWhatsApp, setSkipWhatsApp] = React.useState<boolean>(initialCustomerState.skipWhatsApp);

    const api = React.useContext(ApiContext);
    const { auth } = React.useContext(AuthContext);

    const loadCustomer = async () => {
        const failableCustomerData = await api.customer.getCustomerData();
        return failableCustomerData.match({
            success: customer => customer,
            failure: () => null
        });
    }

    const init = () => {
        loadCustomer().then(setCustomer);
    }

    const checkSkipWhatsApp = () => {
        if(!!customer?.email) {
            setSkipWhatsApp(!!localStorage.getItem(`no-whatsapp-${customer?.email}`));
        }
    }

    const makeSkipWhatsApp = () => {
        if(skipWhatsApp && !!customer?.email) {
            localStorage.setItem(`no-whatsapp-${customer?.email}`, "true");
        }
    }

    React.useEffect(init, []);
    React.useEffect(init, [auth]);
    React.useEffect(checkSkipWhatsApp, [customer]);
    React.useEffect(makeSkipWhatsApp, [skipWhatsApp]);

    return (
        <CustomerContext.Provider value={{ customer, setCustomer, skipWhatsApp, setSkipWhatsApp }}>
            {children}
        </CustomerContext.Provider>
    );
};