import React from "react";
import { IFailable, failableAsync } from "ts-failable";
import { ApiNotificationError, NotificationSettings, PassifyApi } from "../../shared";
import { AuthContext } from "../auth";

type F<T> = IFailable<T, ApiNotificationError>;
type P<T> = Promise<F<T>>;

export type ApiNotification = {
    updateEmailNotificationSettings: (notifications: Array<string>) => P<boolean>,
    updateWhatsAppNotificationSettings: (notifications: Array<string>) => P<boolean>,
    markNotificationAsRead: (notificationIds: Array<string>) => P<boolean>,
    getNotificationSettings: () => P<NotificationSettings>,
    getNotifications: () => P<Array<any>>,
}

export const ApiNotificationProvider = (): ApiNotification => {
    const { getAuth, refreshAuth } = React.useContext(AuthContext);

    const updateEmailNotificationSettings = async (notifications: Array<string>): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.updateEmailNotificationSettings(auth.accessToken, notifications);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as boolean);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return updateEmailNotificationSettings(notifications);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    const updateWhatsAppNotificationSettings = async (notifications: Array<string>): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.updateWhatsAppNotificationSettings(auth.accessToken, notifications);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as boolean);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return updateWhatsAppNotificationSettings(notifications);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    const markNotificationAsRead = async (notificationIds: Array<string>): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.markNotificationAsRead(auth.accessToken, notificationIds);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(true);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return markNotificationAsRead(notificationIds);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    const getNotificationSettings = async (): Promise<F<NotificationSettings>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.getNotificationSettings(auth.accessToken);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as NotificationSettings);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return getNotificationSettings();
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    const getNotifications = async (): Promise<F<Array<any>>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.getNotifications(auth.accessToken);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as Array<any>);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return getNotifications();
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    return {
        updateEmailNotificationSettings,
        updateWhatsAppNotificationSettings,
        markNotificationAsRead,
        getNotificationSettings,
        getNotifications,
    };
};