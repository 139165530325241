import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation, faX } from "@fortawesome/free-solid-svg-icons";
import { Lock, Mail, Visibility, VisibilityOff } from "@mui/icons-material";
import { CheckboxInput, MainButton, PasswordForm, PasswordRequirement, TextInput } from "../../components";
import { CONFIRM_EMAIL_ID, EmailFormatter, SIGNIN_ID, SIGNUP_ID, View, executeRecaptcha } from "../../shared";
import { ApiContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./signupview.scss";
import { useSearchParams } from "react-router-dom";

class SignupView extends View {
    id = SIGNUP_ID;
    route = "/auth/signup";
    defaultRoute = false;
    authNeeded = false;
    header = {
        backClick: null,
        supportClick: () => {}
    };
    render = () => {
        const [email, setEmail] = React.useState<string>("");
        const [emailCursor, setEmailCursor] = React.useState<number>(0);
        const emailRef = React.useRef<HTMLInputElement>(null);
        const emailFormatter = new EmailFormatter();
        const [password, setPassword] = React.useState<string>("");
        const [confirmPassword, setConfirmPassword] = React.useState<string>("");
        const [requirementsFulfilled, setRequirementsFulfilled] = React.useState<boolean>(false);
        const [showPasswordRequirements, setShowPasswordRequirements] = React.useState<boolean>(false);
        const [rememberMe, setRememberMe] = React.useState<boolean>(true);
        const [agreeOnTerms, setAgreeOnTerms] = React.useState<boolean>(false);
        const [waitForClose, setWaitForClose] = React.useState<boolean>(false);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);

        const [searchParams] = useSearchParams();

        const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = emailFormatter.updateValue({text: email, cursor: emailCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setEmail(updatedValue.text);
            setEmailCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = emailRef.current;
            if(!!input) {
                input.setSelectionRange(emailCursor, emailCursor);
            }
        }, [emailRef, emailCursor, email]);

        React.useEffect(() => {
            if(waitForClose) {
                toggleRequirements(false);
            }
        }, [password, confirmPassword, agreeOnTerms]);

        const clickRememberMeHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setRememberMe(oldRememberMe => !oldRememberMe);
            e.preventDefault();
        }

        const clickAgreeOnTermsHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setAgreeOnTerms(oldAgreeOnTerms => !oldAgreeOnTerms);
            e.preventDefault();
        }

        const signup = async (e: React.MouseEvent<HTMLButtonElement>) => {
            if(requirementsFulfilled && email.trim().match(/^[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/) != null) {
                await executeRecaptcha(async (token: any) => {
                    const failableRegister = await api.auth.register(token, email.trim(), password);
                    failableRegister.match({
                        success: success => {
                            if(success) {
                                sessionStorage.setItem("register", JSON.stringify({
                                    reason: "confirmEmail",
                                    email: email.trim(),
                                    password: password,
                                    rememberMe: rememberMe,
                                }));
                                goTo(views[CONFIRM_EMAIL_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined);
                            }
                        },
                        failure: () => {
                            pushNotification("Erro ao se cadastrar.");
                        }
                    });
                });
            }
            e.preventDefault();
        };

        const toggleRequirements = (focus: boolean) => {
            if(focus) {
                setShowPasswordRequirements(true);
                setWaitForClose(false);
            } else if(requirementsFulfilled) {
                setShowPasswordRequirements(false);
                setWaitForClose(false);
            } else {
                setWaitForClose(true);
            }
        }

        return (
            <div id="signup">
                <div id="signup-form">
                    <TextInput ref={emailRef} id="signin-email" name="email" value={email} prefix={<Mail sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="E-mail" onChange={changeEmailHandle} />
                    <PasswordForm onPasswordChanged={setPassword} onConfirmPasswordChanged={setConfirmPassword} onFocus={toggleRequirements} showPasswordRequirements={showPasswordRequirements} required={[PasswordRequirement.length]} recommended={[PasswordRequirement.lowercase, PasswordRequirement.uppercase, PasswordRequirement.digit, PasswordRequirement.specialchar]} onRequirementsChanged={setRequirementsFulfilled} />
                    {false && <><CheckboxInput id="signin-remember-me" name="remember-me" checked={rememberMe} text="Manter-me logado" onClick={clickRememberMeHandle} />
                    <div></div></>}
                    <div className="terms"><CheckboxInput id="signin-agree-on-terms" name="agree-on-terms" checked={agreeOnTerms} text="" onClick={clickAgreeOnTermsHandle} /><div className="agree-on-terms-text">Eu aceito a <a href="https://passify.co/politica-de-privacidade.pdf" target="_blank">política de privacidade</a> e os <a href="https://passify.co/termos-de-servico.pdf" target="_blank">termos de serviço</a> da Passify</div></div>
                    <MainButton enabled={
                        requirementsFulfilled &&
                        email.trim().match(/^[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/) != null &&
                        agreeOnTerms
                    } content="Cadastrar" onClick={signup} />
                    <div id="signup-signin-text">Já tem conta? <a onClick={() => goTo(views[SIGNIN_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined)}>Fazer login</a></div>
                </div>
            </div>
        );
    }
}

export { SignupView };