import React from "react";
import { useParams } from "react-router-dom";
import { QueueClock, ScreenLoader, SecondaryButton } from "../../components";
import { View, PAYMENT_ID, PIX_PAYMENT_ID, CARD_PAYMENT_ID, EVENT_ID } from "../../shared";
import { NavigationContext, QueueContext } from "../../contexts";
import "./paymentview.scss";

class PaymentView extends View {
    id = PAYMENT_ID;
    route = "/checkout/payment/:eventId";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => { this.navigation!.navigate(-1); },
        supportClick: () => {}
    };
    render = () => {
        const params = this.params = useParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [expirationTime, setExpirationTime] = React.useState<number>();

        const { getParsedQueueToken } = React.useContext(QueueContext);
        const { navigate, views, goTo } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            let eventId = params["eventId"];

            if(eventId === undefined) {
                navigate(-1);
            }

            return { eventId: eventId ?? "" };
        }

        const init = () => {
            const { eventId } = getIds();

            setExpirationTime(getParsedQueueToken(eventId)?.exp);
            setFirstLoad(false);
        }

        React.useEffect(init, []);

        const selectPix = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const { eventId } = getIds();
            goTo(views[PIX_PAYMENT_ID], { eventId });
            e.preventDefault();
        }

        const selectCard = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const { eventId } = getIds();
            goTo(views[CARD_PAYMENT_ID], { eventId });
            e.preventDefault();
        }

        const expire = () => {
            const { eventId } = getIds();
            goTo(views[EVENT_ID], {eventId});
        }

        return firstLoad ? <ScreenLoader /> : <div id="payment">
            <QueueClock expirationTime={expirationTime} onExpire={expire} />
            <div id="payment-choose">
                Pagar com:
                <SecondaryButton content="PIX" onClick={selectPix} />
                <SecondaryButton content="Cartão de crédito" onClick={selectCard} />
            </div>
        </div>;
    }
}

export { PaymentView };