import { initMercadoPago } from "@mercadopago/sdk-react";
import { createCardToken, getInstallments } from "@mercadopago/sdk-react/coreMethods"
import { AbstractApi } from "./api";
import { MERCADO_PAGO_API_KEY } from "../../environment";

class MercadoPagoApi extends AbstractApi {
    private static _instance: MercadoPagoApi;
    protected _host = "https://api.mercadopago.com/v1";
    public readonly publicKey = MERCADO_PAGO_API_KEY ?? "";

    private constructor() {
        super();
        initMercadoPago(this.publicKey);
    }

    static get instance() { return this._instance || (this._instance = new this()); }

    initial = async () => {
        return await this._get(`/payment_methods/search?publick_key=${this.publicKey}&locale=pt-BR&js_version=2.0.0&referer=&marketplace=NONE&status=active&limit=1&site_id=MLB`);
    }
    
    getPaymentMethods = async (cardBin: string) => {
        return await this._get(`/payment_methods/search?publick_key=${this.publicKey}&locale=pt-BR&js_version=2.0.0&referer=&marketplace=NONE&status=active&bins=${cardBin.toString()}&processing_mode=aggregator&site_id=MLB`);
    }
    
    getInstallments = async (cardBin: string, amount: number) => {
        return await getInstallments({
          bin: cardBin,
          amount: amount.toFixed(2),
        });
    }
    
    createCardToken = async (
        cardNumber: string,
        cardHolderName: string,
        cardHolderIdType: string,
        cardHolderId: string,
        securityCode: string,
        expirationMonth: string,
        expirationYear: string
    ) => {
        return await createCardToken({
          cardNumber: cardNumber,
          cardholderName: cardHolderName,
          cardExpirationMonth: expirationMonth,
          cardExpirationYear: expirationYear,
          securityCode: securityCode,
          identificationType: cardHolderIdType,
          identificationNumber: cardHolderId,
        });
    }
}

const mercadoPagoApi = MercadoPagoApi.instance;

export { mercadoPagoApi as MercadoPagoApi };