import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ApiCheckoutError, REVIEW_SUMMARY_ID, SIGNIN_ID, View, TICKET_SELECTION_ID, executeRecaptcha, EVENT_ID, QUEUE_ID, SegmentList, AvailableTickets, CheckoutOrder, BatchList, ReservationList } from "../../shared";
import { ListTile, MainButton, QueueClock, ScreenLoader } from "../../components";
import { ApiContext, NavigationContext, NotificationsContext, QueueContext } from "../../contexts";
import "./ticketselectionview.scss";

interface SelectedTickets {
    [id: string]: number,
}

class TicketSelectionView extends View {
    id = TICKET_SELECTION_ID;
    route = "/checkout/ticket/:eventId/:eventSessionId";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[EVENT_ID], { eventId: this.params!["eventId"]! }); },
        supportClick: () => {}
    };
    render = () => {
        const params = this.params = useParams()
        const [searchParams, setSearchParams] = useSearchParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [commiting, setCommiting] = React.useState<boolean>(false);
        const [availableTickets, setAvailableTickets] = React.useState<SegmentList>([]);
        const [selectedTickets, setSelectedTickets] = React.useState<SelectedTickets>({});
        const [order, setOrder] = React.useState<CheckoutOrder>();
        const [maxTicketAmount, setMaxTicketAmount] = React.useState<number>(1);
        const [code, setCode] = React.useState<string>("");
        const [discount, setDiscount] = React.useState<number>(0);
        const [totalAmount, setTotalAmount] = React.useState<number>(0);
        const [expirationTime, setExpirationTime] = React.useState<number>();

        const { pushNotification } = React.useContext(NotificationsContext);
        const { getParsedQueueToken } = React.useContext(QueueContext);
        const api = React.useContext(ApiContext);
        const { location, navigate, views, goTo } = this.navigation = React.useContext(NavigationContext);
        
        let timer: NodeJS.Timer;
        const maxAmountPerTicket = Infinity; // change to backend

        let lock = false;

        const getIds = () => {
            let eventSessionId = params["eventSessionId"];
            let eventId = params["eventId"];

            if(eventId === undefined) {
                navigate(-1);
            } else if(eventSessionId === undefined) {
                goTo(views[EVENT_ID], {eventId});
            }

            return { eventId: eventId ?? "", eventSessionId: eventSessionId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            const { eventId } = getIds();

            return (error: ApiCheckoutError) => {
                switch(error.errorType) {
                    case "QUEUE_WAITING":
                        goTo(views[QUEUE_ID], { eventId }, { ref: location.pathname })
                        break;
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
                        break;
                    case "EMPTY_ORDER":
                        setTotalAmount(0);
                        break;
                    case "TICKET_RESERVATION_ERROR":
                        switch(error.code) {
                            case "LimitReached":
                                pushNotification("Você já atingiu a quantidade máxima de ingressos por pessoa.");
                                break;
                            case "NotFound":
                            case "Unavailable":
                                pushNotification("Quantidade de ingressos selecionada indisponível.");
                                break;
                        }
                        break;
                }
                return response;
            }
        }

        const loadAvailableTickets = async (eventId: string, eventSessionId: string, code?: string) => {
            const failureAvailableTickets = await api.checkout.getAvailableTickets(eventId, eventSessionId, code);
            return failureAvailableTickets.match<AvailableTickets>({
                success: availableTickets => availableTickets,
                failure: handleError({ticketSegments: []})
            });
        }

        const loadOrder = async (eventId: string) => {
            const failableOrder = await api.checkout.getCheckoutOrderPreview(eventId);
            return failableOrder.match({
                success: order => order,
                failure: handleError(null)
            });
        }

        const loadOrderById = async (orderId: string) => {
            const failableOrder = await api.checkout.getCheckoutOrderById(orderId);
            return failableOrder.match({
                success: order => order,
                failure: handleError(null)
            });
        }

        const init = () => {
            if(lock) return ;
            lock = true;

            const { eventId, eventSessionId } = getIds();
            const code = searchParams.get("code");

            setFirstLoad(true);
            loadAvailableTickets(eventId, eventSessionId, code || undefined).then(availableTickets => {
                const ticketSegments = availableTickets.ticketSegments;
                if(!!code) {
                    loadAvailableTickets(eventId, eventSessionId).then(uncodedAvailableTickets => {
                        const uncodedTicketSegments = uncodedAvailableTickets.ticketSegments;
                        if(
                            (ticketSegments.length === uncodedTicketSegments.length && ticketSegments.reduce((prev, cur) => prev + cur.ticketBatches.length, 0) === uncodedTicketSegments.reduce((prev, cur) => prev + cur.ticketBatches.length, 0)) ||
                            (ticketSegments.length === 0 || ticketSegments.reduce((prev, cur) => prev + cur.ticketBatches.length, 0) === 0)
                        ) {
                            setCode("");
                            setSearchParams({});
                            goTo(views[TICKET_SELECTION_ID], {eventId: eventId, eventSessionId});
                        }
                    });
                }
                setAvailableTickets(ticketSegments);
                setMaxTicketAmount(availableTickets.customerLimit ?? Infinity);
                    
                const handleOrder = (order: CheckoutOrder | null) => {
                    if(!!order) {
                        const newSelectedTickets: SelectedTickets = {};
                        for(let i = 0; i < order.reservations.length; i++) {
                            const reservation = order.reservations[i];
                            newSelectedTickets[reservation.batchId] = reservation.quantity;
                        }
                        setSelectedTickets(newSelectedTickets);

                        if(order.status !== "Draft") {
                            goTo(views[REVIEW_SUMMARY_ID], { eventId, eventSessionId });
                        }
                        setOrder(order);
                        setTotalAmount(order.totalAmount);
                    }
                    setExpirationTime(getParsedQueueToken(eventId)?.exp);
                    setFirstLoad(false);
                    lock = false;
                }

                const storedOrderId = window.localStorage.getItem(`order-${eventId}`);
                if(!!storedOrderId) {
                    loadOrderById(storedOrderId).then(order => {
                        if(!!order && ["Canceled", "Expired", "PaymentApproved", "Completed"].indexOf(order.status) === -1) {
                            handleOrder(order);
                        } else {
                            window.localStorage.removeItem(`order-${eventId}`);
                            loadOrder(eventId).then(handleOrder);
                        }
                    });
                } else {
                    loadOrder(eventId).then(handleOrder);
                }
            });
        }

        const debounce = () => {
            if(!firstLoad) {
                if(timer !== undefined) {
                    clearTimeout(timer);
                }

                setCommiting(true);
                timer = setTimeout(addToCart, 1000);

                return () => {
                    if(timer !== undefined) {
                        clearTimeout(timer);
                    }
                }
            }
        }

        React.useEffect(init, []);
        React.useEffect(init, [searchParams]);
        React.useEffect(debounce, [selectedTickets]);

        const addTicket = (batchId: string) => {
            return (e: React.MouseEvent<HTMLButtonElement>) => {
                if(ticketsSelected < maxTicketAmount && (selectedTickets[batchId] ?? 0) < maxAmountPerTicket) {
                    setSelectedTickets(oldSelected => ({
                        ...oldSelected,
                        [batchId]: (oldSelected[batchId] ?? 0) + 1
                    }));
                    setCommiting(true);
                }
                e.preventDefault();
            }
        }

        const removeTicket = (batchId: string) => {
            return (e: React.MouseEvent<HTMLButtonElement>) => {
                setSelectedTickets(oldSelected => ({
                    ...oldSelected,
                    [batchId]: Math.max((oldSelected[batchId] ?? 0) - 1, 0)
                }));
                setCommiting(true);
                e.preventDefault();
            }
        }

        const changeCodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            setCode(e.currentTarget.value.toLowerCase());
            e.preventDefault();
        }

        const applyCode = (e: React.MouseEvent<HTMLButtonElement>) => {
            setSearchParams({ code: encodeURIComponent(code) });
            e.preventDefault();
        }

        const ticketsSelected = availableTickets.reduce((prev, cur) => prev + cur.ticketBatches.reduce((prev, cur) => prev + (selectedTickets[cur.id] ?? 0), 0), 0);
        const subtotal = availableTickets.reduce((prev, cur) => prev + cur.ticketBatches.reduce((prev, cur) => prev + (selectedTickets[cur.id] ?? 0) * cur.price, 0), 0);
        const feeAmount = totalAmount - subtotal;

        const addToCart = async () => {
            const { eventId } = getIds();

            const batchSuccess: {[batchId: string]: boolean} = {};

            const batches = Object.keys(selectedTickets);
            await executeRecaptcha(async (token: any) => {
                for(let i = 0; i < batches.length; i++) {
                    const batchId = batches[i];
                    const failableUpdateReservation = await api.checkout.updateReservation(token, eventId, batchId, selectedTickets[batchId], !!searchParams.get("code") ? decodeURIComponent(searchParams.get("code") || "") : undefined);
                    batchSuccess[batchId] = failableUpdateReservation.match({
                        success: () => true,
                        failure: handleError(false)
                    });
                }
            });

            const success = Object.values(batchSuccess).reduce((prev, cur) => prev && cur, true);

            const order = await loadOrder(eventId);
            if(!!order) {
                setTotalAmount(order.totalAmount);

                if(!success) {
                    setSelectedTickets(oldSelectedTickets => {
                        const newSelectedTickets = {...oldSelectedTickets};
                        const batchIds = Object.keys(batchSuccess);
                        for(let i = 0; i < batchIds.length; i++) {
                            const batchId = batchIds[i];
                            if(!batchSuccess[batchId]) {
                                newSelectedTickets[batchId] = order.reservations.find(reservation => reservation.batchId === batchId)?.quantity ?? 0;
                            }
                        }
                        return newSelectedTickets;
                    });
                }
            }

            setCommiting(false);
            return success;
        }

        const proceedToShopping = async (e: React.MouseEvent<HTMLButtonElement>) => {
            if(commiting && timer !== undefined) {
                clearTimeout(timer);
            }
            if(!commiting || await addToCart()) {
                goTo(views[REVIEW_SUMMARY_ID], getIds());
            }
            e.preventDefault();
        }

        const expire = () => {
            const { eventId } = getIds();
            goTo(views[EVENT_ID], {eventId});
        }

        const mergeBatchesAndReservations = (batches: BatchList, reservations: ReservationList) => {
            return batches.filter(batch => !batch.isSoldOut || reservations.some(reservation => reservation.batchId === batch.id && reservation.quantity > 0));
        }

        return firstLoad ? <ScreenLoader /> : <div id="ticket-selection">
            <QueueClock expirationTime={expirationTime} onExpire={expire} />
            {!!searchParams.get("code") ?
                <div className="code-active-prompt">
                    Código <b>{decodeURIComponent(searchParams.get("code") || "")}</b> está ativo!
                </div> :
                <div className="code-form">
                    <input type="text" placeholder="Código" value={code} onChange={changeCodeHandler} />
                    <button onClick={applyCode}><FontAwesomeIcon icon={faPlay} /></button>
                </div>
            }
            {availableTickets.reduce((prev, cur) => prev + cur.ticketBatches.length, 0) > 0 ? <>
                {availableTickets.map(segment => segment.ticketBatches.length > 0 ? <div className="segment">
                    <ListTile title={segment.name} />
                    <div className="segment-batches">
                        {mergeBatchesAndReservations(segment["ticketBatches"], !!order ? order.reservations : []).length > 0 ?
                            mergeBatchesAndReservations(segment["ticketBatches"], !!order ? order.reservations : []).map(batch => <div className="batch">
                                <ListTile separator={false} title={batch.name} subtitle={`R$ ${batch.price.toFixed(2)}`} trailing={<div className="batch-selection-quantity">
                                    <div className="batch-selector">
                                        <div><button onClick={removeTicket(batch.id)} disabled={(selectedTickets[batch.id] ?? 0) == 0}><FontAwesomeIcon icon={faMinus} /></button></div>
                                        <div className="batch-quantity-display">{selectedTickets[batch.id] ?? 0}</div>
                                        <div><button onClick={addTicket(batch.id)}><FontAwesomeIcon icon={faPlus} /></button></div>
                                    </div>
                                    <div className="batch-subtotal">
                                        Total: R$ {((selectedTickets[batch.id] ?? 0) * batch.price).toFixed(2)}
                                    </div>
                                </div>} />
                            </div>) :
                            <div className="code-active-prompt">
                                Ingressos esgotados!
                            </div>
                        }
                    </div>
                </div> : <></>)}
                <div className="summary">
                    <div className="summary-item">
                        <span>Subtotal:</span>
                        <span>R$ {subtotal.toFixed(2)}</span>
                    </div>
                    <div className="summary-item">
                        <span>Taxa de serviço:</span>
                        {commiting ?
                            <div className="summary-loader-progressbar"><div className="summary-loader-progressbar-circle"></div></div> :
                            <span>R$ {feeAmount.toFixed(2)}</span>
                        }
                    </div>
                    {discount > 0 && <div className="summary-item">
                        <span>Desconto:</span>
                        <span>- R$ {discount.toFixed(2)}</span>
                    </div>}
                    <div className="summary-item total">
                        <span>Total:</span>
                        {commiting ?
                            <div className="summary-loader-progressbar"><div className="summary-loader-progressbar-circle"></div></div> :
                            <span>R$ {totalAmount.toFixed(2)}</span>
                        }
                    </div>
                </div>
                <div className="checkout-button-container">
                    <MainButton enabled={ticketsSelected > 0} content="Ir para pagamento" onClick={proceedToShopping} />
                </div>
            </> : <div className="code-active-prompt">
                Não há ingressos a venda!
            </div>}
        </div>;
    }
}

export { TicketSelectionView };