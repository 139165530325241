import React from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { ArrowBackIos, AttachMoney, CalendarMonth, LocationOn, Send } from "@mui/icons-material";
import { EVENT_ID, EventDetails, EVENTS_ID, View, TICKET_SELECTION_ID } from "../../shared";
import { ListTile, MainButton, ScreenLoader } from "../../components";
import { ApiContext, AuthContext, MetaContext, NavigationContext } from "../../contexts";
import "./eventview.scss";

class EventView extends View {
    id = EVENT_ID;
    route = "/event/:eventId";
    defaultRoute = false;
    authNeeded = false;
    header = undefined;
    render = () => {
        const params = this.params = useParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [event, setEvent] = React.useState<EventDetails>();

        const { setTags } = React.useContext(MetaContext);
        const { auth } = React.useContext(AuthContext);
        const api = React.useContext(ApiContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const eventId = params["eventId"];
            if(!eventId) {
                goTo(views[EVENTS_ID]);
            }

            return { eventId: eventId ?? "" };
        }

        const changeOGMeta = () => {
            if(!!event) {
                setTags({
                    title: event.name,
                    type: "website",
                    description: event.shortDescription ?? "",
                    image: event.smallImageUrl ?? "https://www.passify.co/passify.png",
                    url: `https://app.passify.co/event/${event.slug}`,
                    siteName: `${event.name} on Passify`,
                });
            }
        }

        const init = () => {
            const { eventId } = getIds();

            api.event.getEventDetails(eventId).then(failableEventDetails => {
                failableEventDetails.match({
                    success: setEvent,
                    failure: error => {
                        switch(error.errorType) {
                            case "EVENT_NOT_FOUND":
                                goTo(views[EVENTS_ID]);
                                break;
                        }
                    }
                });
                setFirstLoad(false);
            });
        }

        React.useEffect(init, []);
        React.useEffect(changeOGMeta, [event]);

        const proceedToShopping = async (e: React.MouseEvent<HTMLButtonElement>) => {
            if(event !== undefined && event.sessions !== undefined) {
                if(event.sessions.length === 1) {
                    goTo(views[TICKET_SELECTION_ID], {
                        eventId: event.id,
                        eventSessionId: event.sessions[0].id
                    });
                } else {
                    goTo(views["SELECT_SESSION_ID"], { // change to constant
                        eventId: event.id
                    });
                }
            }
            e.preventDefault();
        }

        return firstLoad || event === undefined ? <ScreenLoader /> : <div id="event">
            <div id="event-back-button"><a onClick={() => goTo(views[EVENTS_ID])}><ArrowBackIos /></a></div>
            <div id="event-share-button"><a href={`https://wa.me/?text=${encodeURIComponent(`Bora na ${event.name}? https://app.passify.co/event/${event.slug}`)}`}><Send /></a></div>
            <div id="event-image-container">
                <img src={event.bigImageUrl} />
            </div>
            <div id="event-name">{event.name}</div>
            <ListTile separator={false} leading={<div className="circle-icon-container"><CalendarMonth /></div>} title={DateTime.fromMillis(Date.parse(event.startDate)).toFormat("ccc, d LLL yyyy", { locale: "pt-br" })} subtitle={`${DateTime.fromMillis(Date.parse(event.startDate)).toFormat("HH:mm", { locale: "pt-br" })} - ${DateTime.fromMillis(Date.parse(event.endDate)).toFormat("HH:mm", { locale: "pt-br" })} (GMT -03:00)`} />
            <ListTile separator={false} leading={<div className="circle-icon-container"><LocationOn /></div>} title={event.venue.name} subtitle={event.venue.street1} />
            <ListTile separator={false} leading={<div className="circle-icon-container"><AttachMoney /></div>} title={`R$ ${event.priceRangeStart === event.priceRangeEnd ? event.priceRangeStart.toFixed(2) : `${event.priceRangeStart.toFixed(2)} - ${event.priceRangeEnd.toFixed(2)}`}`} subtitle={event.priceRangeStart === event.priceRangeEnd ? "Preço único" : "Preço varia com tipo do ingresso"} />
            <div className="divider"><hr /></div>
            <ListTile separator={false} leading={<div className="circle-icon-container"><img src={event.producer.imageUrl} /></div>} title={event.producer.name} subtitle={"Organizador"} />
            <div id="event-about">
                <h3>Sobre o evento</h3>
                <p>{event.longDescription.split("\n").map((item, i) => <span key={i}>{item}<br /></span>)}</p>
            </div>
            <div id="event-button-container">
                <MainButton enabled={Date.parse(event.endDate) >= new Date().getTime()} content={Date.parse(event.endDate) < new Date().getTime() ? "Evento encerrado" : !!auth ? "Comprar ingressos" : "Logar para comprar"} onClick={proceedToShopping} />
            </div>
        </div>;
    }
}

export { EventView };