import React from "react";
import md5 from "md5";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faHome, faSignIn, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { ArrowBackIos, HeadsetMicOutlined, NotificationsOutlined, QrCode, Wallet } from "@mui/icons-material";
import { PASS_ID, SETTINGS_ID, HOME_ID, EVENTS_ID, SIGNIN_ID, REGISTER_PHONE_ID, CHANGE_PHONE_ID, REGISTER_NAME_ID } from "./shared";
import { ScreenLoader } from "./components";
import { AuthContext, CustomerContext, NavigationContext, NotificationsContext, ThemeContext } from "./contexts";
import "./app.scss";

const App = () => {
    const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

    const { notifications } = React.useContext(NotificationsContext);
    const { auth, setAuth } = React.useContext(AuthContext);
    const { customer, skipWhatsApp } = React.useContext(CustomerContext);
    const { location, views, goTo, routeBuilder, currentView } = React.useContext(NavigationContext);
    const { theme } = React.useContext(ThemeContext);

    const [searchParams] = useSearchParams();

    const init = () => {
        setFirstLoad(false);
    }

    const requireCustomerData = () => {
        if(!!customer && !!customer.email) {
            if(!customer.name) {
                if(!!currentView && [REGISTER_NAME_ID].indexOf(currentView.id) === -1) {
                    goTo(views[REGISTER_NAME_ID], undefined, !!searchParams.get("ref") ? { ref: searchParams.get("ref")! } : { ref: location.pathname });
                }
            } else if(!customer.phone) {
                if(!!currentView && [REGISTER_PHONE_ID, CHANGE_PHONE_ID].indexOf(currentView.id) === -1 && (!skipWhatsApp && !localStorage.getItem(`no-whatsapp-${customer.email}`))) {
                    goTo(views[REGISTER_PHONE_ID], undefined, !!searchParams.get("ref") ? { ref: searchParams.get("ref")! } : { ref: location.pathname });
                }
            }
        }
    }

    React.useEffect(init, []);
    React.useEffect(requireCustomerData, [customer, currentView]);

    const greetings = () => {
        const now = new Date();
        const hour = now.getHours();
        if(hour >= 5 && hour < 12) {
            return "Bom dia";
        } else if(hour >= 12 && hour < 18) {
            return "Boa tarde";
        } else {
            return "Boa noite";
        }
    }

    const signout = async (e: React.MouseEvent<HTMLLIElement>) => {
        setAuth(null);
        goTo(views[SIGNIN_ID]);
        e.preventDefault();
    }

    const backCorner = (onClick: React.MouseEventHandler<HTMLAnchorElement>) => <a onClick={onClick}><ArrowBackIos /></a>;
    const notificationsCorner = (onClick: React.MouseEventHandler<HTMLDivElement>) => <div id="app-header-notifications-container" onClick={onClick}><NotificationsOutlined /></div>;
    const supportCorner = (onClick: React.MouseEventHandler<HTMLAnchorElement>) => <a href="https://wa.me/5511956621753" onClick={onClick}><HeadsetMicOutlined sx={{fontSize: 18}} /></a>;

    return (
        <main id="app">
            <div id="app-notifications">
                {notifications.map(notification =>
                    <div key={notification.id} className={`app-notification ${notification.show ? "show" : "hide"}`}>
                        {notification.message}
                    </div>
                )}
            </div>
            {currentView === undefined || currentView.header === undefined ? <></> : <header id="app-header">
                {"backClick" in currentView.header ? <>
                    <div className="app-header-corner left">
                        {currentView.header.backClick !== null && backCorner(currentView.header.backClick)}
                    </div>
                    <h1><img id="app-logo" src={`/images/passify-${theme}.svg`} alt="Passify" /></h1>
                    <div className="app-header-corner right">
                        {currentView.header.supportClick !== undefined && supportCorner(currentView.header.supportClick)}
                    </div>
                </> : <>
                    <div className="app-header-corner left">
                        <div id="app-header-avatar" onClick={currentView.header.profileClick}>{!!customer ? <img src={`https://gravatar.com/avatar/${md5(customer.email)}?d=retro`} /> : "?"}</div>
                    </div>
                    <div id="app-header-greetings">
                        <h2>{greetings()} 👋</h2>
                        <h3>{!!customer && customer.name !== undefined ? customer.name : "Visitante"}</h3>
                    </div>
                    <div className="app-header-corner right">
                        {currentView.header.notificationsClick !== undefined && notificationsCorner(currentView.header.notificationsClick)}
                        {currentView.header.supportClick !== undefined && supportCorner(currentView.header.supportClick)}
                    </div>
                </>}
            </header>}
            <div id="app-body">
                {firstLoad || routeBuilder === undefined ? <ScreenLoader /> : <Routes>
                    <Route path="/">
                        {routeBuilder.build()}
                    </Route>
                </Routes>}
            </div>
            <footer id="app-navbar">
                <div id="app-navbar-container">
                    <div id="app-navbar-overlay"></div>
                    <nav id="app-navbar-content">
                        <ul>
                            <li className={["/event", "/checkout"].find(path => location.pathname.indexOf(path) == 0) != undefined ? "active" : ""} onClick={() => goTo(views[EVENTS_ID])}>
                                <div className="app-navbar-icon"><FontAwesomeIcon icon={faHome} /></div>
                                <span className="app-navbar-text">Eventos</span>
                            </li>
                            <li className={location.pathname == "" || location.pathname == "/" || ["/home", "/wallet"].find(path => location.pathname.indexOf(path) == 0) != undefined ? "active" : ""} onClick={() => goTo(views[HOME_ID])}>
                                <div className="app-navbar-icon"><Wallet sx={{fontSize: "20px"}} /></div>
                                <span className="app-navbar-text">Carteira</span>
                            </li>
                            <li className={["/pass"].find(path => location.pathname.indexOf(path) == 0) != undefined ? "active" : ""} onClick={() => goTo(views[PASS_ID])}>
                                <div className="app-navbar-icon"><QrCode sx={{fontSize: "20px"}} /></div>
                                <span className="app-navbar-text">Meu passe</span>
                            </li>
                            <li className={["/settings"].find(path => location.pathname.indexOf(path) == 0) != undefined ? "active" : ""} onClick={() => goTo(views[SETTINGS_ID])}>
                                <div className="app-navbar-icon"><FontAwesomeIcon icon={faGear} /></div>
                                <span className="app-navbar-text">Config</span>
                            </li>
                            {auth != null ?
                                <li className={["/auth"].find(path => location.pathname.indexOf(path) == 0) != undefined ? "active" : ""} onClick={signout}>
                                    <div className="app-navbar-icon"><FontAwesomeIcon icon={faSignOut} /></div>
                                    <span className="app-navbar-text">Sair</span>
                                </li> :
                                <li className={["/auth"].find(path => location.pathname.indexOf(path) == 0) != undefined ? "active" : ""} onClick={() => goTo(views[SIGNIN_ID])}>
                                    <div className="app-navbar-icon"><FontAwesomeIcon icon={faSignIn} /></div>
                                    <span className="app-navbar-text">Entrar</span>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
            </footer>
        </main>
    );
}

export { App };
