import React from "react";
import md5 from "md5";
import { AccountBox, CalendarMonth, Person } from "@mui/icons-material";
import { DropDownInput, MainButton, ScreenLoader, TextInput } from "../../components";
import { CpfFormatter, PROFILE_ID, SIGNIN_ID, View, DateFormatter, SETTINGS_ID } from "../../shared";
import { ApiContext, CustomerContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./profileview.scss";
import { faTransgender } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ProfileView extends View {
    id = PROFILE_ID;
    route = "/settings/profile";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => this.navigation!.goTo(this.navigation!.views[SETTINGS_ID]),
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

        const [profilePic, setProfilePic] = React.useState<string>("");

        const [name, setName] = React.useState<string>("");
        const nameRef = React.useRef<HTMLInputElement>(null);

        const [cpf, setCpf] = React.useState<string>("");
        const [cpfCursor, setCpfCursor] = React.useState<number>(0);
        const cpfRef = React.useRef<HTMLInputElement>(null);
        const cpfFormatter = new CpfFormatter();

        const [dateOfBirth, setDateOfBirth] = React.useState<string>("");
        const [dateOfBirthCursor, setDateOfBirthCursor] = React.useState<number>(0);
        const dateOfBirthRef = React.useRef<HTMLInputElement>(null);
        const dateFormatter = new DateFormatter();

        const [gender, setGender] = React.useState<string>("");
        const genderRef = React.useRef<HTMLInputElement>(null);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { customer, setCustomer } = React.useContext(CustomerContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);

        React.useEffect(() => {
            api.customer.getCustomerData().then(failabelCustomerData => {
                failabelCustomerData.match({
                    success: customerData => {
                        const dobParts = (customerData.dateOfBirth as (string | null) ?? "").split("-");

                        setProfilePic(`https://gravatar.com/avatar/${md5(customerData.email)}?d=retro`);
                        setName(customerData.name ?? "");
                        setCpf(cpfFormatter.mask(customerData.taxId ?? "") || "");
                        setDateOfBirth(dobParts.length >= 3 ? `${dobParts[2]}/${dobParts[1]}/${dobParts[0]}` : "");
                        setGender(customerData.gender ?? "");
                    },
                    failure: () => goTo(views[SIGNIN_ID])
                });
                setFirstLoad(false);
            });
        }, []);

        const changeNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setName(e.currentTarget.value);
            e.preventDefault();
        }

        const changeCpfHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = cpfFormatter.updateValue({text: cpf, cursor: cpfCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setCpf(updatedValue.text);
            setCpfCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = cpfRef.current;
            if(!!input) {
                input.setSelectionRange(cpfCursor, cpfCursor);
            }
        }, [cpfRef, cpfCursor, cpf]);

        const changeDobHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = dateFormatter.updateValue({text: dateOfBirth, cursor: dateOfBirthCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setDateOfBirth(updatedValue.text);
            setDateOfBirthCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = dateOfBirthRef.current;
            if(!!input) {
                input.setSelectionRange(dateOfBirthCursor, dateOfBirthCursor);
            }
        }, [dateOfBirthRef, dateOfBirthCursor, dateOfBirth]);

        const changeGenderHandle = (value?: string | number | readonly string[]) => {
            if(value !== undefined) {
                setGender(value.toString());
            }
        }

        const formatDate = (date: string) => {
            const unmaskedDate = dateFormatter.unmask(date);
            return `${unmaskedDate.substring(4, 8)}-${unmaskedDate.substring(2, 4)}-${unmaskedDate.substring(0, 2)}`;
        }

        const save = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const failableSaveCustomerData = await api.customer.saveCustomerData(name, cpfFormatter.unmask(cpf), formatDate(dateOfBirth), gender);
            await failableSaveCustomerData.match({
                success: customerDataSaved => {
                    if(customerDataSaved) {
                        setCustomer({
                            email: customer!.email,
                            name: name,
                            taxId: cpfFormatter.unmask(cpf),
                            dateOfBirth: formatDate(dateOfBirth),
                            phone: customer?.phone,
                            gender: gender,
                        });
                        pushNotification("Perfil atualizado com sucesso!");
                    }
                    return Promise.resolve();
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID]);
                            break;
                        default:
                            pushNotification("Erro ao atualizar perfil.");
                            break;
                    }
                    return Promise.resolve();
                }
            });
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="profile">
            <div id="profile-picture-container">
                <img src={profilePic} />
            </div>
            <div className="profile-form-field">
                <div className="label">Nome</div>
                <TextInput ref={nameRef} id="profile-name" name="name" value={name} prefix={<Person sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Nome e sobrenome" onChange={changeNameHandle} />
            </div>
            <div className="profile-form-field">
                <div className="label">CPF</div>
                <TextInput ref={cpfRef} id="profile-cpf" name="cpf" inputMode="numeric" value={cpf} prefix={<AccountBox sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000.000.000-00" onChange={changeCpfHandle} />
            </div>
            <div className="profile-form-field">
                <div className="label">Data de aniversário</div>
                <TextInput ref={dateOfBirthRef} id="profile-dob" name="dob" inputMode="numeric" value={dateOfBirth} prefix={<CalendarMonth sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="dd/mm/aaaa" onChange={changeDobHandle} />
            </div>
            <div className="profile-form-field">
                <div className="label">Como você se identifica?</div>
                <DropDownInput id="profile-gender" name="gender" selectedValue={gender} prefix={<FontAwesomeIcon icon={faTransgender} style={{fontSize: 16.67, opacity: 0.6}} />} items={[
                    {
                        text: "Mulher",
                        value: "female",
                    },
                    {
                        text: "Homem",
                        value: "male",
                    },
                    {
                        text: "Nenhum destes",
                        value: "non-binary",
                    },
                    {
                        text: "Prefiro não informar",
                        value: "not-informed",
                    }
                ]} onChange={changeGenderHandle} />
            </div>
            <MainButton content="Salvar perfil" onClick={save} />
        </div>;
    }
}

export { ProfileView };