import { v4 } from "uuid";

export const HOME_ID = v4();
export const WALLET_EVENT_ID = v4();
export const TRANSFER_ID = v4();
export const TRANSFER_SEND_ID = v4();
export const TRANSFER_RECEIVE_ID = v4();
export const EVENTS_ID = v4();
export const EVENT_ID = v4();
export const QUEUE_ID = v4();
export const TICKET_SELECTION_ID = v4();
export const REVIEW_SUMMARY_ID = v4();
export const PAYMENT_ID = v4();
export const CARD_PAYMENT_ID = v4();
export const PIX_PAYMENT_ID = v4();
export const PASS_ID = v4();
export const SETTINGS_ID = v4();
export const PROFILE_ID = v4();
export const CONTACT_ID = v4();
export const CHANGE_EMAIL_ID = v4();
export const CHANGE_PHONE_ID = v4();
export const SECURITY_ID = v4();
export const NOTIFICATIONS_SETTINGS_ID = v4();
export const REGISTER_PHONE_ID = v4();
export const REGISTER_NAME_ID = v4();
export const SIGNIN_ID = v4();
export const SIGNUP_ID = v4();
export const CONFIRM_EMAIL_ID = v4();
export const CREATE_PASSWORD_ID = v4();
export const FORGOT_PASSWORD_ID = v4();
export const RESET_PASSWORD_ID = v4();
//export const VERIFICATION_ID = v4();