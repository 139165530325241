import React from "react";
import { useSearchParams } from "react-router-dom";
import { Person } from "@mui/icons-material";
import { MainButton, ScreenLoader, TextInput } from "../../components";
import { HOME_ID, REGISTER_NAME_ID, REGISTER_PHONE_ID, SIGNIN_ID, View } from "../../shared";
import { ApiContext, CustomerContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./registernameview.scss";

class RegisterNameView extends View {
    id = REGISTER_NAME_ID;
    route = "/settings/profile/register-name";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: null,
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [name, setName] = React.useState<string>("");
        const nameRef = React.useRef<HTMLInputElement>(null);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { customer, setCustomer } = React.useContext(CustomerContext);
        const { navigate, views, goTo, routeBuilder } = this.navigation = React.useContext(NavigationContext);

        const [searchParams] = useSearchParams();

        const init = () => {
            setName(customer?.name ?? "");
            setFirstLoad(false);
        }

        React.useEffect(init, []);

        const changeNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setName(e.currentTarget.value);
            e.preventDefault();
        }

        const registerName = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const failableSaveCustomerData = await api.customer.saveCustomerData(name, customer?.taxId, customer?.dateOfBirth);
            await failableSaveCustomerData.match({
                success: customerDataSaved => {
                    if(customerDataSaved) {
                        setCustomer({
                            email: customer!.email,
                            name: name,
                            taxId: customer?.taxId,
                            dateOfBirth: customer?.dateOfBirth,
                            phone: customer?.phone,
                        });
                        pushNotification("Nome cadastrado com sucesso!");

                        if(!!customer?.phone) {
                            const ref = searchParams.get("ref");
                            if(!!ref && routeBuilder?.find(ref) !== "/") {
                                navigate(ref);
                            } else {
                                goTo(views[HOME_ID], undefined,  { ref: !!searchParams.get("ref") ? searchParams.get("ref")! : views[HOME_ID].route });
                            }
                        } else {
                            goTo(views[REGISTER_PHONE_ID], undefined,  { ref: !!searchParams.get("ref") ? searchParams.get("ref")! : views[HOME_ID].route });
                        }
                    }
                    return Promise.resolve();
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined);
                            break;
                        default:
                            pushNotification("Erro ao cadastrar nome.");
                            break;
                    }
                    return Promise.resolve();
                }
            });
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="register-name">
            <div className="register-name-prompt">Informe seu nome para concluir o cadastro:</div>
            <TextInput ref={nameRef} id="profile-name" name="name" value={name} prefix={<Person sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Nome e sobrenome" onChange={changeNameHandle} />
            <MainButton enabled={name.length > 0} content="Cadastrar nome" onClick={registerName} />
        </div>;
    }
}

export { RegisterNameView };