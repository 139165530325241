import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation, faX } from "@fortawesome/free-solid-svg-icons";
import { TextInput } from "../inputs";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import "./passwordform.scss";

export enum PasswordRequirement {
    match,
    length,
    lowercase,
    uppercase,
    digit,
    specialchar,
}

interface PasswordRequirements {
    [type: string | number]: { check: () => boolean, description: string }
}

interface PasswordFormProps {
    onPasswordChanged?: (password: string) => void,
    onConfirmPasswordChanged?: (confirmPassword: string) => void,
    onFocus?: (focus: boolean) => void,
    showLabels?: boolean,
    showPasswordRequirements?: boolean,
    additionalRequirements?: PasswordRequirements,
    required: Array<PasswordRequirement | string>,
    recommended: Array<PasswordRequirement | string>,
    onRequirementsChanged?: (fulfilled: boolean) => void,
}

const PasswordForm = (props: PasswordFormProps) => {

    const [password, setPassword] = React.useState<string>("");
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(false);

    const passwordRequirementsRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if(!!props.onPasswordChanged) {
            props.onPasswordChanged(password);
        }
    }, [password]);

    React.useEffect(() => {
        if(!!props.onConfirmPasswordChanged) {
            props.onConfirmPasswordChanged(confirmPassword);
        }
    }, [confirmPassword]);

    const passwordRequirements: PasswordRequirements = {
        ...props.additionalRequirements,
        [PasswordRequirement.match]: {
            check: () => password === confirmPassword,
            description: "Senha e confirmação de senha iguais",
        },
        [PasswordRequirement.length]: {
            check: () => password.length >= 6,
            description: "Mínimo de 6 caracteres",
        },
        [PasswordRequirement.lowercase]: {
            check: () => /[a-z]/.test(password),
            description: "Pelo menos uma letra minúscula",
        },
        [PasswordRequirement.uppercase]: {
            check: () => /[A-Z]/.test(password),
            description: "Pelo menos uma letra maiúscula",
        },
        [PasswordRequirement.digit]: {
            check: () => /[0-9]/.test(password),
            description: "Pelo menos um número",
        },
        [PasswordRequirement.specialchar]: {
            check: () => /[!@#$%^&*(),.?":{}|<>]/.test(password),
            description: "Pelo menos um caractere especial (!,@,*, ...)",
        },
    };

    const changePasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value);
        e.preventDefault();
    };

    const changeConfirmPasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setConfirmPassword(e.currentTarget.value);
        e.preventDefault();
    };

    const changeFocus = (focus: boolean) => {
        return (e: React.FormEvent<HTMLInputElement>) => {
            if(props.onFocus !== undefined) {
                props.onFocus(focus);
            }
            e.preventDefault();
        }
    }

    const validIcon = <FontAwesomeIcon icon={faCheck} />
    const recommendedIcon = <FontAwesomeIcon icon={faExclamation} />
    const invalidIcon = <FontAwesomeIcon icon={faX} />

    const requirement = (valid: boolean, text: string) => {
        return <div key={text} className={valid ? "requirement-valid" : "requirement-invalid"}>
            {valid ? validIcon : invalidIcon}
            <div className="requirement-text">{text}</div>
        </div>;
    }

    const recommendation = (valid: boolean, text: string) => {
        return <div key={text} className={valid ? "requirement-valid" : "requirement-recommended"}>
            {valid ? validIcon : recommendedIcon}
            <div className="requirement-text">{text}</div>
        </div>;
    }

    const requiredWithMatch = props.required.indexOf(PasswordRequirement.match) === - 1 ? [PasswordRequirement.match, ...props.required] : props.required;
    const filteredRequired = requiredWithMatch.filter((req, i) => requiredWithMatch.indexOf(req) === i && req in passwordRequirements);
    const filteredRecommended = props.recommended.filter((rec, i) => props.recommended.indexOf(rec) === i && props.required.indexOf(rec) === -1 && rec in passwordRequirements);

    React.useEffect(() => {
        if(props.onRequirementsChanged !== undefined) {
            props.onRequirementsChanged(filteredRequired.reduce((prev, cur) => prev && passwordRequirements[cur].check(), true));
        }
    }, [password, confirmPassword])

    return <>
        <div className="password-form-input">
            {!!props.showLabels && <div className="label">Senha</div>}
            <TextInput id="password-form-password" name="password" password={!showPassword} value={password} prefix={<Lock sx={{fontSize: 16.67, opacity: 0.6}} />} suffix={showPassword ? <VisibilityOff sx={{fontSize: 16.67, opacity: 0.6}} onClick={() => { setShowPassword(false); }} /> : <Visibility sx={{fontSize: 16.67, opacity: 0.6}} onClick={() => { setShowPassword(true); }} /> } placeholder="Senha" onChange={changePasswordHandle} onFocus={changeFocus(true)} onBlur={changeFocus(false)} />
        </div>
        <div className="password-form-input">
            {!!props.showLabels && <div className="label">Confirme a senha</div>}
            <TextInput id="password-form-confirm-password" name="confirm-password" password={!showConfirmPassword} value={confirmPassword} prefix={<Lock sx={{fontSize: 16.67, opacity: 0.6}} />} suffix={showConfirmPassword ? <VisibilityOff sx={{fontSize: 16.67, opacity: 0.6}} onClick={() => { setShowConfirmPassword(false); }} /> : <Visibility sx={{fontSize: 16.67, opacity: 0.6}} onClick={() => { setShowConfirmPassword(true); }} /> } placeholder="Confirme a senha" onChange={changeConfirmPasswordHandle} onFocus={changeFocus(true)} onBlur={changeFocus(false)} />
        </div>
        <div ref={passwordRequirementsRef} className={`password-requirements ${props.showPasswordRequirements ? "open" : ""}`} style={{"--password-requirements-size": `${18 * ((1 + filteredRequired.length) + ((filteredRecommended.length > 0 ? 2 : 0) + filteredRecommended.length))}px`} as React.CSSProperties}>
            <div>Obrigatório:</div>
            {filteredRequired.map(req => requirement(passwordRequirements[req].check(), passwordRequirements[req].description))}
            {filteredRecommended.length > 0 && <>
                <div></div>
                <div>Recomendado:</div>
                {filteredRecommended.map(rec => recommendation(passwordRequirements[rec].check(), passwordRequirements[rec].description))}
            </>}
        </div>
    </>;
}

export { PasswordForm };