import { PASSIFY_API_HOST } from "../../environment";
import { AbstractApi } from "./api";

class PassifyApi extends AbstractApi {
    private static _instance: PassifyApi;
    protected _host = `https://${PASSIFY_API_HOST}`;

    private constructor() {
        super();
    }

    static get instance() { return this._instance || (this._instance = new this()); }

    // OpenId

    connect = async (recaptchaToken: string, username: string, password: string) => {
        return await this._post("/connect/token", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `client_id=web&client_secret=secret&grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
        });
    }

    refreshToken = async (refreshToken: string) => {
        return await this._post("/connect/token", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `client_id=web&client_secret=secret&grant_type=refresh_token&refresh_token=${encodeURIComponent(refreshToken)}`
        });
    }

    // Auth

    createNewBuyer = async (recaptchaToken: string) => {
        return await this._post("/auth/new-buyer", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
            }
        });
    }

    register = async (recaptchaToken: string, email: string, password?: string) => {
        return await this._post("/auth/register", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "password": password,
            })
        });
    }

    resendConfirmation = async (email: string) => {
        return await this._post("/auth/resend-confirmation", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
            })
        });
    }

    confirmEmail = async (email: string, code: string, password?: string) => {
        return await this._post("/auth/confirm", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "code": code,
                "password": password,
            })
        });
    }

    facebookSignin = async (recaptchaToken: string, accessToken: string) => {
        return await this._post("/auth/facebook", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "accessToken": accessToken,
            })
        });
    }

    googleSignin = async (recaptchaToken: string, code: string) => {
        return await this._post("/auth/google-oidc", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "code": code,
            })
        });
    }

    forgotPassword = async (recaptchaToken: string, email: string) => {
        return await this._post("/auth/forgot-password", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
            })
        });
    }

    resetPassword = async (email: string, code: string, password: string) => {
        return await this._post("/auth/reset-password", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "code": code,
                "password": password,
            })
        });
    }

    changePassword = async (accessToken: string, oldPassword: string, newPassword: string) => {
        return await this._put("/auth/password", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "oldPassword": oldPassword,
                "newPassword": newPassword,
            })
        });
    }

    // Lead

    registerLead = async (source: string, data: {name?: string, email?: string, instagram?: string, phone?: string}) => {
        return await this._post("/lead", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "source": source,
                ...data,
            })
        });
    }

    // Event

    getEvents = async () => {
        return await this._get("/event", {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    getEventDetails = async (eventId: string) => {
        return await this._get(`/event/${eventId}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    getEventCategories = async () => {
        return await this._get("/category", {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    // Queue

    joinQueue = async (accessToken: string, eventId: string) => {
        return await this._post(`/queue/v1/${eventId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
    }

    getQueuePosition = async (accessToken: string, eventId: string, queueAccessCode: string) => {
        return await this._get(`/queue/v1/${eventId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "X-Passify-QueueGateway": queueAccessCode
            }
        });
    }

    abandonQueue = async (accessToken: string, eventId: string, queueAccessCode: string) => {
        return await this._delete(`/queue/v1/${eventId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "X-Passify-QueueGateway": queueAccessCode
            }
        });
    }

    // Checkout

    getAvailableTickets = async (queueAccessCode: string, accessToken: string, eventSessionId: string, code?: string | null) => {
        return await this._get(`/checkout/available/${eventSessionId}${code != undefined ? `?code=${code}` : ""}`, {
            headers: {
                "X-Passify-QueueGateway": queueAccessCode,
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getReservations = async (queueAccessCode: string, accessToken: string) => {
        return await this._get("/checkout/reservation", {
            headers: {
                "X-Passify-QueueGateway": queueAccessCode,
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    updateReservation = async (recaptchaToken: string, queueAccessCode: string, accessToken: string, batchId: string, quantity: number, code?: string) => {
        return await this._put(`/checkout/reservation/batch/${batchId}`, {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "X-Passify-QueueGateway": queueAccessCode,
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "quantity": quantity,
                "code": code,
            })
        });
    }

    getCheckoutOrderPreview = async (queueAccessCode: string, accessToken: string) => {
        return await this._get(`/checkout/order/preview`, {
            headers: {
                "X-Passify-QueueGateway": queueAccessCode,
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getCheckoutOrder = async (queueAccessCode: string, accessToken: string) => {
        return await this._get(`/checkout/order`, {
            headers: {
                "X-Passify-QueueGateway": queueAccessCode,
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getCheckoutOrderById = async (accessToken: string, orderId: string) => {
        return await this._get(`/checkout/order/${orderId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    removeCheckoutOrderById = async (accessToken: string, orderId: string) => {
        return await this._delete(`/checkout/order/${orderId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    createCheckoutOrder = async (queueAccessCode: string, accessToken: string, reservations: Array<string>, email?: string) => {
        return await this._post("/checkout/order", {
            headers: {
                "X-Passify-QueueGateway": queueAccessCode,
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "reservations": reservations,
                "email": email,
            })
        });
    }

    choosePixPayment = async (queueAccessCode: string, accessToken: string, taxId: string, name?: string) => {
        return await this._put(`/checkout/order/payment`, {
            headers: {
                "X-Passify-QueueGateway": queueAccessCode,
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "pix": {
                    "taxId": taxId,
                    "name": name,
                }
            })
        });
    }

    chooseCardPayment = async (queueAccessCode: string, accessToken: string, cardHolderName: string, taxId: string, cardPaymentToken: string, paymentMethodId: string, installments: number) => {
        return await this._put(`/checkout/order/payment`, {
            headers: {
                "X-Passify-QueueGateway": queueAccessCode,
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "card": {
                    "cardHolderName": cardHolderName,
                    "taxId": taxId,
                    "token": cardPaymentToken,
                    "installments": installments,
                    "paymentMethodId": paymentMethodId,
                }
            })
        });
    }

    // Customer

    getCustomerData = async (accessToken: string) => {
        return await this._get("/customer/data", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    saveCustomerData = async (accessToken: string, name: string, cpf?: string, dateOfBirth?: string, gender?: string) => {
        return await this._put("/customer/data", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "name": name,
                "taxId": cpf,
                "dateOfBirth": dateOfBirth,
                "gender": gender,
            })
        });
    }

    getCustomerWallet = async (accessToken: string) => {
        return await this._get("/customer/wallet", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    saveCustomerWallet = async (accessToken: string, address: string) => {
        return await this._put("/customer/wallet", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "address": address,
            })
        });
    }

    getCustomerEvents = async (accessToken: string, isActive?: boolean) => {
        return await this._get(`/customer/event${isActive !== undefined ? `?isActive=${isActive}` : ""}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getPasses = async (accessToken: string, isActive?: boolean) => {
        return await this._get(`/customer/pass${isActive !== undefined ? `?isActive=${isActive}` : ""}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getPassesByEvent = async (accessToken: string, eventId: string, isActive?: boolean) => {
        return await this._get(`/customer/pass/event/${eventId}${isActive !== undefined ? `?isActive=${isActive}` : ""}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getPassesBySession = async (accessToken: string, sessionId: string, isActive?: boolean) => {
        return await this._get(`/customer/pass/session/${sessionId}${isActive !== undefined ? `?isActive=${isActive}` : ""}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getPassesByBatch = async (accessToken: string, batchId: string, isActive?: boolean) => {
        return await this._get(`/customer/pass/batch/${batchId}${isActive !== undefined ? `?isActive=${isActive}` : ""}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    requestEmailChange = async (accessToken: string, email: string) => {
        return await this._post("/customer/email/request", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
            })
        });
    }

    requestPhoneChange = async (accessToken: string, phone: string) => {
        return await this._post("/customer/phone/request", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "phone": phone,
            })
        });
    }

    changeEmail = async (accessToken: string, email: string, code: string) => {
        return await this._post("/customer/email", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "code": code,
            })
        });
    }

    changePhone = async (accessToken: string, phone: string, code: string) => {
        return await this._post("/customer/phone", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "phone": phone,
                "code": code,
            })
        });
    }

    getPassSessionMessage = async (accessToken: string) => {
        return await this._get("/customer/message", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
    }

    // Notifications

    updateEmailNotificationSettings = async (accessToken: string, notifications: Array<string>) => {
        return await this._put("/customer/notification/settings/email", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(notifications)
        });
    }

    updateWhatsAppNotificationSettings = async (accessToken: string, notifications: Array<string>) => {
        return await this._put("/customer/notification/settings/whatsapp", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(notifications)
        });
    }

    markNotificationAsRead = async (accessToken: string, notificationIds: Array<string>) => {
        return await this._post("/customer/notification/read", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(notificationIds)
        });
    }

    getNotificationSettings = async (accessToken: string) => {
        return await this._get("/customer/notification/settings", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
    }

    getNotifications = async (accessToken: string) => {
        return await this._get("/customer/notification", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
    }

    // Transfer

    createTransfer = async (accessToken: string, passes: Array<string>, target: {email?: string, phone?: string}) => {
        return await this._post("/transfer", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "passes": passes,
                "email": target.email,
                "phone": target.phone,
            })
        });
    }

    getTransfer = async (accessToken: string, transferId: string) => {
        return await this._get(`/transfer/${transferId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
    }

    cancelTransfer = async (accessToken: string, transferId: string) => {
        return await this._delete(`/transfer/${transferId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
    }

    receiveTransfer = async (accessToken: string, transferId: string) => {
        return await this._post(`/transfer/${transferId}/receive`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
    }

    rejectTransfer = async (accessToken: string, transferId: string) => {
        return await this._delete(`/transfer/${transferId}/receive`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
    }

    // Producer

    getProducerEvents = async (accessToken: string) => {
        return await this._get(`/producer/event`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getProducerEvent = async (accessToken: string, eventId: string) => {
        return await this._get(`/producer/event/${eventId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getSalesBySession = async (accessToken: string, sessionId: string) => {
        return await this._get(`/producer/event/session/${sessionId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getSalesBySegment = async (accessToken: string, sessionId: string, segmentId: string) => {
        return await this._get(`/producer/event/session/${sessionId}/segment/${segmentId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getSalesByBatch = async (accessToken: string, sessionId: string, batchId: string) => {
        return await this._get(`/producer/event/session/${sessionId}/batch/${batchId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    getTransferHistory = async (accessToken: string, orderId: string) => {
        return await this._get(`/producer/order/${orderId}/pass`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }
}

const passifyApi = PassifyApi.instance;

export { passifyApi as PassifyApi };