import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import "./queueclock.scss";

interface QueueClockProps {
    expirationTime?: number,
    onExpire: () => void,
}

const QueueClock = ({ expirationTime, onExpire }: QueueClockProps) => {

    const [remainingTime, setRemainingTime] = React.useState<number>(-1);
    const clockRef = React.useRef<HTMLDivElement>(null);

    const makeFixed = () => {
        const elem = clockRef.current;
        if(!!elem) {
            if(document.body.scrollTop > elem.offsetTop) {
                if(!elem.classList.contains("fixed")) {
                    elem.classList.add("fixed");
                }
            } else if(elem.classList.contains("fixed")) {
                elem.classList.remove("fixed");
            }
        }
    }
    
    React.useEffect(() => {
        window.addEventListener("scroll", makeFixed);
        if(expirationTime !== undefined) {
            const timer = setInterval(() => {
                setRemainingTime(Math.max(expirationTime * 1000 - new Date().getTime(), 0) / 1000);
            }, 950);
    
            return () => {
                clearInterval(timer);
                window.removeEventListener("scroll", makeFixed);
            }
        }
        return () => {
            window.removeEventListener("scroll", makeFixed);
        }
    }, []);

    React.useEffect(() => {
        if(remainingTime === 0) {
            onExpire();
        }
    }, [remainingTime])

    const minutesRemaining = Math.floor(remainingTime / 60);
    const secondsRemaining = Math.floor(remainingTime % 60);

    return <div ref={clockRef} className="queue-clock">
        <FontAwesomeIcon icon={faHourglass} />
        <div className="queue-clock-text">
            {expirationTime === undefined || remainingTime === -1 ? "-:--" : `${minutesRemaining}:${secondsRemaining > 9 ? secondsRemaining : `0${secondsRemaining}`}`}
        </div>
    </div>;
}

export { QueueClock };