import React from "react";
import { failableAsync } from "ts-failable";
import { ApiAuth, ApiAuthProvider, ApiCheckout, ApiCheckoutProvider, ApiCustomer, ApiCustomerProvider, ApiEvent, ApiEventProvider, ApiNotification, ApiNotificationProvider, ApiTransfer, ApiTransferProvider } from "./api/index";

function empty<T, U>() { return failableAsync<T, U>(async ({ failure }) => failure({ errorType: "UNINITIALIZED" } as U)); }

export type ApiState = {
    auth: ApiAuth,
    checkout: ApiCheckout,
    transfer: ApiTransfer,
    customer: ApiCustomer,
    notification: ApiNotification,
    event: ApiEvent,
};

const initialApiState: ApiState = {
    auth: {
        signin: empty,
        signinWithFacebook: empty,
        signinWithGoogle: empty,
        register: empty,
        confirmEmail: empty,
        forgotPassword: empty,
        resetPassword: empty,
        changePassword: empty,
    },
    checkout: {
        getAvailableTickets: empty,
        getReservations: empty,
        updateReservation: empty,
        getCheckoutOrderPreview: empty,
        getCheckoutOrder: empty,
        getCheckoutOrderById: empty,
        removeCheckoutOrderById: empty,
        createOrder: empty,
        choosePixPayment: empty,
        getPaymentToken: empty,
        chooseCardPaymentAndPay: empty,
    },
    transfer: {
        createTransfer: empty,
        getTransfer: empty,
        cancelTransfer: empty,
        receiveTransfer: empty,
        rejectTransfer: empty,
    },
    customer: {
        getCustomerData: empty,
        saveCustomerData: empty,
        saveCustomerWallet: empty,
        requestEmailChange: empty,
        requestPhoneChange: empty,
        changeEmail: empty,
        changePhone: empty,
        getEventSessionTimeline: empty,
        getPasses: empty,
        getPassesByEvent: empty,
        getPassesBySession: empty,
        getPassesByBatch: empty,
        getPassSessionMessage: empty,
    },
    notification: {
        updateEmailNotificationSettings: empty,
        updateWhatsAppNotificationSettings: empty,
        markNotificationAsRead: empty,
        getNotificationSettings: empty,
        getNotifications: empty,  
    },
    event: {
        getEventCategories: empty,
        getEvents: empty,
        getEventDetails: empty,
    }
};

export const ApiContext = React.createContext(initialApiState);

export const ApiProvider = ({ children } : { children: React.ReactNode}) => {
    
    const auth = ApiAuthProvider();
    const checkout = ApiCheckoutProvider();
    const transfer = ApiTransferProvider();
    const customer = ApiCustomerProvider();
    const notification = ApiNotificationProvider();
    const event = ApiEventProvider();

    return (
        <ApiContext.Provider value={{ auth, checkout, transfer, customer, notification, event }}>
            {children}
        </ApiContext.Provider>
    );
};