import React from "react";
import { IFailable, failableAsync } from "ts-failable";
import { AuthContext } from "./auth";
import { ApiQueueError, EventQueue, PassifyApi } from "../shared";

type F<T> = IFailable<T, ApiQueueError>;
type P<T> = Promise<F<T>>;

interface JWTParsedCode {
    aud: string,
    exp: number,
    iss: string,
    jti: string,
    scope: string,
    sub: string,
}

type EventQueues = {
    [eventId: string]: EventQueue
}

export interface QueueState {
    readonly queues: EventQueues,
    getParsedQueueToken: (eventId: string) => JWTParsedCode | null,
    refreshQueueToken: (eventId: string, token: string) => void,
    getQueuePosition: (eventId: string) => P<EventQueue>,
    clearQueue: (eventId: string) => void,
    abandonQueue: (eventId: string) => P<boolean>
};

function empty<T, U>() { return failableAsync<T, U>(async ({ failure }) => failure({ errorType: "UNINITIALIZED" } as U)); }

const initialQueueState: QueueState = {
    queues: {},
    getParsedQueueToken: () => null,
    refreshQueueToken: () => {},
    getQueuePosition: empty,
    clearQueue: () => {},
    abandonQueue: empty
};

export const QueueContext = React.createContext(initialQueueState);

export const QueueProvider = ({ children } : { children: React.ReactNode}) => {
    const [queues, setQueues] = React.useState<EventQueues>({});

    const { auth, refreshAuth, getAuth } = React.useContext(AuthContext);

    const localStorage = window.localStorage;

    let currentQueues = queues;

    const updateQueue = (eventId: string, eventQueue: EventQueue | null) => {
        if(!!eventQueue) {
            currentQueues[eventId] = eventQueue;

            if(!!eventQueue.refreshAt) {
                const refreshTime = Date.parse(eventQueue.refreshAt);
                const now = new Date().getTime();
                if(now < refreshTime) {
                    setTimeout(() => {
                        refreshQueuePosition(eventId);
                    }, refreshTime - now);
                }
            }
        } else {
            delete currentQueues[eventId];
        }
        setQueues({...currentQueues});
    }

    const loadQueues = () => {
        const localQueues = localStorage.getItem("queues");

        try {
            currentQueues = !!localQueues ? JSON.parse(localQueues) as EventQueues : {};
            setQueues(currentQueues);
        } catch(_) {
            setQueues({});
        }
    }

    const storeQueues = () => {
        localStorage.setItem("queues", JSON.stringify(queues));
    }

    const clearQueue = (eventId: string) => {
        updateQueue(eventId, null);
    }

    const joinQueue = async (eventId: string): Promise<F<EventQueue>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.joinQueue(auth.accessToken, eventId);
            if(response.status === 200) {
                const responseJSON = await response.json();
                const eventQueue = responseJSON as EventQueue;
                updateQueue(eventId, eventQueue);
                return success(eventQueue);
            } else if(response.status === 401) {
                run(await refreshAuth());
                return joinQueue(eventId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const refreshQueuePosition = async (eventId: string): Promise<F<EventQueue>> => failableAsync(
        async ({ success, failure, run }) => {
            if(eventId in queues && !!(queues[eventId].accessCode)) {
                const auth = run(getAuth());
                const response = await PassifyApi.getQueuePosition(auth.accessToken, eventId, queues[eventId].accessCode!);
                if(response.status === 200) {
                    const responseJSON = await response.json();
                    const eventQueue = responseJSON as EventQueue;
                    updateQueue(eventId, eventQueue);
                    return success(eventQueue);
                } else if(response.status === 401) {
                    run(await refreshAuth());
                    return refreshQueuePosition(eventId);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
            }
            return failure({ errorType: "NO_QUEUE_FOR_EVENT" });
        }
    );

    const getQueuePosition = async (eventId: string): Promise<F<EventQueue>> => failableAsync(
        async ({ success, run }) => {
            let eventQueue;
            if(eventId in currentQueues) {
                const parsedCode = getParsedQueueToken(eventId);
                if(!!parsedCode && parsedCode.exp * 1000 < new Date().getTime()) {
                    clearQueue(eventId);
                    return getQueuePosition(eventId);
                }

                if(currentQueues[eventId].position === 0) {
                    return success(currentQueues[eventId]);
                } else {
                    eventQueue = run(await refreshQueuePosition(eventId));
                    return success(eventQueue);
                }
            } else {
                eventQueue = run(await joinQueue(eventId));
                return success(eventQueue);
            }
        }
    );

    const abandonQueue = async (eventId: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            if(eventId in queues && !!(queues[eventId].accessCode)) {
                const auth = run(getAuth());
                const response = await PassifyApi.abandonQueue(auth.accessToken, eventId, queues[eventId].accessCode!);
                if(response.status === 200 || response.status === 404) {
                    clearQueue(eventId);
                    return success(response.status === 200);
                } else if(response.status === 401) {
                    run(await refreshAuth());
                    return abandonQueue(eventId);
                }
                return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
            }
            return failure({ errorType: "NO_QUEUE_FOR_EVENT" });
        }
    );

    React.useEffect(loadQueues, []);
    React.useEffect(storeQueues, [queues]);
    React.useEffect(() => {
        if(!auth) {
            setQueues({});
        }
    }, [auth]);

    const parseToken = (token: string) => JSON.parse(atob(token.split('.')[1])) as JWTParsedCode;

    const refreshQueueToken = (eventId: string, token: string) => {
        const oldParsedToken = getParsedQueueToken(eventId);
        const newParsedToken = parseToken(token);

        if(
            oldParsedToken?.aud === newParsedToken.aud &&
            oldParsedToken?.iss === newParsedToken.iss &&
            oldParsedToken?.jti === newParsedToken.jti &&
            oldParsedToken?.scope === newParsedToken.scope &&
            oldParsedToken?.sub === newParsedToken.sub
        ) {
            updateQueue(eventId, {...queues[eventId], accessCode: token});
        }
    }

    const getParsedQueueToken = (eventId: string) => {
        if(eventId in queues) {
            const queue = queues[eventId];
            if(!!queue.accessCode) {
                return parseToken(queue.accessCode);
            }
        }
        return null;
    }

    return (
        <QueueContext.Provider value={{ queues, getParsedQueueToken, refreshQueueToken, getQueuePosition, clearQueue, abandonQueue }}>
            {children}
        </QueueContext.Provider>
    );
};