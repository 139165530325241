import React from "react";
import { DateTime } from "luxon";
import { AccessTime, LocationOn } from "@mui/icons-material";
import { SyncMouseEventHandler } from "../../shared";
import "./walletpasscard.scss";

interface WalletPassCardStyleProps extends React.CSSProperties {
    "--card-size": string | number,
    "--font-size": string | number
}

interface WalletPassLandscapeCardStyleProps extends WalletPassCardStyleProps {
    "--image-size": string | number
}
type WalletPassPortraitCardStyleProps = WalletPassCardStyleProps;

interface WalletPassCardProps {
    style: WalletPassCardStyleProps,
    imageUrl?: string,
    title: JSX.Element | string,
    date: string,
    location: string,
    disabled?: boolean,
    onClick?: SyncMouseEventHandler<HTMLDivElement>,
}

interface WalletPassLandscapeCardProps extends WalletPassCardProps {
    style: WalletPassLandscapeCardStyleProps
}

interface WalletPassPortraitCardProps extends WalletPassCardProps {
    style: WalletPassPortraitCardStyleProps,
    quantity: number
}

const iconStyle = {width: "var(--font-size)", height: "var(--font-size)"};

const WalletPassLandscapeCard = (props: WalletPassLandscapeCardProps) => {
    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if(props.onClick !== undefined) {
            props.onClick(e);
        }
        e.preventDefault();
    }

    return <div className={`wallet-pass-landscape ${props.disabled ? "used" : ""}`} style={props.style} onClick={onClick}>
        <div className="wallet-pass-event-image">
            <img src={props.imageUrl} />
        </div>
        <div className="wallet-pass-details">
            <div className="wallet-pass-title">{props.title}</div>
            <div className="wallet-pass-date"><span className="icon"><AccessTime sx={iconStyle} /></span>{DateTime.fromMillis(Date.parse(props.date)).toFormat("ccc, d LLL yy", { locale: "pt-br" })}</div>
            <div className="wallet-pass-location"><span className="icon"><LocationOn sx={iconStyle} /></span>{props.location}</div>
        </div>
    </div>;
}

const WalletPassPortraitCard = (props: WalletPassPortraitCardProps) => {
    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if(props.onClick !== undefined) {
            props.onClick(e);
        }
        e.preventDefault();
    }

    return <div className={`wallet-pass-portrait ${props.disabled ? "used" : ""}`} style={props.style} onClick={onClick}>
        <div className="wallet-pass-event-image">
            <img src={props.imageUrl} />
        </div>
        <div className="wallet-pass-title">{props.title}</div>
        <div className="wallet-pass-date"><span className="icon"><AccessTime sx={iconStyle} /></span>{DateTime.fromMillis(Date.parse(props.date)).toFormat("ccc, d LLL yy", { locale: "pt-br" })}</div>
        <div className="wallet-pass-location"><span className="icon"><LocationOn sx={iconStyle} /></span>{props.location}</div>
        <div className="wallet-pass-quantity">{props.quantity}x</div>
    </div>;
}

export { WalletPassLandscapeCard, WalletPassPortraitCard };