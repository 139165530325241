import React from "react";
import { IFailable, failableAsync } from "ts-failable";
import { ApiCustomerError, Customer, Pass, PassList, PassifyApi } from "../../shared";
import { AuthContext } from "../auth";

type F<T> = IFailable<T, ApiCustomerError>;
type P<T> = Promise<F<T>>;

export type ApiCustomer = {
    getCustomerData: () => P<Customer>,
    saveCustomerData: (name: string, taxId?: string, dateOfBirth?: string, gender?: string) => P<boolean>,
    saveCustomerWallet: (address: string) => P<boolean>,
    requestEmailChange: (email: string) => P<boolean>,
    requestPhoneChange: (phone: string) => P<boolean>,
    changeEmail: (email: string, code: string) => P<boolean>,
    changePhone: (phone: string, code: string) => P<boolean>,
    getEventSessionTimeline: (isActive?: boolean) => P<PassList>,
    getPasses: (isActive?: boolean) => P<PassList>,
    getPassesByEvent: (eventId: string, isActive?: boolean) => P<PassList>,
    getPassesBySession: (sessionId: string, isActive?: boolean) => P<PassList>,
    getPassesByBatch: (batchId: string, isActive?: boolean) => P<PassList>,
    getPassSessionMessage: () => P<string>,
}

const ApiCustomerProvider = (): ApiCustomer => {
    const { getAuth, refreshAuth } = React.useContext(AuthContext);

    const getCustomerData = async (): Promise<F<Customer>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getCustomerData(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as Customer);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getCustomerData();
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const saveCustomerData = async (name: string, taxId?: string, dateOfBirth?: string, gender?: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.saveCustomerData(auth.accessToken, name, taxId, dateOfBirth, gender);
            if(response.status == 200) {
                const responseJSON = await response.json();
                return success(responseJSON["succeeded"] as boolean);
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return saveCustomerData(name, taxId, dateOfBirth, gender);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const saveCustomerWallet = async (address: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.saveCustomerWallet(auth.accessToken, address);
            if(response.status == 200) {
                const responseJSON = await response.json();
                return success(responseJSON["succeeded"] as boolean);
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return saveCustomerWallet(address);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const requestEmailChange = async (email: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.requestEmailChange(auth.accessToken, email);
            if(response.status == 200) {
                const responseJSON = await response.json();
                return success(responseJSON["succeeded"]);
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return requestEmailChange(email);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const requestPhoneChange = async (phone: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.requestPhoneChange(auth.accessToken, phone);
            if(response.status == 200) {
                const responseJSON = await response.json();
                return success(responseJSON["succeeded"]);
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return requestPhoneChange(phone);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const changeEmail = async (email: string, code: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.changeEmail(auth.accessToken, email, code);
            if(response.status == 200) {
                const responseJSON = await response.json();
                return success(responseJSON["succeeded"]);
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return changeEmail(email, code);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const changePhone = async (phone: string, code: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.changePhone(auth.accessToken, phone, code);
            if(response.status == 200) {
                const responseJSON = await response.json();
                return success(responseJSON["succeeded"]);
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return changePhone(phone, code);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getEventSessionTimeline = async (isActive?: boolean): Promise<F<PassList>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getCustomerEvents(auth.accessToken, isActive);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as PassList);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getEventSessionTimeline(isActive);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const getPasses = async (isActive?: boolean): Promise<F<PassList>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getPasses(auth.accessToken, isActive);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as PassList);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getPasses(isActive);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getPassesByEvent = async (eventId: string, isActive?: boolean): Promise<F<PassList>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getPassesByEvent(auth.accessToken, eventId, isActive);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as PassList);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getPassesByEvent(eventId, isActive);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getPassesBySession = async (sessionId: string, isActive?: boolean): Promise<F<PassList>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getPassesBySession(auth.accessToken, sessionId, isActive);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as PassList);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getPassesBySession(sessionId, isActive);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getPassesByBatch = async (batchId: string, isActive?: boolean): Promise<F<PassList>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getPassesByBatch(auth.accessToken, batchId, isActive);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as PassList);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getPassesByBatch(batchId, isActive);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const getPassSessionMessage = async (): Promise<F<string>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getPassSessionMessage(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"]);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getPassSessionMessage();
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    return {
        getCustomerData,
        saveCustomerData,
        saveCustomerWallet,
        requestEmailChange,
        requestPhoneChange,
        changeEmail,
        changePhone,
        getEventSessionTimeline,
        getPasses,
        getPassesByEvent,
        getPassesBySession,
        getPassesByBatch,
        getPassSessionMessage
    };
};

export { ApiCustomerProvider };