import React from "react";
import { useSearchParams } from "react-router-dom";
import { Pin } from "@mui/icons-material";
import { PhoneFormatter, REGISTER_PHONE_ID, SIGNIN_ID, View, CHANGE_PHONE_ID, CONTACT_ID } from "../../shared";
import { MainButton, ScreenLoader, TextInput } from "../../components";
import { ApiContext, CustomerContext, NavigationContext, NotificationsContext } from "../../contexts";
import "../auth/verificationview.scss";

class ChangePhoneView extends View {
    id = CHANGE_PHONE_ID;
    route = "/settings/contact/change-phone";
    defaultRoute = false;
    authNeeded = false;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[CONTACT_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [email, setEmail] = React.useState<string>();
        const [phone, setPhone] = React.useState<string>();
        const [code, setCode] = React.useState<string>("");

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { customer, setCustomer, setSkipWhatsApp } = React.useContext(CustomerContext);
        const { navigate, views, goTo, location, routeBuilder } = this.navigation = React.useContext(NavigationContext);

        const [searchParams] = useSearchParams();

        const phoneFormatter = new PhoneFormatter();

        React.useEffect(() => {
            const rawRegisterData = sessionStorage.getItem("register");
            if(rawRegisterData == null) {
                navigate(-1);
            } else {
                const registerData = JSON.parse(rawRegisterData);
                setPhone(registerData["phone"]);
                setEmail(registerData["email"]);
            }
            setFirstLoad(false);
        }, []);

        const changeCodeHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const codeCandidate = e.currentTarget.value;
            if(codeCandidate.length <= 6 && (codeCandidate === "" || !isNaN(parseInt(codeCandidate)))) {
                setCode(codeCandidate);
            }
            e.preventDefault();
        }

        const verifyPhone = async () => {
            const failablePhoneChange = await api.customer.changePhone(phone!, code);
            failablePhoneChange.match({
                success: phoneChanged => {
                    if(phoneChanged) {
                        sessionStorage.removeItem("register");
                        pushNotification("Número de WhatsApp confirmado.");

                        setCustomer(!!customer ? {
                            email: customer.email,
                            name: customer.name,
                            taxId: customer.taxId,
                            dateOfBirth: customer.dateOfBirth,
                            phone: phone,
                        } : null);

                        const ref = searchParams.get("ref");
                        if(!!ref && routeBuilder?.find(ref) !== "/") {
                            navigate(ref);
                        } else {
                            goTo(views[CONTACT_ID]);
                        }
                    } else {
                        pushNotification("Código de verificação inválido.");
                    }
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID], undefined, { ref: !!searchParams.get("ref") ? searchParams.get("ref")! : location.pathname });
                            break;
                        case "FETCH_GENERIC_ERROR":
                            pushNotification("Código de verificação inválido.");
                            break;
                    }
                }
            });
        }

        const verify = async (e: React.MouseEvent<HTMLButtonElement>) => {
            await verifyPhone();
            e.preventDefault();
        }

        const giveUp = (e: React.MouseEvent<HTMLAnchorElement>) => {
            setSkipWhatsApp(true);

            const ref = searchParams.get("ref");
            if(!!ref && routeBuilder?.find(ref) !== "/") {
                navigate(ref);
            } else {
                goTo(views[CONTACT_ID]);
            }
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="verification">
            <div id="verification-explanation">
                Enviamos um código para seu WhatsApp<br /><br /><b>{phoneFormatter.mask(phone || "")}</b><br /><small>(<a onClick={() => goTo(views[REGISTER_PHONE_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined)}>Esse não é o meu número</a>)</small><br /><br />Insira-o na caixa abaixo para validá-lo:
            </div>
            <div className="verification-input-container">
                <div className="label">Código de verificação</div>
                <TextInput id="verification-code" name="verification-code" inputMode="numeric" value={code} prefix={<Pin sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000000" onChange={changeCodeHandle} />
            </div>
            <div className="verification-button-container">
                <MainButton enabled={code.length == 6} content="Verificar" onClick={verify} />
            </div>
            <div className="verification-bottom"><small><a onClick={giveUp}>Não tenho WhatsApp</a></small></div>
        </div>
    }
}

export { ChangePhoneView };