import React from "react";
import { Helmet } from "react-helmet";

type Dispatch<A> = (value: A) => void;
interface OGMetaTags {
    title: string,
    type: string,
    description: string,
    image: string,
    url: string,
    siteName: string,
}

export type MetaState = {
    tags: OGMetaTags,
    setTags: Dispatch<OGMetaTags>,
}

const initialMetatagsState: MetaState = {
    tags: {
        title: "passify.",
        type: "website",
        description: "Seu passe para sua vida",
        image: "https://www.passify.co/passify.png",
        url: "https://www.passify.co",
        siteName: "Passify",
    },
    setTags: () => {},
};

export const MetaContext = React.createContext(initialMetatagsState);

const MetaProvider = ({ children } : { children: React.ReactNode}) => {
    const [tags, setTags] = React.useState(initialMetatagsState.tags);

    return (
        <MetaContext.Provider value={{ tags, setTags }}>
            <Helmet>
                <meta name="title" content={tags.title} />
                <meta name="description" content={tags.description} />

                <meta property="og:title" content={tags.title} />
                <meta property="og:type" content={tags.type} />
                <meta property="og:description" content={tags.description} />
                <meta property="og:image" content={tags.image} />
                <meta property="og:url" content={tags.url} />
                <meta property="og:site_name" content={tags.siteName} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="m.passify.co" />
                <meta property="twitter:url" content={tags.url} />
                <meta name="twitter:title" content={tags.title} />
                <meta name="twitter:description" content={tags.description} />
                <meta name="twitter:image" content={tags.image} />
            </Helmet>
            {children}
        </MetaContext.Provider>
    );
};

export { MetaProvider };