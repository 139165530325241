import React from "react";
import { AsyncMouseEventHandler } from "../../shared";
import "./listtile.scss";

interface ListTileStyleProps extends React.CSSProperties {
    "--tile-size": string,
}

interface ListTileProps {
    leading?: JSX.Element,
    title: JSX.Element | string,
    subtitle?: JSX.Element | string,
    trailing?: JSX.Element,
    separator?: boolean,
    style?: ListTileStyleProps,
    onClick?: AsyncMouseEventHandler<HTMLDivElement>,
}

const ListTile = (props: ListTileProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if(props.onClick !== undefined) {
            if(!loading) {
                setLoading(true);
                props.onClick(e).then(() => {
                    setLoading(false);
                });
            }
        }
        e.preventDefault();
    }

    return <div className={`list-tile ${props.separator === false ? "no-separator" : ""}`} style={props.style || {"--tile-size": "64px"}} onClick={onClick}>
        {props.leading != undefined && <div className="list-tile-leading">{props.leading}</div>}
        <div className="list-tile-main">
            <div className="list-tile-title">{props.title}</div>
            {props.subtitle != undefined && <div className="list-tile-subtitle">{props.subtitle}</div>}
        </div>
        {props.trailing != undefined && <div className="list-tile-trailing">{props.trailing}</div>}
    </div>;
}

export { ListTile };