import React from "react";
import md5 from "md5";
import { ArrowForwardIos, CreditCardOutlined, LightMode, LockOutlined, LoginOutlined, LogoutOutlined, MailOutline, NotificationsOutlined, PaletteOutlined, PersonOutline, TranslateOutlined, WalletOutlined } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SETTINGS_ID, PROFILE_ID, View, CONTACT_ID, SECURITY_ID, SIGNIN_ID, NOTIFICATIONS_SETTINGS_ID } from "../../shared";
import { ListTile } from "../../components";
import { AuthContext, CustomerContext, NavigationContext, ThemeContext } from "../../contexts";
import { faMoon, faUser } from "@fortawesome/free-regular-svg-icons";
import "./settingsview.scss";

class SettingsView extends View {
    id = SETTINGS_ID;
    route = "/settings";
    defaultRoute = true;
    authNeeded = false;
    header = undefined;
    render = () => {
        const { auth, setAuth } = React.useContext(AuthContext);
        const { customer } = React.useContext(CustomerContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);
        const { theme, setTheme } = React.useContext(ThemeContext);

        const changeTheme = async (e: React.MouseEvent<HTMLDivElement>) => {
            setTheme(oldTheme => oldTheme === "dark" ? "light" : "dark");
        }

        const signout = async (e: React.MouseEvent<HTMLDivElement>) => {
            setAuth(null);
            goTo(views[SIGNIN_ID]);
            e.preventDefault();
        }

        const tileStyle = {"--tile-size": "52px"};
        const leadingStyle = {width: 28};
        const trailingStyle = {width: 20};

        return <div id="settings">
            {!!auth ? <>
                {!!customer && <>
                    <div id="settings-picture-container">
                        <img src={`https://gravatar.com/avatar/${md5(customer.email)}?d=retro`} />
                    </div>
                    <div id="settings-user-name">{customer.name}</div>
                </>}
                <ListTile separator={false} leading={<FontAwesomeIcon icon={faUser} style={{...leadingStyle, fontSize: "22px"}} />} title="Meu perfil" trailing={<ArrowForwardIos sx={trailingStyle} />} style={tileStyle} onClick={async () => goTo(views[PROFILE_ID])} />
                <ListTile separator={false} leading={<MailOutline sx={leadingStyle} />} title="Contato" trailing={<ArrowForwardIos sx={trailingStyle} />} style={tileStyle} onClick={async () => goTo(views[CONTACT_ID])} />
                <ListTile separator={false} leading={<LockOutlined sx={leadingStyle} />} title="Segurança" trailing={<ArrowForwardIos sx={trailingStyle} />} style={tileStyle} onClick={async () => goTo(views[SECURITY_ID])} />
                <ListTile separator={false} leading={<NotificationsOutlined sx={leadingStyle} />} title="Notificações" trailing={<ArrowForwardIos sx={trailingStyle} />} style={tileStyle} onClick={async () => goTo(views[NOTIFICATIONS_SETTINGS_ID])} />
                {/*<ListTile separator={false} leading={<CreditCardOutlined sx={leadingStyle} />} title="Pagamento" trailing={<ArrowForwardIos sx={trailingStyle} />} style={tileStyle} onClick={async () => goTo(views[PROFILE_ID])} />
                <ListTile separator={false} leading={<WalletOutlined sx={leadingStyle} />} title="Carteira Cripto" trailing={<ArrowForwardIos sx={trailingStyle} />} style={tileStyle} onClick={async () => goTo(views[PROFILE_ID])} />*/}
            </> : <div id="settings-logo">
                <img src={`/images/passify-${theme}.svg`} />
            </div>}
            {false && <ListTile separator={false} leading={<TranslateOutlined sx={leadingStyle} />} title="Idioma" trailing={<>Português (BR) {false && <ArrowForwardIos sx={trailingStyle} />}</>} style={tileStyle} onClick={async () => {}} />}
            <ListTile separator={false} leading={<PaletteOutlined sx={leadingStyle} />} title="Tema" trailing={theme === "dark" ? <><FontAwesomeIcon icon={faMoon} style={{fontSize: "16px"}} /> Escuro</> : <><LightMode sx={{width: 18}} /> Claro</>} style={tileStyle} onClick={changeTheme} />
            {!!auth ?
                <div id="settings-signout"><ListTile separator={false} leading={<LogoutOutlined sx={leadingStyle} />} title="Sair da conta" style={tileStyle} onClick={signout} /></div> :
                <div id="settings-signin"><ListTile separator={false} leading={<LoginOutlined sx={leadingStyle} />} title="Entrar na conta" style={tileStyle} onClick={async () => goTo(views[SIGNIN_ID])} /></div>
            }
            <div className="terms"><a href="https://passify.co/politica-de-privacidade.pdf" target="_blank">Política de privacidade</a> | <a href="https://passify.co/termos-de-servico.pdf" target="_blank">Termos de serviço</a></div>
        </div>;
    }
}

export { SettingsView };