import React from "react";
import { DateTime } from "luxon";
import { Add, ArrowForwardIos, CalendarMonthOutlined, Clear, GridView, QrCode, SwapHoriz, VisibilityOutlined } from "@mui/icons-material";
import { EventDetails, EventSession, PASS_ID, PROFILE_ID, PassList, SyncMouseEventHandler, View, HOME_ID, EVENTS_ID, TRANSFER_ID, WALLET_EVENT_ID, Pass, SIGNIN_ID, TRANSFER_SEND_ID } from "../../shared";
import { ButtonSliderSwitch, ScreenLoader, SecondarySmallButton, Timeline, WalletPassLandscapeCard, WalletPassPortraitCard } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { ApiContext, AuthContext, NavigationContext, NotificationsContext, ThemeContext } from "../../contexts";
import "./homeview.scss";

enum PassState {
    all,
    active,
    inactive
}

enum WalletViewMode {
    timeline,
    grid
}

class HomeView extends View {
    id = HOME_ID;
    route = "/wallet";
    defaultRoute = true;
    authNeeded = false;
    header = {
        profileClick: () => { this.navigation!.goTo(this.navigation!.views[PROFILE_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [passes, setPasses] = React.useState<PassList>([]);
        const [passState, setPassState] = React.useState<PassState>(PassState.all);
        const [walletViewMode, setWalletViewMode] = React.useState<WalletViewMode>(WalletViewMode.timeline);
        const [selectedEventId, setSelectedEventId] = React.useState<string>();
        const [selectedEventSessionId, setSelectedEventSessionId] = React.useState<string>();
        const [event, setEvent] = React.useState<EventDetails>();
        const [eventSession, setEventSession] = React.useState<EventSession>();

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { auth } = React.useContext(AuthContext);
        const { views, goTo, location } = this.navigation = React.useContext(NavigationContext);
        const { theme } = React.useContext(ThemeContext);

        let loadingPasses = false;

        const handleError = <T,>(response: T) => {
            return () => {

                return response;
            }
        }

        const loadPasses = async () => {
            loadingPasses = true;

            const isActive =
                passState === PassState.active ? true :
                passState === PassState.inactive ? false :
                undefined;

            const failablePasses = await (walletViewMode === WalletViewMode.timeline ?
                api.customer.getEventSessionTimeline(isActive) :
                selectedEventSessionId !== undefined ?
                    api.customer.getPassesBySession(selectedEventSessionId, isActive) :
                    selectedEventId !== undefined ?
                        api.customer.getPassesByEvent(selectedEventId, isActive) :
                        api.customer.getPasses(isActive));

            return failablePasses.match({
                    success: passes => {
                        loadingPasses = false;
                        return passes;
                    },
                    failure: handleError([])
                });
        }

        const loadEvent = async (eventId: string) => {
            const failableEvent = await api.event.getEventDetails(eventId);
            return failableEvent.match({
                success: event => event,
                failure: handleError(undefined)
            });
        }

        const init = () => {
            if(!loadingPasses) {
                loadPasses().then(passes => {
                    setPasses(passes);
                    setFirstLoad(false);
                });
            }
        }

        const onChangeFilter = () => {
            if(!firstLoad) {
                init();
            }
        }

        const onEventSelected = () => {
            onChangeFilter();
            if(selectedEventId !== undefined) {
                loadEvent(selectedEventId).then(setEvent);
            } else {
                setEvent(undefined);
            }
        }

        const setOnSingleSession = () => {
            if(event !== undefined && event.sessions !== undefined && event.sessions.length === 1) {
                setSelectedEventSessionId(event.sessions[0].id);
            }
        }

        const onEventSessionSelected = () => {
            onChangeFilter();
            if(selectedEventSessionId !== undefined && event !== undefined && event.sessions !== undefined) {
                setEventSession(event.sessions.find(session => session.id === selectedEventSessionId));
            } else {
                setEventSession(undefined);
            }
        }

        const unsetOnSingleSession = () => {
            if(eventSession === undefined && event !== undefined && event.sessions !== undefined && event.sessions.length === 1) {
                setSelectedEventId(undefined);
            }
        }

        React.useEffect(init, []);
        React.useEffect(onChangeFilter, [passState, walletViewMode]);
        React.useEffect(onEventSelected, [selectedEventId]);
        React.useEffect(setOnSingleSession, [event]);
        React.useEffect(onEventSessionSelected, [selectedEventSessionId]);
        React.useEffect(unsetOnSingleSession, [eventSession]);

        const selectEvent = (eventId: string) => {
            setSelectedEventId(eventId);
        }

        const unselectEvent = () => {
            unselectEventSession();
            setSelectedEventId(undefined);
        }

        const selectEventSession = (eventSessionId: string) => {
            setSelectedEventSessionId(eventSessionId);
        }

        const unselectEventSession = () => {
            setSelectedEventSessionId(undefined);
        }

        const sortPassesByIsActive = (passes: PassList) => {
            return passes.sort((a, b) => {
                if(a.isActive === b.isActive) {
                    if(!a.transferOrderId && !!b.transferOrderId) {
                        return -1;
                    }
                    return 0;
                } else if(a.isActive) {
                    return -1;
                } else {
                    return 1;
                }
            });
        }

        const sortPassesByStartDate = (passes: PassList) => {
            return passes.sort((a, b) => {
                if(a.startDate === b.startDate) {
                    return 0;
                } else if(Date.parse(a.startDate) < Date.parse(b.startDate)) {
                    return 1;
                } else {
                    return -1;
                }
            });
        }

        const landscapeCardStyle = {
            "--card-size": "calc(100vw - 78px)",
            "--font-size": "16px",
            "--image-size": "80px"
        };

        const portraitCardStyle = {
            "--card-size": "calc(50vw - 28px)",
            "--font-size": "min(20px, calc(3.68vw))"
        };

        const cancelTransfer = (transferOrderId: string) => {
            return async (e: React.MouseEvent<HTMLButtonElement>) => {
                const failableCancel = await api.transfer.cancelTransfer(transferOrderId);
                failableCancel.match({
                    success: success => {
                        if(success) {
                            onChangeFilter();
                            pushNotification("Transferência cancelada!");
                        }
                    },
                    failure: () => {
                        pushNotification("Erro ao cancelar transferência.");
                    }
                });
                e.preventDefault();
            }
        }

        const buildPassCard = (imageUrl: string | undefined, title: string, date: string, location: string, quantity: number, disabled?: boolean, onClick?: SyncMouseEventHandler<HTMLDivElement>) => {
            if(walletViewMode === WalletViewMode.grid) {
                return <WalletPassPortraitCard
                    imageUrl={imageUrl}
                    title={title}
                    date={date}
                    location={location}
                    quantity={quantity}
                    disabled={disabled}
                    style={portraitCardStyle}
                    onClick={onClick}
                />
            } else {
                return <WalletPassLandscapeCard
                    imageUrl={imageUrl}
                    title={title}
                    date={date}
                    location={location}
                    disabled={disabled}
                    style={landscapeCardStyle}
                    onClick={onClick}
                />
            }
        }

        const buildPassGridElements = (eventId?: string, eventSessionId?: string) => {
            const fetchElementData = <T,>(batchData: T, sessionData: T, eventData: T) => (!!eventSessionId && !!eventSession) ? batchData : (!!eventId && !!event) ? sessionData : eventData; 
            const getKey = (pass: Pass) => `${fetchElementData(`batch-${pass.ticketBatchId}`, `session-${pass.sessionId}`, `event-${pass.eventId}`)}-${pass.isActive ? "active" : "not-active"}-${pass.transferOrderId ?? "no-transfer"}`;
            const getTitle = (pass: Pass) => fetchElementData(pass.batchName!, pass.sessionName!, pass.eventName);
            const getOnClick = (pass: Pass) => fetchElementData(
                !pass.isActive ? undefined : () => goTo(views[WALLET_EVENT_ID], { eventId: pass.eventId }),
                () => { selectEventSession(pass.sessionId!); },
                () => { selectEvent(pass.eventId); }
            );

            const elements = sortPassesByIsActive(passes).map(pass => <div key={getKey(pass)} className={`home-pass-container ${!!pass.transferOrderId ? "home-pass-container-transfer" : ""}`}>
                {buildPassCard(
                    pass.smallImageUrl,
                    getTitle(pass),
                    pass.startDate,
                    pass.venue.name,
                    pass.quantity,
                    !pass.isActive,
                    getOnClick(pass)
                )}
                {pass.isActive && eventSessionId !== undefined && <div className="pass-buttons-container">
                    {!!pass.transferOrderId ?
                        <>
                            <SecondarySmallButton content={<VisibilityOutlined sx={{fontSize: 18}} />} onClick={async () => goTo(views[TRANSFER_SEND_ID], { transferId: pass.transferOrderId! })} />
                            <SecondarySmallButton content={<Clear sx={{fontSize: 18}} />} onClick={cancelTransfer(pass.transferOrderId)} />
                        </> :
                        <>
                            <SecondarySmallButton content={<QrCode sx={{fontSize: 18}} />} onClick={async () => goTo(views[PASS_ID], undefined, { eventId: pass.eventId })} />
                            <SecondarySmallButton content={<SwapHoriz sx={{fontSize: 18}} />} onClick={async () => goTo(views[TRANSFER_ID], { batchId: pass.ticketBatchId! })} />
                        </>
                    }
                </div>}
            </div>);

            if(elements.length % 2 == 1) {
                elements.push(<div className="home-pass-container"></div>);
            }

            return elements;
        }

        const buildPassTimelineElements = () => {
            const sortedPasses = sortPassesByStartDate(passes);
            return sortedPasses.map(pass => ({
                key: DateTime.fromMillis(Date.parse(pass.startDate)).toFormat("yyyy-LL-dd"),
                element: buildPassCard(
                    pass.smallImageUrl,
                    pass.sessionName!,
                    pass.startDate,
                    pass.venue.name,
                    0,
                    !pass.isActive,
                    () => goTo(views[WALLET_EVENT_ID], { eventId: pass.eventId })
                )
            }));
        }

        const passGridElements = buildPassGridElements(selectedEventId, selectedEventSessionId);
        const passTimelineElements = buildPassTimelineElements();
        const noEventsFoundElement = (
            <div className="home-prompt">
                <img src={`/images/${theme}/empty-tickets.svg`} />
                {!auth ? <>
                    <h3>Entre na sua conta.</h3>
                    <p>Entre na sua conta para visualizar os eventos em sua carteira.</p>
                    <a onClick={() => goTo(views[SIGNIN_ID], undefined, { ref: location.pathname })}>Entrar</a>
                </> : <>
                    <h3>Nenhum evento encontrado.</h3>
                    <p>Você não tem nenhum ingresso em sua carteira. Adquira ingressos para os eventos mais irados na galeria da Passify.</p>
                    <a onClick={() => goTo(views[EVENTS_ID])}>Encontrar eventos</a>
                </>}
            </div>
        );
        
        return firstLoad ? <ScreenLoader /> : <div id="home">
            <div className="home-title">
                <h2>Meus eventos</h2>
                <a onClick={() => goTo(views[EVENTS_ID])}>{true ? <Add /> : <>Encontrar outros <ArrowForwardIos sx={{fontSize: 13}} /></>}</a>
            </div>
            <div id="home-filter-fixed">
                <ButtonSliderSwitch index={passState === PassState.active ? 1 : passState === PassState.inactive ? 2 : 0} items={[{
                    content: "Todos",
                    onClick: (e) => {
                        setPassState(PassState.all);
                        e.preventDefault();
                    }
                },{
                    content: "Próximos",
                    onClick: (e) => {
                        setPassState(PassState.active);
                        e.preventDefault();
                    }
                },{
                    content: "Passados",
                    onClick: (e) => {
                        setPassState(PassState.inactive);
                        e.preventDefault();
                    }
                }]} />
                <ButtonSliderSwitch index={walletViewMode === WalletViewMode.grid ? 1 : 0} items={[{
                    content: <CalendarMonthOutlined sx={{height: 16}} />,
                    onClick: (e) => {
                        setWalletViewMode(WalletViewMode.timeline);
                        e.preventDefault();
                    }
                },{
                    content: <GridView sx={{height: 16}} />,
                    onClick: (e) => {
                        setWalletViewMode(WalletViewMode.grid);
                        e.preventDefault();
                    }
                }]} />
            </div>
            <div id="home-filter-variable">
                {walletViewMode === WalletViewMode.grid && event !== undefined && <div className="home-filter-item">
                    <div className="home-filter-image"><img src={event.smallImageUrl} /></div>
                    <div className="home-filter-content">{event.name}{eventSession !== undefined && `: ${eventSession.name}`}</div>
                    <div className="home-filter-remove-button-container"><button className="invisible" onClick={eventSession !== undefined ? unselectEventSession : unselectEvent}><FontAwesomeIcon icon={faX} /></button></div>
                </div>}
            </div>
            {loadingPasses ? <div className="pass-loader-progressbar"><div className="pass-loader-progressbar-circle"></div></div> : <React.Fragment key={JSON.stringify(passes)}>
                {walletViewMode === WalletViewMode.grid ?
                    (passGridElements.length > 0 ?
                        <div id="home-grid">
                            {passGridElements}
                        </div> :
                        noEventsFoundElement
                    ) :
                    (Object.keys(passTimelineElements).length > 0 ?
                        <div className="home-timeline">
                            <Timeline items={passTimelineElements} />
                        </div> :
                        noEventsFoundElement
                    )
                }
            </React.Fragment>}
        </div>;
    };
}

export { HomeView };