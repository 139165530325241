import { IFailable, failableAsync } from "ts-failable";
import { ApiEventError, CategoryList, EventDetails, EventList, PassifyApi } from "../../shared";

type F<T> = IFailable<T, ApiEventError>;
type P<T> = Promise<F<T>>;

export type ApiEvent = {
    getEventCategories: () => P<CategoryList>,
    getEvents: () => P<EventList>,
    getEventDetails: (eventId: string) => P<EventDetails>,
}

const ApiEventProvider = (): ApiEvent => {
    const getEventCategories = async (): Promise<F<CategoryList>> => failableAsync(
        async ({ success, failure }) => {
            const response = await PassifyApi.getEventCategories();
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as CategoryList);
                }
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const getEvents = async (): Promise<F<EventList>> => failableAsync(
        async ({ success, failure }) => {
            const response = await PassifyApi.getEvents();
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as EventList);
                }
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );
    
    const getEventDetails = async (eventId: string): Promise<F<EventDetails>> => failableAsync(
        async ({ success, failure }) => {
            const response = await PassifyApi.getEventDetails(eventId);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as EventDetails);
                }
            } else if(response.status === 404) {
                return failure({ errorType: "EVENT_NOT_FOUND" });
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    return { getEventCategories, getEvents, getEventDetails };
};

export { ApiEventProvider };