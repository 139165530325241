abstract class AbstractApi {
    protected abstract _host: string;

    protected _get = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "GET"
        });
    }

    protected _post = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "POST"
        });
    }

    protected _put = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "PUT"
        });
    }

    protected _delete = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "DELETE"
        });
    }
}

export { AbstractApi };