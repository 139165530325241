import React from "react";
import Web3 from "web3";
import { useSearchParams } from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';
import { EVENTS_ID, EventDetails, PASS_ID, PROFILE_ID, SIGNIN_ID, View } from "../../shared";
import { CheckboxInput, ScreenLoader, SmallButton } from "../../components";
import { ApiContext, NavigationContext, ThemeContext } from "../../contexts";
import "./passview.scss"

const getAverageRGB = (imgEl: HTMLImageElement) => {
    var blockSize = 5,
        defaultRGB = {r: 0, g: 0, b: 0},
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {r:0,g:0,b:0},
        count = 0;
        
    if (!context) {
        return defaultRGB;
    }
    
    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
    
    context.drawImage(imgEl, 0, 0);
    
    try {
        data = context.getImageData(0, 0, width, height);
    } catch(e) {
        console.log(e);
        return defaultRGB;
    }
    
    length = data.data.length;
    
    while ( (i += blockSize * 4) < length ) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i+1];
        rgb.b += data.data[i+2];
    }
    
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);
    
    return rgb;   
}

const loadImg = async (url: string) => {
    let blob = await fetch(url).then(r => r.blob());
    let dataUrl = await new Promise(resolve =>  {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
    return typeof dataUrl === "string" ? dataUrl : url;
}

interface SignedMessage {
    message: string,
    signature: string
}

class PassView extends View {
    id = PASS_ID;
    route = "/pass";
    defaultRoute = true;
    authNeeded = true;
    header = {
        profileClick: () => { this.navigation!.goTo(this.navigation!.views[PROFILE_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [searchParams] = useSearchParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [event, setEvent] = React.useState<EventDetails>();
        const [bgColor, setBgColor] = React.useState<string | null>(null);
        const [bgOpacity, setBgOpacity] = React.useState<number>(1);
        const [alternateColor, setAlternateColor] = React.useState<boolean>(false);
        const [dismissDisclaimer, setDismissDisclaimer] = React.useState<boolean>(true);
        const [dontShowAgain, setDontShowAgain] = React.useState<boolean>(false);
        const [intervalProgress, setIntervalProgress] = React.useState<number>(0);
        const [signedMsg, setSignedMsg] = React.useState<SignedMessage>({message: "", signature: ""});

        const api = React.useContext(ApiContext);
        const { views, goTo, navigate } = this.navigation = React.useContext(NavigationContext);
        const { theme } = React.useContext(ThemeContext);

        const startTime = Date.now();
        const web3 = new Web3("https://polygon-rpc.com");
        const intervalSize = 5500;

        let timer: NodeJS.Timer;
        let nextSignedMsg: SignedMessage;
        let actualSignedMsg: SignedMessage;
        let actualIntervalProgress: number;
        let isLoading = false;

        let permPrivateKey = ""; // REMOVE

        const getIds = () => {
            const eventId = searchParams.get("eventId");
            return { eventId: eventId || "" };
        }

        const getImage = React.useCallback((elem: HTMLImageElement) => {
            if(!!elem && event !== undefined && event.bigImageUrl !== undefined) {
                loadImg(event.bigImageUrl).then(imgSrc => {
                    elem.onload = () => {
                        const rgb = getAverageRGB(elem);
                        const benchmarkColor = (theme === "dark") ? 0 : 255;
                        const diff = Math.abs((rgb.r + rgb.g + rgb.b) / 3 - benchmarkColor);
                        setBgOpacity(0.8 + 0.15 * (diff / 255));
                    }
                    elem.src = imgSrc;
                });
            }
        }, [event]);

        const loadPasses = async () => {
            const failablePasses = await api.customer.getEventSessionTimeline(true);
            return failablePasses.match({
                success: passes => passes,
                failure: () => []
            });
        }

        const loadEvent = async () => {
            const { eventId } = getIds();
            if(!!eventId) {
                const failableEventDetails = await api.event.getEventDetails(eventId);
                return failableEventDetails.match({
                    success: event => event,
                    failure: () => null
                });
            }
            return null;
        }

        const saveCustomerWallet = async (address: string) => { // REMOVE AFTER FINAL SOLUTION
            const failableSave = await api.customer.saveCustomerWallet(address);
            return failableSave.match({
                success: success => !!success,
                failure: () => false
            })
        }

        const init = () => {
            loadPasses().then(passes => {
                if(passes.length > 0) {
                    if(false) {
                        const passBgColor = localStorage.getItem("passBgColor");
                        if(!!passBgColor) {
                            setBgColor(passBgColor);
                        }
        
                        loadEvent().then(event => {
                            if(!!event) {
                                setEvent(event);
                            } else {
                                navigate(-1);
                            }
                        });
                    }
        
                    const dismissPassDisclaimer = localStorage.getItem("dismissPassDisclaimer");
                    if(!dismissPassDisclaimer) {
                        setDismissDisclaimer(false);
                    }
        
                    saveCustomerWallet(account().address).then(success => {
                        if(success) {
                            preLoad().then(() => {
                                setFirstLoad(false);
                            });
                        } else {
                            navigate(-1);
                        }
                    });
        
                    if(!!timer) {
                        clearInterval(timer);
                    }
                    timer = setInterval(generatePass, 10);
                } else {
                    setFirstLoad(false);
                }
            });

            return () => {
                if(!!timer) {
                    clearInterval(timer);
                }
            }
        }

        const storeBgColor = () => {
            if(!!bgColor) {
                localStorage.setItem("passBgColor", bgColor);
            } else {
                localStorage.removeItem("passBgColor");
            }
        }

        const alternate = () => { setAlternateColor(old => !old); }

        React.useEffect(init, []);
        React.useEffect(storeBgColor, [bgColor]);
        React.useEffect(alternate, [signedMsg]);

        const account = () => {
            let account;
            let privateKey = permPrivateKey; //localStorage.getItem("account");
            if(!privateKey) {
                account = web3.eth.accounts.create();
                permPrivateKey = account.privateKey;
                //localStorage.setItem("account", account.privateKey);
            } else {
                account = web3.eth.accounts.privateKeyToAccount(privateKey);
            }
            web3.eth.accounts.wallet.add(account);
            return account;
        };

        const preLoad = async () => {
            const failablePassSessionMessage = await api.customer.getPassSessionMessage();
            await failablePassSessionMessage.match({
                success: async request => {
                    nextSignedMsg = {
                        message: request,
                        signature: await web3.eth.sign(request, account().address),
                    };
                    isLoading = false;
                    return Promise.resolve();
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID], undefined, { ref: this.parsedRoute });
                            break;
                    }
                    return Promise.resolve();
                }
            });
        }

        const generatePass = () => {
            const newIntervalProgress = ((Date.now() - startTime) % intervalSize) / intervalSize;

            if(!actualSignedMsg || actualSignedMsg.message === "") {
                actualSignedMsg = nextSignedMsg;
                setSignedMsg(actualSignedMsg);
            }

            if(newIntervalProgress > 0.9 && !isLoading && actualSignedMsg.message === nextSignedMsg.message) {
                isLoading = true;
                preLoad();
            }

            if(newIntervalProgress < actualIntervalProgress) {
                if(nextSignedMsg && actualSignedMsg.message !== nextSignedMsg.message) {
                    actualSignedMsg = nextSignedMsg;
                    setSignedMsg(actualSignedMsg);
                    actualIntervalProgress = newIntervalProgress;
                    setIntervalProgress(actualIntervalProgress);
                }
            } else {
                actualIntervalProgress = newIntervalProgress;
                setIntervalProgress(actualIntervalProgress);
            }
        };

        const clickDontShowAgainHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setDontShowAgain(oldDontShowAgain => !oldDontShowAgain);
            e.preventDefault();
        }

        const dismiss = async (e: React.MouseEvent<HTMLButtonElement>) => {
            if(dontShowAgain) {
                localStorage.setItem("dismissPassDisclaimer", "true");
            }
            setDismissDisclaimer(true);
            e.preventDefault();
        }

        const changeBgColor = (colorName: string | null) => {
            return (e: React.MouseEvent<HTMLDivElement>) => {
                setBgColor(colorName);
                e.preventDefault();
            }
        }

        const colorCircle = (colorName: string | null) => {
            return (
                <div className={`qr-code-color-circle ${bgColor === colorName ? "selected" : ""}`}>
                    <div className={`qr-code-color bg-gradient-${!!colorName ? colorName : "default"}`} onClick={changeBgColor(colorName)}></div>
                </div>
            );
        }

        return firstLoad ? <ScreenLoader /> : 
            <div id="pass">
                {!signedMsg || !signedMsg.message ?
                    <div className="pass-prompt">
                        <img src={`/images/${theme}/empty-tickets.svg`} />
                        <h3>Sem ingressos</h3>
                        <p>Você não tem nenhum ingresso em sua carteira. Adquira ingressos para os eventos mais irados na galeria da Passify.</p>
                        <a onClick={() => goTo(views[EVENTS_ID])}>Encontrar eventos</a>
                    </div> :
                    <>
                        {!dismissDisclaimer && <div id="pass-disclaimer">
                            <div id="pass-disclaimer-container">
                                <div id="pass-disclaimer-title">Bem vindo ao passe Passify!</div>
                                <div id="pass-disclaimer-text">Este passe único permite acesso a todos os ingressos em sua carteira, eliminando a necessidade de buscar cada QR Code individualmente.<br />Consulte sua carteira para verificar seus ingressos disponíveis.</div>
                                <div id="pass-disclaimer-dont-show-again"><CheckboxInput id="pass-dont-show-again" name="dont-show-again" checked={dontShowAgain} text="Não mostrar de novo" onClick={clickDontShowAgainHandle} /></div>
                                <div id="pass-disclaimer-button-container"><SmallButton content="Ok, entendi!" onClick={dismiss} /></div>
                            </div>
                        </div>}
                        {false && !event && <div id="qr-code-color-selector">
                            {colorCircle(null)}
                            {colorCircle("blue")}
                            {colorCircle("red")}
                            {colorCircle("green")}
                            {colorCircle("yellow")}
                            {colorCircle("orange")}
                            {colorCircle("gray")}
                        </div>}
                        <div id="qr-code-holder">
                            {signedMsg && signedMsg.message != "" && <>
                                <div id="qr-code" className={event!! ? "bg-event" : ""} style={{"--bg-opacity": bgOpacity} as React.CSSProperties}>
                                    {!event && <div id="qr-code-bg-overlay" className={`${alternateColor ? "alternate" : ""} ${!!bgColor ? `bg-gradient-${bgColor}` : ""}`}>
                                        <div id="qr-code-footer">
                                            <hr />
                                            <img src={`/images/passify-${theme}.svg`} />
                                        </div>
                                    </div>}
                                    {!!event && <div id="qr-code-bg-image">
                                        <img ref={getImage} />
                                    </div>}
                                    <QRCodeSVG fgColor={theme === "dark" ? "#fff" : "#18201b"} bgColor="transparent" value={JSON.stringify(signedMsg)} />
                                </div>
                                {intervalProgress !== -1 && 
                                    <div id="qr-code-progressbar-container" className={`${alternateColor ? "alternate" : ""} ${!!bgColor ? `bg-solid-${bgColor}` : ""}`}>
                                        <div id="qr-code-progressbar" className={`${alternateColor ? "alternate" : ""} ${!!bgColor ? `bg-gradient-${bgColor}` : ""}`} style={{width: `${intervalProgress * 286}px`}}></div>
                                    </div>
                                }
                            </>}
                        </div>
                    </>
                }
                {signedMsg && signedMsg.message != "" && <div id="pass-info">NÃO USE PRINTS, ELES NÃO VÃO FUNCIONAR!</div>}
            </div>;
    }
}

export { PassView };