import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation, faX } from "@fortawesome/free-solid-svg-icons";
import { Lock, Pin } from "@mui/icons-material";
import { FORGOT_PASSWORD_ID, HOME_ID, PhoneFormatter, REGISTER_PHONE_ID, SIGNIN_ID, SIGNUP_ID, View } from "../../shared";
import { MainButton, ScreenLoader, TextInput } from "../../components";
import { ApiContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./verificationview.scss";

class VerificationView extends View {
    id = ""; //VERIFICATION_ID;
    route = "/auth/verification";
    defaultRoute = false;
    authNeeded = false;
    header = {
        backClick: null
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [reason, setReason] = React.useState<string>();
        const [email, setEmail] = React.useState<string>();
        const [password, setPassword] = React.useState<string>();
        const [confirmPassword, setConfirmPassword] = React.useState<string>("");
        const [rememberMe, setRememberMe] = React.useState<boolean>(false);
        const [phone, setPhone] = React.useState<string>();
        const [code, setCode] = React.useState<string>("");

        const passwordRequirementsRef = React.useRef<HTMLDivElement>(null);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { navigate, views, goTo } = this.navigation = React.useContext(NavigationContext);

        const phoneFormatter = new PhoneFormatter();

        React.useEffect(() => {
            const rawRegisterData = sessionStorage.getItem("register");
            if(rawRegisterData == null) {
                navigate(-1);
            } else {
                const registerData = JSON.parse(rawRegisterData);
                setReason(registerData["reason"]);
                switch(registerData["reason"]) {
                    case "confirmEmail":
                        setEmail(registerData["email"]);
                        setPassword(registerData["password"]);
                        setRememberMe(registerData["rememberMe"]);
                        break;
                    case "resetPassword":
                        setEmail(registerData["email"]);
                        break;
                    case "confirmPhone":
                        setPhone(registerData["phone"]);
                        setEmail(registerData["email"]);
                        break;
                    default:
                        navigate(-1);
                        break;
                }
            }
            setFirstLoad(false);
        }, []);

        const bypassPhoneConfirmation = () => {
            localStorage.setItem(`whatsappBypass-${email}`, "true");
            goTo(views[HOME_ID]);
        }

        const changeCodeHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const codeCandidate = e.currentTarget.value;
            if(codeCandidate.length <= 6 && !isNaN(parseInt(codeCandidate))) {
                setCode(codeCandidate);
            }
            e.preventDefault();
        }

        const changePasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setPassword(e.currentTarget.value);
            e.preventDefault();
        }

        const changeConfirmPasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setConfirmPassword(e.currentTarget.value);
            e.preventDefault();
        }

        const _checkPasswordMatch = () => password == confirmPassword;
        const _checkPasswordLength = () => (password ?? "").length >= 6;
        const _checkPasswordLowercase = () => /[a-z]/.test(password ?? "");
        const _checkPasswordUppercase = () => /[A-Z]/.test(password ?? "");
        const _checkPasswordDigit = () => /[0-9]/.test(password ?? "");
        const _checkPasswordSpecialChar = () => /[!@#$%^&*(),.?":{}|<>]/.test(password ?? "");

        const openRequirements = (e: React.FormEvent<HTMLInputElement>) => {
            const elem = passwordRequirementsRef.current;
            if(!!elem) {
                elem.className = "open";
            }
            e.preventDefault();
        }

        const closeRequirements = (e: React.FormEvent<HTMLInputElement>) => {
            const elem = passwordRequirementsRef.current;
            if(!!elem) {
                elem.className = "";
            }
            e.preventDefault();
        }

        const validIcon = <FontAwesomeIcon icon={faCheck} />
        const recommendedIcon = <FontAwesomeIcon icon={faExclamation} />
        const invalidIcon = <FontAwesomeIcon icon={faX} />

        const requirement = (valid: boolean, text: string) => {
            return <div className={valid ? "requirement-valid" : "requirement-invalid"}>
                {valid ? validIcon : invalidIcon}
                <div className="requirement-text">{text}</div>
            </div>;
        }

        const recommendation = (valid: boolean, text: string) => {
            return <div className={valid ? "requirement-valid" : "requirement-recommended"}>
                {valid ? validIcon : recommendedIcon}
                <div className="requirement-text">{text}</div>
            </div>;
        }

        const verifyEmail = async () => {
            const failableEmailConfirm = await api.auth.confirmEmail(email!.trim(), code, password, rememberMe);
            failableEmailConfirm.match({
                success: emailConfirmed => {
                    if(emailConfirmed) {
                        sessionStorage.removeItem("register");
                        goTo(views[REGISTER_PHONE_ID]);
                    } else {
                        pushNotification("Código de verificação iválido.");
                    }
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID]);
                            break;
                        case "FETCH_GENERIC_ERROR":
                            pushNotification("Código de verificação iválido.");
                            break;
                    }
                }
            });
        }

        const verifyPhone = async () => {
            const failablePhoneChange = await api.customer.changePhone(phone!, code);
            failablePhoneChange.match({
                success: phoneChanged => {
                    if(phoneChanged) {
                        sessionStorage.removeItem("register");
                        goTo(views[HOME_ID]);
                    } else {
                        pushNotification("Código de verificação iválido.");
                    }
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID]);
                            break;
                        case "FETCH_GENERIC_ERROR":
                            pushNotification("Código de verificação iválido.");
                            break;
                    }
                }
            });
        }

        const confirmResetPassword = async () => {
            const failablePasswordReset = await api.auth.resetPassword(email!.trim(), code, password!, rememberMe);
            failablePasswordReset.match({
                success: passwordReset => {
                    if(passwordReset) {
                        sessionStorage.removeItem("register");
                        goTo(views[HOME_ID]);
                    } else {
                        pushNotification("Código de verificação iválido.");
                    }
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID]);
                            break;
                        case "FETCH_GENERIC_ERROR":
                            pushNotification("Código de verificação iválido.");
                            break;
                    }
                }
            });
        }

        const verify = async (e: React.MouseEvent<HTMLButtonElement>) => {
            switch(reason) {
                case "confirmEmail":
                    await verifyEmail();
                    break;
                case "confirmPhone":
                    await verifyPhone();
                    break;
                case "resetPassword":
                    await confirmResetPassword();
                    break;
            }
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="verification">
            {phone != undefined ? <>
                <div id="verification-explanation">
                    Enviamos um código para seu WhatsApp<br /><br /><b>{phoneFormatter.mask(phone)}</b><br /><small>(<a onClick={() => goTo(views[REGISTER_PHONE_ID])}>Esse não é o meu número</a>)</small><br /><br />Insira-o na caixa abaixo para validá-lo:
                </div>
                <div className="verification-input-container">
                    <div className="label">Código de verificação</div>
                    <TextInput id="verification-code" name="verification-code" value={code} prefix={<Pin sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000000" onChange={changeCodeHandle} />
                </div>
                <div className="verification-button-container">
                    <MainButton enabled={code.length == 6} content="Verificar" onClick={verify} />
                </div>
                <div className="verification-bottom"><small><a onClick={bypassPhoneConfirmation}>Não tenho WhatsApp</a></small></div>
            </> : reason == "confirmEmail" ? <>
                <div id="verification-explanation">
                    Enviamos um código para seu e-mail<br /><br /><b>{email}</b><br /><small>(<a onClick={() => goTo(views[SIGNUP_ID])}>Esse não é o meu e-mail</a>)</small><br /><br />Insira-o na caixa abaixo para validá-lo:
                </div>
                <div className="verification-input-container">
                    <div className="label">Código de verificação</div>
                    <TextInput id="verification-code" name="verification-code" value={code} prefix={<Pin sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000000" onChange={changeCodeHandle} />
                </div>
                <div className="verification-button-container">
                    <MainButton enabled={code.length == 6} content="Verificar" onClick={verify} />
                </div>
            </> : <>
                <div id="verification-explanation">
                    Enviamos um código para seu e-mail<br /><b>{email}</b><br /><br /><small>(<a onClick={reason == "confirmEmail" ? () => goTo(views[SIGNUP_ID]) : () => goTo(views[FORGOT_PASSWORD_ID])}>Esse não é o meu e-mail</a>)</small><br /><br />Para resetar sua senha, insira-o na caixa abaixo para validá-lo e crie uma nova senha:
                </div>
                <div className="verification-input-container">
                    <div className="label">Código de verificação</div>
                    <TextInput id="verification-code" name="verification-code" value={code} prefix={<Pin sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000000" onChange={changeCodeHandle} />
                </div>
                <div className="verification-input-container">
                    <div className="label">Senha</div>
                    <TextInput id="verification-password" name="password" password={true} value={password ?? ""} prefix={<Lock sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Senha" onChange={changePasswordHandle} onFocus={openRequirements} onBlur={closeRequirements} />
                </div>
                <div className="verification-input-container">
                    <div className="label">Confirme a senha</div>
                    <TextInput id="verification-confirm-password" name="confirm-password" password={true} value={confirmPassword} prefix={<Lock sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Confirme a senha" onChange={changeConfirmPasswordHandle} onFocus={openRequirements} onBlur={closeRequirements} />
                </div>
                <div ref={passwordRequirementsRef} id="password-requirements">
                    <div>Obrigatório:</div>
                    {requirement(_checkPasswordLength(), "Mínimo de 6 caracteres")}
                    {requirement(_checkPasswordMatch(), "Senha e confirmação de senha iguais")}
                    <div><br />Recomendado:</div>
                    {recommendation(_checkPasswordLowercase(), "Pelo menos uma letra minúscula")}
                    {recommendation(_checkPasswordUppercase(), "Pelo menos uma letra maiúscula")}
                    {recommendation(_checkPasswordDigit(), "Pelo menos um número")}
                    {recommendation(_checkPasswordSpecialChar(), "Pelo menos um caractere especial (!,@,*, ...)")}
                </div>
                <div className="verification-button-container">
                    <MainButton enabled={_checkPasswordLength() && _checkPasswordMatch() && code.length == 6} content="Resetar senha" onClick={verify} />
                </div>
            </>}
        </div>
    }
}

export { VerificationView };