import React from "react";
import { IFailable, failableAsync } from "ts-failable";
import { ApiTransferError, PassifyApi, TransferOrder } from "../../shared";
import { AuthContext } from "../auth";

type F<T> = IFailable<T, ApiTransferError>;
type P<T> = Promise<F<T>>;

export type ApiTransfer = {
    createTransfer: (passes: Array<string>, target: {email?: string, phone?: string}) => P<TransferOrder>,
    getTransfer: (transferId: string) => P<TransferOrder>,
    cancelTransfer: (transferId: string) => P<boolean>,
    receiveTransfer: (transferId: string) => P<boolean>,
    rejectTransfer: (transferId: string) => P<boolean>,
}

export const ApiTransferProvider = (): ApiTransfer => {
    const { getAuth, refreshAuth } = React.useContext(AuthContext);

    const createTransfer = async (passes: Array<string>, target: {email?: string, phone?: string}): Promise<F<TransferOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.createTransfer(auth.accessToken, passes, target);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as TransferOrder);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return createTransfer(passes, target);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    const getTransfer = async (transferId: string): Promise<F<TransferOrder>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = await getAuth();
            const response = await auth.match({
                success: auth => PassifyApi.getTransfer(auth.accessToken, transferId),
                failure: () => PassifyApi.getTransfer("", transferId),
            });
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as TransferOrder);
                }
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    const cancelTransfer = async (transferId: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.cancelTransfer(auth.accessToken, transferId);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(true);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return cancelTransfer(transferId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    const receiveTransfer = async (transferId: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.receiveTransfer(auth.accessToken, transferId);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(true);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return receiveTransfer(transferId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    const rejectTransfer = async (transferId: string): Promise<F<boolean>> => failableAsync(
        async ({ success, failure, run }) => {
            const auth = run(getAuth());
            const response = await PassifyApi.rejectTransfer(auth.accessToken, transferId);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(true);
                }
            } else if(response.status === 401) {
                run(await refreshAuth());
                return rejectTransfer(transferId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: ""});
        }
    );

    return {
        createTransfer,
        getTransfer,
        cancelTransfer,
        receiveTransfer,
        rejectTransfer,
    };
};