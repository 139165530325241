import React from "react";
import md5 from "md5";
import { DateTime } from "luxon";
import { AccessTime, LocationOn, PlayArrow } from "@mui/icons-material";
import { HOME_ID, SIGNIN_ID, TRANSFER_RECEIVE_ID, TransferOrder, View } from "../../shared";
import { MainButton, ScreenLoader, SecondaryButton } from "../../components";
import { ApiContext, AuthContext, CustomerContext, NavigationContext, NotificationsContext } from "../../contexts";
import { useParams } from "react-router-dom";
import "./transferreceiveview.scss";

interface TransferringItems {
    [eventSessionId: string]: {
        eventSessionName: string,
        image?: string,
        venueName: string,
        startDate: string,
        endDate: string,
        tickets: Array<{
            batchId: string,
            batchName: string,
            quantity: number,
            price: number,
        }>,
    }
}

class TransferReceiveView extends View {
    id = TRANSFER_RECEIVE_ID;
    route = "/wallet/transfer/receive/:transferId";
    defaultRoute = false;
    authNeeded = false;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[HOME_ID]); }
    };
    render = () => {
        const params = this.params = useParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [transferOrder, setTransfer] = React.useState<TransferOrder | null>(null);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { auth } = React.useContext(AuthContext);
        const { customer } = React.useContext(CustomerContext);
        const { goTo, views, location } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const transferId = params["transferId"];

            if(!transferId) {
                goTo(views[HOME_ID]);
            }

            return { transferId: transferId ?? "" };
        }

        const loadTransfer = async (transferId: string) => {
            const failableTransfer = await api.transfer.getTransfer(transferId);
            return failableTransfer.match({
                success: transfer => transfer,
                failure: () => null,
            });
        }

        const init = () => {
            if(!auth) {
                goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
            }

            const { transferId } = getIds();

            loadTransfer(transferId).then(transferOrder => {
                if(!!transferOrder) {
                    setTransfer(transferOrder);
                } else {
                    goTo(views[HOME_ID]);
                }
                setFirstLoad(false);
            });
        }

        React.useEffect(init, []);

        const getTransferringItems = () => {
            const items: TransferringItems = {};
            if(!!transferOrder) {
                for(let i = 0; i < transferOrder.passes.length; i++) {
                    const pass = transferOrder.passes[i];
                    if(Object.keys(items).indexOf(pass.eventSession.id) === -1) {
                        items[pass.eventSession.id] = {
                            eventSessionName: pass.eventSession.name,
                            image: pass.event.smallImageUrl,
                            venueName: pass.event.venue.name,
                            startDate: pass.eventSession.startDate,
                            endDate: pass.eventSession.endDate,
                            tickets: [],
                        };
                    }

                    if(!items[pass.eventSession.id].tickets.some(ticket => ticket.batchId === pass.batch.id)) {
                        items[pass.eventSession.id].tickets.push({
                            batchId: pass.batch.id,
                            batchName: pass.batch.name,
                            quantity: 1,
                            price: pass.batch.price,
                        });
                    } else {
                        items[pass.eventSession.id].tickets = items[pass.eventSession.id].tickets.map(ticket => {
                            if(ticket.batchId === pass.batch.id) {
                                return { ...ticket, quantity: ticket.quantity + 1 };
                            }
                            return ticket;
                        });
                    }
                }
            }
            return items;
        }

        const receiveTransfer = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const { transferId } = getIds();

            const failableReceive = await api.transfer.receiveTransfer(transferId);
            failableReceive.match({
                success: received => {
                    if(received) {
                        pushNotification("Ingresso recebido!");
                        goTo(views[HOME_ID]);
                    }
                },
                failure: () => {
                    pushNotification("Erro ao receber ingresso.");
                }
            });

            e.preventDefault();
        }

        const cancelTransfer = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const { transferId } = getIds();

            const failableCancel = await api.transfer.cancelTransfer(transferId);
            failableCancel.match({
                success: received => {
                    if(received) {
                        pushNotification("Transferência cancelada!");
                        goTo(views[HOME_ID]);
                    }
                },
                failure: () => {
                    pushNotification("Erro ao cancelar transferência.");
                }
            });

            e.preventDefault();
        }

        const rejectTransfer = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const { transferId } = getIds();

            const failableReject = await api.transfer.rejectTransfer(transferId);
            failableReject.match({
                success: rejected => {
                    if(rejected) {
                        pushNotification("Ingresso rejeitado!");
                        goTo(views[HOME_ID]);
                    }
                },
                failure: () => {
                    pushNotification("Erro ao rejeitar ingresso.");
                }
            });

            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="transfer-receive">
            <div id="transfer-receive-title">Transferência</div>
            <div id="transfer-receive-peers">
                <div id="transfer-receive-peers-sender" className="transfer-receive-peer">
                    <div className="transfer-receive-peer-image">
                        {!!transferOrder?.senderPicture ?
                            <img src={transferOrder.senderPicture} /> :
                            !!transferOrder?.canCancel && !!customer?.email ?
                                <img src={`https://gravatar.com/avatar/${md5(customer.email)}?d=retro`} /> :
                                <span className="unknown-peer">?</span>
                        }
                    </div>
                    <div className="transfer-receive-peer-name">
                        {!!transferOrder?.sender ?
                            transferOrder.sender :
                            (!!transferOrder?.canCancel && !!customer?.email) ?
                                customer?.name ?? customer?.email :
                                <span className="unknown-sender">Remetente desconhecido</span>
                        }
                    </div>
                </div>
                <PlayArrow />
                <div id="transfer-receive-peers-recipient" className="transfer-receive-peer">
                    <div className="transfer-receive-peer-image">
                        {!!transferOrder?.recipientPicture ?
                            <img src={transferOrder.recipientPicture} /> :
                            !!transferOrder?.canReceive && !!customer?.email ?
                                <img src={`https://gravatar.com/avatar/${md5(customer.email)}?d=retro`} /> :
                                <span className="unknown-peer">?</span>
                        }
                    </div>
                    <div className="transfer-receive-peer-name">
                        {!!transferOrder?.recipient ?
                            transferOrder.recipient :
                            (!!transferOrder?.canReceive && !!customer?.email) ?
                                customer?.name ?? customer?.email :
                                <span className="unknown-recipient">Destinatário desconhecido</span>
                        }
                    </div>
                </div>
            </div>
            {Object.values(getTransferringItems()).map(item =>
                <div key={item.eventSessionName} className="transfer-receive-item">
                    <div className="transfer-receive-item-session">
                        <div className="transfer-receive-item-image">
                            <img src={item.image} />
                        </div>
                        <div className="transfer-receive-item-session-info">
                            <div className="transfer-receive-item-name">{item.eventSessionName}</div>
                            <div className="transfer-receive-item-date">
                                <span className="icon"><AccessTime sx={{width: 16, height: 16}} /></span> {DateTime.fromMillis(Date.parse(item.startDate)).toFormat("ccc, d LLL yyyy", { locale: "pt-br" })}
                            </div>
                            <div className="transfer-receive-item-location">
                                <span className="icon"><LocationOn sx={{width: 16, height: 16}} /></span> {item.venueName}
                            </div>
                        </div>
                    </div>
                    <div className="transfer-receive-item-batches">
                        {item.tickets.map(ticket => <div key={ticket.batchId} className="transfer-receive-item-batch">{ticket.quantity}x {ticket.batchName}</div>)}
                    </div>
                </div>
            )}
            {transferOrder?.status.toLowerCase() === "draft" ?
                <>
                    {!!transferOrder?.canReceive && <MainButton content={"Aceitar transferência"} onClick={receiveTransfer} />}
                    {!!transferOrder?.canCancel && <MainButton content={"Cancelar transferência"} onClick={cancelTransfer} />}
                    {!!transferOrder?.canReject && <SecondaryButton content="Rejeitar transferência" onClick={rejectTransfer} />}
                    {(!transferOrder || (!transferOrder.canCancel && !transferOrder.canReceive && !transferOrder.canReject)) && "Esta transferência de ingresso não é para você."}
                </> :
                transferOrder?.status.toLowerCase() === "canceled" ?
                    <>Esta transferência foi cancelada pelo dono do ingresso.</> :
                    <>Esta transferência já foi realizada.</>
            }
        </div>;
    }
}

export { TransferReceiveView };