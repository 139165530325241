export const fbLogin = async (callback) => {
    return new Promise(resolve => {
        window.FB.login(response => {
            console.log(response);
            if(response.status == "connected") {
                callback(response.authResponse).then(resolve).catch(resolve);
            } else {
                return ;
            }
        }, {scope: "email,public_profile"});
    });
}