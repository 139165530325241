import React from "react";
import { ApiProvider } from "./api";
import { AuthProvider } from "./auth";
import { CustomerProvider } from "./customer";
import { NavigationProvider } from "./navigation";
import { ThemeProvider } from "./theme";
import { QueueProvider } from "./queue";
import { NotificationsProvider } from "./notifications";
import { MetaProvider } from "./meta";

const PassifyProvider = ({ children } : { children: React.ReactNode}) => (
    <MetaProvider>
        <NotificationsProvider>
            <AuthProvider>
                <QueueProvider>
                    <ApiProvider>
                        <CustomerProvider>
                            <NavigationProvider>
                                <ThemeProvider>
                                    {children}
                                </ThemeProvider>
                            </NavigationProvider>
                        </CustomerProvider>
                    </ApiProvider>
                </QueueProvider>
            </AuthProvider>
        </NotificationsProvider>
    </MetaProvider>
);

export { PassifyProvider };