import { SyncMouseEventHandler } from "../../shared";
import "./binaryswitch.scss";

interface BinaryValues {
    on: string,
    off: string,
}

interface BinaryIcons {
    on: JSX.Element,
    off: JSX.Element,
}

interface BinarySwitchProps {
    checked?: boolean,
    rounded?: boolean,
    onClick?: SyncMouseEventHandler<HTMLInputElement>,
    values?: BinaryValues,
    icons?: BinaryIcons,
}

const BinarySwitch = (props: BinarySwitchProps) => {
    const checked = props.checked !== undefined ? props.checked : false;
    const rounded = props.rounded !== undefined ? props.rounded : true;

    return <label className="binary-switch">
        <input type="checkbox" checked={checked} onClick={props.onClick} />
        <span className={`binary-switch-slider ${rounded ? "rounded" : ""}`}></span>
    </label>
}

export { BinarySwitch };