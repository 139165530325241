import React from "react";
import { ScreenLoader, SmallButton, TextInput } from "../../components";
import { ApiCustomerError, EmailFormatter, PhoneFormatter, SIGNIN_ID, View, CONTACT_ID, SETTINGS_ID, CHANGE_PHONE_ID, CHANGE_EMAIL_ID } from "../../shared";
import { Mail, MailOutline, WhatsApp } from "@mui/icons-material";
import { ApiContext, NavigationContext } from "../../contexts";
import "./contactview.scss";

class ContactView extends View {
    id = CONTACT_ID;
    route = "/settings/contact";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => this.navigation!.goTo(this.navigation!.views[SETTINGS_ID]),
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

        const [email, setEmail] = React.useState<string>("");
        const [emailCursor, setEmailCursor] = React.useState<number>(0);
        const emailRef = React.useRef<HTMLInputElement>(null);
        const emailFormatter = new EmailFormatter();

        const [phone, setPhone] = React.useState<string>("");
        const [phoneCursor, setPhoneCursor] = React.useState<number>(0);
        const phoneRef = React.useRef<HTMLInputElement>(null);
        const phoneFormatter = new PhoneFormatter();

        const [currentEmail, setCurrentEmail] = React.useState<string>("");
        const [currentPhone, setCurrentPhone] = React.useState<string>("");

        const api = React.useContext(ApiContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);

        React.useEffect(() => {
            api.customer.getCustomerData().then(failabelCustomerData => {
                failabelCustomerData.match({
                    success: customerData => {
                        setEmail(customerData.email);
                        setCurrentEmail(customerData.email);
                        setPhone(phoneFormatter.mask(customerData.phone || "") || "");
                        setCurrentPhone(customerData.phone || "");
                    },
                    failure: () => goTo(views[SIGNIN_ID])
                });
                setFirstLoad(false);
            });
        }, []);

        const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = emailFormatter.updateValue({text: email, cursor: emailCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setEmail(updatedValue.text);
            setEmailCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = emailRef.current;
            if(!!input) {
                input.setSelectionRange(emailCursor, emailCursor);
            }
        }, [emailRef, emailCursor, email]);

        const changePhoneHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = phoneFormatter.updateValue({text: phone, cursor: phoneCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setPhone(updatedValue.text);
            setPhoneCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = phoneRef.current;
            if(!!input) {
                input.setSelectionRange(phoneCursor, phoneCursor);
            }
        }, [phoneRef, phoneCursor, phone]);

        const onFailure = (error: ApiCustomerError) => {
            switch(error.errorType) {
                case "NO_AUTH":
                case "AUTH_EXPIRED":
                    goTo(views[SIGNIN_ID]);
                    break;
            }
            return Promise.resolve();
        }

        const saveEmail = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const unmaskedEmail = emailFormatter.unmask(email);
            if(unmaskedEmail !== currentEmail) {
                const failableRequestEmailChange = await api.customer.requestEmailChange(unmaskedEmail);
                await failableRequestEmailChange.match({
                    success: emailChangeRequested => {
                        if(emailChangeRequested) {
                            sessionStorage.setItem("register", JSON.stringify({
                                reason: "confirmEmail",
                                email: email,
                            }));
                            goTo(views[CHANGE_EMAIL_ID]);
                        }
                        return Promise.resolve();
                    },
                    failure: onFailure
                });
            }
            e.preventDefault();
        }

        const savePhone = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const unmaskedPhone = phoneFormatter.unmask(phone);
            const updatePhone = unmaskedPhone[0] != "+" ? `+55${unmaskedPhone}` : unmaskedPhone;
            if(updatePhone !== currentPhone) {
                const failableRequestPhoneChange = await api.customer.requestPhoneChange(updatePhone);
                await failableRequestPhoneChange.match({
                    success: phoneChangeRequested => {
                        if(phoneChangeRequested) {
                            sessionStorage.setItem("register", JSON.stringify({
                                reason: "confirmPhone",
                                phone: updatePhone,
                                email: email,
                            }));
                            goTo(views[CHANGE_PHONE_ID]);
                        }
                        return Promise.resolve();
                    },
                    failure: onFailure
                });
            }
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="contact">
            <div className="contact-prompt">
                <h2><MailOutline />Contato</h2>
                <p>Mensagens, notificações e confirmações serão enviadas para você através do E-mail e WhatsApp informados abaixo.</p>
            </div>
            <div className="contact-form">
                <div className="contact-form-field">
                    <div className="label">E-mail</div>
                    <TextInput ref={emailRef} id="contact-email" name="email" value={email} prefix={<Mail sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="E-mail" onChange={changeEmailHandle} />
                </div>
                <SmallButton enabled={email !== "" && email !== currentEmail} content="Atualizar" onClick={saveEmail} />
            </div>
            <div className="contact-form">
                <div className="contact-form-field">
                    <div className="label">WhatsApp</div>
                    <TextInput ref={phoneRef} id="contact-phone" name="phone" inputMode="tel" value={phone} prefix={<WhatsApp sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="(11) 99999-9999" onChange={changePhoneHandle} />
                </div>
                <SmallButton enabled={phone !== "" && phoneFormatter.unmask(phone) !== currentPhone} content="Atualizar" onClick={savePhone} />
            </div>
        </div>;
    }
}

export { ContactView };