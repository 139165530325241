import React from "react";
import { Lock } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation, faX } from "@fortawesome/free-solid-svg-icons";
import { CONFIRM_EMAIL_ID, CREATE_PASSWORD_ID, HOME_ID, PassifyApi, SIGNIN_ID, View } from "../../shared";
import { useSearchParams } from "react-router-dom";
import { CheckboxInput, MainButton, PasswordForm, PasswordRequirement, ScreenLoader, TextInput } from "../../components";
import { ApiContext, NavigationContext } from "../../contexts";
import "./createpasswordview.scss";

class CreatePasswordView extends View {
    id = CREATE_PASSWORD_ID;
    route = "/auth/create-password";
    defaultRoute = false;
    authNeeded = false;
    header = {
        backClick: null,
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [email, setEmail] = React.useState<string>("");
        const [password, setPassword] = React.useState<string>("");
        const [confirmPassword, setConfirmPassword] = React.useState<string>("");
        const [requirementsFulfilled, setRequirementsFulfilled] = React.useState<boolean>(false);
        const [showPasswordRequirements, setShowPasswordRequirements] = React.useState<boolean>(false);
        const [agreeOnTerms, setAgreeOnTerms] = React.useState<boolean>(false);
        const [waitForClose, setWaitForClose] = React.useState<boolean>(false);

        const api = React.useContext(ApiContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);

        React.useEffect(() => {
            api.customer.getCustomerData().then(failableCustomerData => {
                failableCustomerData.match({
                    success: customerData => {
                        if(customerData.email === "") {
                            goTo(views[HOME_ID]);
                        }
                        setEmail(customerData.email);
                    },
                    failure: error => {
                        switch(error.errorType) {
                            case "NO_AUTH":
                            case "AUTH_EXPIRED":
                                goTo(views[SIGNIN_ID]);
                                break;
                        }
                    }
                });
                setFirstLoad(false);
            });
        }, []);

        React.useEffect(() => {
            if(waitForClose) {
                toggleRequirements(false);
            }
        }, [password, confirmPassword, agreeOnTerms]);

        const clickAgreeOnTermsHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setAgreeOnTerms(oldAgreeOnTerms => !oldAgreeOnTerms);
            e.preventDefault();
        }

        const createPassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
            if(requirementsFulfilled && email.trim() != "") {
                const response = await PassifyApi.register(email.trim(), password);
                if(response.status == 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"] == true) {
                        sessionStorage.setItem("register", JSON.stringify({
                            reason: "confirmEmail",
                            email: email.trim(),
                            password: password,
                            rememberMe: true,
                        }));
                        goTo(views[CONFIRM_EMAIL_ID]);
                    }
                }
            }
            e.preventDefault();
        };

        const toggleRequirements = (focus: boolean) => {
            if(focus) {
                setShowPasswordRequirements(true);
                setWaitForClose(false);
            } else if(requirementsFulfilled) {
                setShowPasswordRequirements(false);
                setWaitForClose(false);
            } else {
                setWaitForClose(true);
            }
        }

        return firstLoad ? <ScreenLoader /> : <div id="create-password">
            <div className="create-password-prompt">Cadastre uma senha para concluir o seu cadastro e acessar seu passe:</div>
            <PasswordForm onPasswordChanged={setPassword} onConfirmPasswordChanged={setConfirmPassword} onFocus={toggleRequirements} showPasswordRequirements={showPasswordRequirements} required={[PasswordRequirement.length]} recommended={[PasswordRequirement.lowercase, PasswordRequirement.uppercase, PasswordRequirement.digit, PasswordRequirement.specialchar]} onRequirementsChanged={setRequirementsFulfilled} />
            <div className="terms"><CheckboxInput id="signin-agree-on-terms" name="agree-on-terms" checked={agreeOnTerms} text="" onClick={clickAgreeOnTermsHandle} /><div>Eu aceito a <a href="https://passify.co/politica-de-privacidade.pdf" target="_blank">política de privacidade</a> e os <a href="https://passify.co/termos-de-servico.pdf" target="_blank">termos de serviço</a> da Passify</div></div>
            <MainButton content="Concluir cadastro" onClick={createPassword} />
        </div>;
    }
}

export { CreatePasswordView };