import { MaskFormatter } from "./maskformatter";

class EmailFormatter extends MaskFormatter {
    unmask(maskedText: string): string {
        return maskedText.toLowerCase();
    }

    mask(unmaskedText: string): string | null {
        if(unmaskedText === "") {
            return unmaskedText;
        }

        let match;

        match = unmaskedText.match(/^[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
        if(match != null && match.length != 0) {
            return unmaskedText;
        }

        match = unmaskedText.match(/^[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.?)+/);
        if(match != null && match.length != 0) {
            return unmaskedText;
        }

        match = unmaskedText.match(/^[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@$/);
        if(match != null && match.length != 0) {
            return unmaskedText;
        }

        match = unmaskedText.match(/^[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*/);
        if(match != null && match.length != 0) {
            return unmaskedText;
        }

        return null;
    }
}

export { EmailFormatter };