import React from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { EmailFormatter, Reservation, View, REVIEW_SUMMARY_ID, TICKET_SELECTION_ID, SIGNIN_ID, PIX_PAYMENT_ID, CARD_PAYMENT_ID, HOME_ID, EVENT_ID, EventDetails, QUEUE_ID, ApiCheckoutError, CheckoutOrder, ApiEventError } from "../../shared";
import { ListTile, MainButton, QueueClock, ScreenLoader, SecondaryButton, TextInput } from "../../components";
import { AccessTime, ArrowForwardIos, ConfirmationNumberOutlined, CreditCard, LocationOn, QrCode } from "@mui/icons-material";
import { ApiContext, NavigationContext, NotificationsContext, QueueContext } from "../../contexts";
import "./reviewsummaryview.scss";

class ReviewSummaryView extends View {
    id = REVIEW_SUMMARY_ID;
    route = "/checkout/review-summary/:eventId/:eventSessionId";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => { this.navigation!.navigate(-1); },
        supportClick: () => {}
    };
    render = () => {
        const params = this.params = useParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

        const [event, setEvent] = React.useState<EventDetails>();
        const [reservations, setReservations] = React.useState<Array<Reservation>>([]);
        const [totalAmount, setTotalAmount] = React.useState<number>(0);
        const [expirationTime, setExpirationTime] = React.useState<number>();

        const emailFormatter = new EmailFormatter();
        const [email, setEmail] = React.useState<string>("");
        const [emailCursor, setEmailCursor] = React.useState<number>(0);
        const emailRef = React.useRef<HTMLInputElement>(null);
        const [confirmEmail, setConfirmEmail] = React.useState<string>("");
        const [confirmEmailCursor, setConfirmEmailCursor] = React.useState<number>(0);
        const confirmEmailRef = React.useRef<HTMLInputElement>(null);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { getParsedQueueToken, abandonQueue } = React.useContext(QueueContext);
        const { views, goTo, navigate } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            let eventSessionId = params["eventSessionId"];
            let eventId = params["eventId"];

            if(eventId === undefined) {
                navigate(-1);
            } else if(eventSessionId === undefined) {
                goTo(views[EVENT_ID], {eventId});
            }

            return { eventId: eventId ?? "", eventSessionId: eventSessionId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            const { eventId, eventSessionId } = getIds();

            return (error: ApiCheckoutError | ApiEventError) => {
                switch(error.errorType) {
                    case "QUEUE_WAITING":
                        goTo(views[QUEUE_ID], { eventId }, { ref: this.parsedRoute })
                        break;
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID], undefined, { ref: this.parsedRoute });
                        break;
                    default:
                        goTo(views[TICKET_SELECTION_ID], {eventId, eventSessionId});
                        break;
                }
                return response;
            }
        }

        const loadEvent = async (eventId: string) => {
            const failableEvent = await api.event.getEventDetails(eventId);
            return failableEvent.match({
                success: event => event,
                failure: handleError(null)
            });
        }

        const loadOrder = async (eventId: string) => {
            const failableOrder = await api.checkout.getCheckoutOrder(eventId);
            return failableOrder.match({
                success: order => order,
                failure: handleError(null)
            });
        }

        const loadOrderById = async (orderId: string) => {
            const failableOrder = await api.checkout.getCheckoutOrderById(orderId);
            return failableOrder.match({
                success: order => order,
                failure: handleError(null)
            });
        }

        const init = () => {
            const { eventId, eventSessionId } = getIds();

            loadEvent(eventId).then(event => {
                if(!!event) {
                    setEvent(event);
                }

                const handleOrder = (order: CheckoutOrder | null) => {
                    if(!!order) {
                        if(order.reservations.length === 0) {
                            goTo(views[TICKET_SELECTION_ID], { eventId, eventSessionId });
                        } else if(!!order.invoiceCode) {
                            goTo(views[PIX_PAYMENT_ID], { eventId });
                        }
                        setReservations(order.reservations);
                        setTotalAmount(order.totalAmount);
                    }

                    setExpirationTime(getParsedQueueToken(eventId)?.exp);
                    setFirstLoad(false);
                }
                
                const storedOrderId = window.localStorage.getItem(`order-${eventId}`);
                if(!!storedOrderId) {
                    loadOrderById(storedOrderId).then(order => {
                        if(!!order && ["Canceled", "Expired", "PaymentApproved", "Completed"].indexOf(order.status) === -1) {
                            handleOrder(order);
                        } else {
                            window.localStorage.removeItem(`order-${eventId}`);
                            loadOrder(eventId).then(handleOrder);
                        }
                    });
                } else {
                    loadOrder(eventId).then(handleOrder);
                }
            });
        }

        React.useEffect(init, []);

        const subtotal = reservations.reduce((prev, cur) => prev + cur.quantity * cur.price, 0);
        const feeAmount = totalAmount - subtotal;

        const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = emailFormatter.updateValue({text: email, cursor: emailCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setEmail(updatedValue.text);
            setEmailCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = emailRef.current;
            if(input !== null) {
                input.setSelectionRange(emailCursor, emailCursor);
            }
        }, [emailRef, emailCursor, email]);

        const changeConfirmEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = emailFormatter.updateValue({text: confirmEmail, cursor: confirmEmailCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setConfirmEmail(updatedValue.text);
            setConfirmEmailCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = confirmEmailRef.current;
            if(input != null) {
                input.setSelectionRange(confirmEmailCursor, confirmEmailCursor);
            }
        }, [confirmEmailRef, confirmEmailCursor, confirmEmail]);

        const payWith = async (paymentTypeId: string) => {
            const eventId = event!.id;
            goTo(views[paymentTypeId], { eventId });
        }

        const payWithPix = async (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
            await payWith(PIX_PAYMENT_ID);
            e.preventDefault();
        }

        const payWithCard = async (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
            await payWith(CARD_PAYMENT_ID);
            e.preventDefault();
        }

        const redeemTickets = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const failableMockPayment = await api.checkout.choosePixPayment(event!.id, "", "");
            failableMockPayment.match({
                success: () => {
                    abandonQueue(event!.id);
                    payWith(HOME_ID);
                    pushNotification("Ingresso(s) resgatado(s).");
                },
                failure: () => {
                    pushNotification("Erro ao resgatar ingresso(s).");
                }
            });
            e.preventDefault();
        }

        const expire = () => {
            const { eventId } = getIds();
            goTo(views[EVENT_ID], {eventId});
        }

        return firstLoad ? <ScreenLoader /> : <div id="review-summary">
            <QueueClock expirationTime={expirationTime} onExpire={expire} />
            <div id="review-summary-session">
                <div id="review-summary-session-image">
                    <img src={event!.smallImageUrl} />
                </div>
                <div id="review-summary-session-info">
                    <div id="review-summary-session-name">{event!.name}</div>
                    <div id="review-summary-session-date">
                        <span className="icon"><AccessTime sx={{width: 16, height: 16}} /></span> {DateTime.fromMillis(Date.parse(event!.startDate)).toFormat("ccc, d LLL yyyy", { locale: "pt-br" })}
                    </div>
                    <div id="review-summary-session-location">
                        <span className="icon"><LocationOn sx={{width: 16, height: 16}} /></span> {event!.venue.name}
                    </div>
                </div>
            </div>
            <div id="review-summary-table">
                {reservations.filter(reservation => reservation.quantity > 0).map(reservation => <div className="review-summary-item">
                    <div className="review-summary-item-description" title={`${reservation.quantity}x ${reservation.segmentName}${reservation.segmentName !== reservation.batchName ? `: ${reservation.batchName}` : ""}`}>{reservation.quantity}x {reservation.segmentName}{reservation.segmentName !== reservation.batchName && `: ${reservation.batchName}`}</div>
                    <div className="review-summary-item-price">R$ {(reservation.quantity * reservation.price).toFixed(2)}</div>
                </div>)}
                <div className="review-summary-item">
                    <div>Taxa de serviço</div>
                    <div>R$ {feeAmount.toFixed(2)}</div>
                </div>
                <div id="review-summary-total" className="review-summary-item">
                    <div>Total</div>
                    <div>R$ {totalAmount.toFixed(2)}</div>
                </div>
            </div>
            {false && <>
                <div className="unsigned-title">Você não está logado na Passify!<br />Para prosseguir para o pagamento, informe seu e-mail:</div>
                <div className="unsigned-form-field">
                    <div className="label">E-mail</div>
                    <TextInput ref={emailRef} id="unsigned-email" name="email" value={email} prefix={<img src="/images/email.svg" width={16.67} height={15} />} placeholder="Seu e-mail" onChange={changeEmailHandle} />
                </div>
                <div className="unsigned-form-field">
                    <div className="label">Confirme seu E-mail</div>
                    <TextInput ref={confirmEmailRef} id="unsigned-confirm-email" name="confirm-email" value={confirmEmail} prefix={<img src="/images/email.svg" width={16.67} height={15} />} placeholder="Confirme seu e-mail" onChange={changeConfirmEmailHandle} />
                </div>
            </>}
            <div className="payment-button-container">
                {totalAmount > 0 ? <>
                    <div className="payment-button-container-box" onClick={payWithPix}>
                        <img src="/images/pix.svg" />
                        PIX
                    </div>
                    <div className="payment-button-container-box" onClick={payWithCard}>
                        <img src="/images/credit-card.svg" />
                        Cartão de crédito
                    </div>
                </> : totalAmount > 0 ? <>
                    <SecondaryButton content={<ListTile separator={false} leading={<QrCode />} title="PIX" trailing={<ArrowForwardIos />} />} onClick={payWithPix} />
                    <SecondaryButton content={<ListTile separator={false} leading={<CreditCard />} title="Cartão de crédito" trailing={<ArrowForwardIos />} />} onClick={payWithCard} />
                </> : <MainButton content={<ListTile separator={false} leading={<ConfirmationNumberOutlined />} title="Receber ingresso(s)" trailing={<ArrowForwardIos />} />} onClick={redeemTickets} />
                }
            </div>
        </div>;
    }
}

export { ReviewSummaryView };