import React from "react";
import { v4 } from "uuid";

interface Notification {
    id: string,
    message: string,
    show: boolean,
}

export type NotificationsState = {
    notifications: Array<Notification>,
    pushNotification: (notification: string) => void,
};

const initialNotificationsState: NotificationsState = {
    notifications: [],
    pushNotification: () => {},
};

const defaultDuration = 5000;

export const NotificationsContext = React.createContext(initialNotificationsState);

export const NotificationsProvider = ({ children } : { children: React.ReactNode}) => {
    const [notifications, setNotifications] = React.useState(initialNotificationsState.notifications);

    const pushNotification = (notification: string, duration?: number) => {
        const id = v4();
        setNotifications(oldNotifications => [
            ...oldNotifications,
            {
                id,
                message: notification,
                show: true,
            }
        ]);

        setTimeout(() => { hideNotification(id); }, duration ?? defaultDuration);
        setTimeout(() => { expireNotification(id); }, (duration ?? defaultDuration) * 2);
    }

    const hideNotification = (id: string) => {
        setNotifications(oldNotifications => oldNotifications.map(notification => {
            if(notification.id === id) {
                return {
                    ...notification,
                    show: false,
                }
            }
            return notification;
        }));
    }

    const expireNotification = (id: string) => {
        setNotifications(oldNotifications => oldNotifications.filter(notification => notification.id !== id));
    }

    return (
        <NotificationsContext.Provider value={{ notifications, pushNotification }}>
            {children}
        </NotificationsContext.Provider>
    );
};