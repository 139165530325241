import React from "react";
import { DateTime } from "luxon";
import { AccessTime, LocationOn } from "@mui/icons-material";
import { SyncMouseEventHandler } from "../../shared";
import "./eventcard.scss";

interface EventCardStyleProps extends React.CSSProperties {
    "--card-size": string | number,
    "--font-size": string | number
}

interface EventCardProps {
    style: EventCardStyleProps,
    imageUrl?: string,
    name: string,
    date: string,
    location: string,
    onClick?: SyncMouseEventHandler<HTMLDivElement>,
}

const EventCard = (props: EventCardProps) => {
    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if(props.onClick !== undefined) {
            props.onClick(e);
        }
        e.preventDefault();
    }

    return <div className="event-card" style={props.style} onClick={onClick}>
        <div className="event-card-image"><img src={props.imageUrl} /></div>
        <div className="event-card-name">{props.name}</div>
        <div className="event-card-date"><span className="icon"><AccessTime sx={{width: props.style["--font-size"], height: props.style["--font-size"]}} /></span> {DateTime.fromMillis(Date.parse(props.date)).toFormat("ccc, d LLL  • HH:mm", { locale: "pt-br" })}</div>
        <div className="event-card-location"><span className="icon"><LocationOn sx={{width: props.style["--font-size"], height: props.style["--font-size"]}} /></span> {props.location}</div>
    </div>;
}

export { EventCard };