import React from "react";
import { CalendarMonthOutlined, ConfirmationNumberOutlined, Key, Login, MailOutline, NotificationsOutlined, ShoppingCartOutlined, SwapHoriz, WhatsApp } from "@mui/icons-material";
import { BinarySwitch, ListTile, ScreenLoader } from "../../components";
import { View, SETTINGS_ID, NOTIFICATIONS_SETTINGS_ID, ApiNotificationError, SIGNIN_ID } from "../../shared";
import { ApiContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./notificationssettingsview.scss";

interface NotificationsSettings {
    login: boolean,
    passwordChange: boolean,
    orderApproved: boolean,
    orderCompleted: boolean,
    eventReminder: boolean,
    transfer: boolean,
}

const EmptyNotificationSettings: NotificationsSettings = {
    login: false,
    passwordChange: false,
    orderApproved: false,
    orderCompleted: false,
    eventReminder: false,
    transfer: false,
};

const notificationSettingsToList = (settings: NotificationsSettings) => {
    const ignoreList = [];
    if(!settings.login) { ignoreList.push("Login"); }
    if(!settings.passwordChange) { ignoreList.push("PasswordChange"); }
    if(!settings.orderApproved) { ignoreList.push("OrderApproved"); }
    if(!settings.orderCompleted) { ignoreList.push("OrderCompleted"); }
    if(!settings.eventReminder) { ignoreList.push("EventReminder"); }
    if(!settings.transfer) { ignoreList.push("TransferCreated", "TransferCanceled", "TransferCompleted"); }
    return ignoreList;
}

class NotificationsSettingsView extends View {
    id = NOTIFICATIONS_SETTINGS_ID;
    route = "/settings/notifications";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => this.navigation!.goTo(this.navigation!.views[SETTINGS_ID]),
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

        const [emailNotifications, setEmailNotifications] = React.useState<NotificationsSettings>(EmptyNotificationSettings);
        const [whatsAppNotifications, setWhatsAppNotifications] = React.useState<NotificationsSettings>(EmptyNotificationSettings);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { views, goTo, location } = this.navigation = React.useContext(NavigationContext);

        const handleError = <T,>(response: T) => {
            return (error: ApiNotificationError) => {
                switch(error.errorType) {
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
                        break;
                }
                return response;
            }
        }

        const loadNotificationSettings = async () => {
            const failableSettings = await api.notification.getNotificationSettings();
            return failableSettings.match({
                success: settings => settings,
                failure: handleError({emailIgnore: [], whatsAppIgnore: []}),
            });
        }

        const init = () => {
            loadNotificationSettings().then(settings => {
                [{
                    setter: setEmailNotifications,
                    data: settings.emailIgnore,
                },{
                    setter: setWhatsAppNotifications,
                    data: settings.whatsAppIgnore,
                }].map(channel => {
                    channel.setter({
                        login: channel.data.indexOf("Login") === -1,
                        passwordChange: channel.data.indexOf("PasswordChange") === -1,
                        orderApproved: channel.data.indexOf("OrderApproved") === -1,
                        orderCompleted: channel.data.indexOf("OrderCompleted") === -1,
                        eventReminder: channel.data.indexOf("EventReminder") === -1,
                        transfer: channel.data.indexOf("TransferCreated") === -1 || channel.data.indexOf("TransferCanceled") === -1 || channel.data.indexOf("TransferCompleted") === -1,
                    });
                });
                setFirstLoad(false);
            });
        }

        React.useEffect(init, []);

        const changeLoginHandle = (set: React.Dispatch<React.SetStateAction<NotificationsSettings>>, save: (notificationSettings: NotificationsSettings) => Promise<boolean>) => {
            return async (e: React.FormEvent<HTMLInputElement | HTMLDivElement>) => {
                set(oldValue => {
                    const newValue = {
                        ...oldValue,
                        login: !oldValue.login
                    };
                    save(newValue);
                    return newValue;
                });
                e.preventDefault();
            }
        }

        const changePasswordChangeHandle = (set: React.Dispatch<React.SetStateAction<NotificationsSettings>>, save: (notificationSettings: NotificationsSettings) => Promise<boolean>) => {
            return async (e: React.FormEvent<HTMLInputElement | HTMLDivElement>) => {
                set(oldValue => {
                    const newValue = {
                        ...oldValue,
                        passwordChange: !oldValue.passwordChange
                    };
                    save(newValue);
                    return newValue;
                });
                e.preventDefault();
            }
        }

        const changeOrderApprovedHandle = (set: React.Dispatch<React.SetStateAction<NotificationsSettings>>, save: (notificationSettings: NotificationsSettings) => Promise<boolean>) => {
            return async (e: React.FormEvent<HTMLInputElement | HTMLDivElement>) => {
                set(oldValue => {
                    const newValue = {
                        ...oldValue,
                        orderApproved: !oldValue.orderApproved
                    };
                    save(newValue);
                    return newValue;
                });
                e.preventDefault();
            }
        }

        const changeOrderCompletedHandle = (set: React.Dispatch<React.SetStateAction<NotificationsSettings>>, save: (notificationSettings: NotificationsSettings) => Promise<boolean>) => {
            return async (e: React.FormEvent<HTMLInputElement | HTMLDivElement>) => {
                set(oldValue => {
                    const newValue = {
                        ...oldValue,
                        orderCompleted: !oldValue.orderCompleted
                    };
                    save(newValue);
                    return newValue;
                });
                e.preventDefault();
            }
        }

        const changeEventReminderHandle = (set: React.Dispatch<React.SetStateAction<NotificationsSettings>>, save: (notificationSettings: NotificationsSettings) => Promise<boolean>) => {
            return async (e: React.FormEvent<HTMLInputElement | HTMLDivElement>) => {
                set(oldValue => {
                    const newValue = {
                        ...oldValue,
                        eventReminder: !oldValue.eventReminder
                    };
                    save(newValue);
                    return newValue;
                });
                e.preventDefault();
            }
        }

        const changeTransferHandle = (set: React.Dispatch<React.SetStateAction<NotificationsSettings>>, save: (notificationSettings: NotificationsSettings) => Promise<boolean>) => {
            return async (e: React.FormEvent<HTMLInputElement | HTMLDivElement>) => {
                set(oldValue => {
                    const newValue = {
                        ...oldValue,
                        transfer: !oldValue.transfer
                    };
                    save(newValue);
                    return newValue;
                });
                e.preventDefault();
            }
        }

        const saveEmailNotificationSettings = async (notificationSettings: NotificationsSettings) => {
            const failableUpdate = await api.notification.updateEmailNotificationSettings(notificationSettingsToList(notificationSettings));
            return failableUpdate.match({
                success: success => {
                    if(success) {
                        //pushNotification("Configurações atualizadas com sucesso!");
                    } else {
                        //pushNotification("Erro ao atualizar configurações.");
                    }
                    return success;
                },
                failure: handleError(false),
            });
        }

        const saveWhatsAppNotificationSettings = async (notificationSettings: NotificationsSettings) => {
            const failableUpdate = await api.notification.updateWhatsAppNotificationSettings(notificationSettingsToList(notificationSettings));
            return failableUpdate.match({
                success: success => {
                    if(success) {
                        //pushNotification("Configurações atualizadas com sucesso!");
                    } else {
                        //pushNotification("Erro ao atualizar configurações.");
                    }
                    return success;
                },
                failure: handleError(false),
            });
        }

        const tileStyle = {"--tile-size": "52px"};
        const leadingStyle = {width: 28};

        return firstLoad ? <ScreenLoader /> : <div id="notifications-settings">
            <div className="notifications-settings-prompt">
                <h2><NotificationsOutlined />Notificações</h2>
                <p>Escolha quais notificações você quer receber e por onde devo mandá-las. A idéia é te manter bem informadx sem te encher o saco!</p>
            </div>
            <h3><MailOutline sx={leadingStyle} /> Notificações por E-mail</h3>
            <div className="notifications-settings-group">
                {/*<ListTile separator={false} leading={<Login sx={leadingStyle} />} title="Login na conta" trailing={<BinarySwitch checked={emailNotifications.login} onClick={changeLoginHandle(setEmailNotifications, saveEmailNotificationSettings)} />} style={tileStyle} onClick={changeLoginHandle(setEmailNotifications, saveEmailNotificationSettings)} />
                <ListTile separator={false} leading={<Key sx={leadingStyle} />} title="Alteração de senha" trailing={<BinarySwitch checked={emailNotifications.passwordChange} onClick={changePasswordChangeHandle(setEmailNotifications, saveEmailNotificationSettings)} />} style={tileStyle} onClick={changePasswordChangeHandle(setEmailNotifications, saveEmailNotificationSettings)} />
                <ListTile separator={false} leading={<ShoppingCartOutlined sx={leadingStyle} />} title="Compras efetuadas" trailing={<BinarySwitch checked={emailNotifications.orderApproved} onClick={changeOrderApprovedHandle(setEmailNotifications, saveEmailNotificationSettings)} />} style={tileStyle} onClick={changeOrderApprovedHandle(setEmailNotifications, saveEmailNotificationSettings)} />*/}
                <ListTile separator={false} leading={<ConfirmationNumberOutlined sx={leadingStyle} />} title="Ingressos adquiridos" trailing={<BinarySwitch checked={emailNotifications.orderCompleted} onClick={changeOrderCompletedHandle(setEmailNotifications, saveEmailNotificationSettings)} />} style={tileStyle} onClick={changeOrderCompletedHandle(setEmailNotifications, saveEmailNotificationSettings)} />
                {/*<ListTile separator={false} leading={<CalendarMonthOutlined sx={leadingStyle} />} title="Proximidade do evento" trailing={<BinarySwitch checked={emailNotifications.eventReminder} onClick={changeEventReminderHandle(setEmailNotifications, saveEmailNotificationSettings)} />} style={tileStyle} onClick={changeEventReminderHandle(setEmailNotifications, saveEmailNotificationSettings)} />*/}
                <ListTile separator={false} leading={<SwapHoriz sx={leadingStyle} />} title="Transferência de ingresso" trailing={<BinarySwitch checked={emailNotifications.transfer} onClick={changeTransferHandle(setEmailNotifications, saveEmailNotificationSettings)} />} style={tileStyle} onClick={changeTransferHandle(setEmailNotifications, saveEmailNotificationSettings)} />
            </div>
            <h3><WhatsApp sx={leadingStyle} /> Notificações por WhatsApp</h3>
            <div className="notifications-settings-group">
                {/*<ListTile separator={false} leading={<Login sx={leadingStyle} />} title="Login na conta" trailing={<BinarySwitch checked={whatsAppNotifications.login} onClick={changeLoginHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />} style={tileStyle} onClick={changeLoginHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />
                <ListTile separator={false} leading={<Key sx={leadingStyle} />} title="Alteração de senha" trailing={<BinarySwitch checked={whatsAppNotifications.passwordChange} onClick={changePasswordChangeHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />} style={tileStyle} onClick={changePasswordChangeHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />
                <ListTile separator={false} leading={<ShoppingCartOutlined sx={leadingStyle} />} title="Compras efetuadas" trailing={<BinarySwitch checked={whatsAppNotifications.orderApproved} onClick={changeOrderApprovedHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />} style={tileStyle} onClick={changeOrderApprovedHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />*/}
                <ListTile separator={false} leading={<ConfirmationNumberOutlined sx={leadingStyle} />} title="Ingressos adquiridos" trailing={<BinarySwitch checked={whatsAppNotifications.orderCompleted} onClick={changeOrderCompletedHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />} style={tileStyle} onClick={changeOrderCompletedHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />
                {/*<ListTile separator={false} leading={<CalendarMonthOutlined sx={leadingStyle} />} title="Proximidade do evento" trailing={<BinarySwitch checked={whatsAppNotifications.eventReminder} onClick={changeEventReminderHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />} style={tileStyle} onClick={changeEventReminderHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />*/}
                <ListTile separator={false} leading={<SwapHoriz sx={leadingStyle} />} title="Transferência de ingresso" trailing={<BinarySwitch checked={whatsAppNotifications.transfer} onClick={changeTransferHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />} style={tileStyle} onClick={changeTransferHandle(setWhatsAppNotifications, saveWhatsAppNotificationSettings)} />
            </div>
        </div>;
    }
}

export { NotificationsSettingsView };