import React from "react";
import { useSearchParams } from "react-router-dom";
import { Phone } from "@mui/icons-material";
import { MainButton, ScreenLoader, TextInput } from "../../components";
import { CHANGE_PHONE_ID, HOME_ID, PhoneFormatter, REGISTER_PHONE_ID, SIGNIN_ID, View } from "../../shared";
import { ApiContext, NavigationContext, NotificationsContext } from "../../contexts";
import "./registerphoneview.scss";

class RegisterPhoneView extends View {
    id = REGISTER_PHONE_ID;
    route = "/settings/profile/register-phone";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: null,
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [email, setEmail] = React.useState<string>("");
        const [phone, setPhone] = React.useState<string>("");
        const [phoneCursor, setPhoneCursor] = React.useState<number>(0);
        const phoneRef = React.useRef<HTMLInputElement>(null);
        const phoneFormatter = new PhoneFormatter();

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { views, goTo, navigate, routeBuilder } = this.navigation = React.useContext(NavigationContext);

        const [searchParams] = useSearchParams();

        React.useEffect(() => {
            api.customer.getCustomerData().then(failableCustomerData => {
                failableCustomerData.match({
                    success: customerData => {
                        setEmail(customerData.email);
                        setFirstLoad(false);
                    },
                    failure: () => goTo(views[SIGNIN_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined)
                });
            });
        }, []);

        const changePhoneHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = phoneFormatter.updateValue({text: phone, cursor: phoneCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setPhone(updatedValue.text);
            setPhoneCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = phoneRef.current;
            if(input != null) {
                input.setSelectionRange(phoneCursor, phoneCursor);
            }
        }, [phoneRef, phoneCursor, phone]);

        const registerPhone = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const unmaskedPhone = phone.length > 0 && phone[0] == "+" ? phoneFormatter.unmask(phone) : `+55${phoneFormatter.unmask(phone)}`;
            const failableRequestPhoneChange = await api.customer.requestPhoneChange(unmaskedPhone);
            failableRequestPhoneChange.match({
                success: phoneChangeRequested => {
                    if(phoneChangeRequested) {
                        sessionStorage.setItem("register", JSON.stringify({
                            reason: "confirmPhone",
                            phone: unmaskedPhone,
                            email: email,
                        }));
                        goTo(views[CHANGE_PHONE_ID], undefined,  { ref: !!searchParams.get("ref") ? searchParams.get("ref")! : views[HOME_ID].route });
                    } else {
                        pushNotification("Verifique se o número de telefone está correto, por favor.");
                    }
                },
                failure: error => {
                    switch(error.errorType) {
                        case "NO_AUTH":
                        case "AUTH_EXPIRED":
                            goTo(views[SIGNIN_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined);
                            break;
                    }
                }
            });
            e.preventDefault();
        }

        const skip = (e: React.MouseEvent<HTMLAnchorElement>) => {
            const ref = searchParams.get("ref");
            if(!!ref && routeBuilder?.find(ref) !== "/") {
                navigate(ref);
            } else {
                goTo(views[HOME_ID]);
            }
            e.preventDefault();
        }

        return firstLoad ? <ScreenLoader /> : <div id="register-phone">
            <div className="register-phone-prompt">Informe seu celular para concluir o cadastro:</div>
            <TextInput ref={phoneRef} id="register-phone-number" name="phone" inputMode="tel" value={phone} prefix={<Phone sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="(11) 99999-9999" onChange={changePhoneHandle} />
            <MainButton enabled={phoneFormatter.unmask(phone).length >= 10} content="Cadastrar celular" onClick={registerPhone} />
        </div>;
    }
}

export { RegisterPhoneView };