import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ScreenLoader } from "../../components";
import { View, QUEUE_ID, HOME_ID, EVENT_ID } from "../../shared";
import { NavigationContext, QueueContext } from "../../contexts";
import "./queueview.scss";

class QueueView extends View {
    id = QUEUE_ID;
    route = "/queue/:eventId";
    defaultRoute = false;
    authNeeded = false;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[EVENT_ID], { eventId: this.params!["eventId"] ?? "" }); },
        supportClick: () => {}
    };
    render = () => {
        const params = this.params = useParams();
        const [searchParams] = useSearchParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [firstPosition, setFirstPosition] = React.useState<number>(-1);
        const [currentPosition, setCurrentPosition] = React.useState<number>(-1);

        const { queues } = React.useContext(QueueContext);
        const { navigate, views, goTo } = this.navigation = React.useContext(NavigationContext);

        const getIds = () => {
            const eventId = params["eventId"];

            if(!eventId) {
                goTo(views[HOME_ID]);
            }

            return { eventId: eventId ?? "" };
        }

        const loadQueuePosition = () => {
            const { eventId } = getIds();

            if(eventId in queues) {
                const eventQueue = queues[eventId];
                return eventQueue.position;
            } else {
                return null;
            }
        }

        React.useEffect(() => {
            const position = loadQueuePosition();
            if(!!position) {
                setFirstPosition(position);
                setCurrentPosition(position);
                setFirstLoad(false);
            } else {
                goTo(views[HOME_ID]);
            }
        }, []);

        React.useEffect(() => {
            const newPosition = loadQueuePosition();
            if(newPosition !== null) {
                setCurrentPosition(newPosition);
            } else {
                goTo(views[HOME_ID]);
            }
        }, [queues]);

        React.useEffect(() => {
            if(currentPosition === 0) {
                const refPath = searchParams.get("ref");
                if(!!refPath) {
                    navigate(refPath);
                } else {
                    navigate(-1);
                }
            }
        }, [currentPosition]);

        const progressPosition = ((firstPosition - currentPosition) / firstPosition) * 286;

        return firstLoad ? <ScreenLoader /> : <div id="queue">
            Sua posição na fila
            <div id="queue-position">{currentPosition}<sup>a</sup></div>
            <div id="queue-walking-guy">
                <div id="queue-waliking-guy-path" style={{width: `${Math.max(0, progressPosition - 15)}px`}}></div>
                <img id="queue-walking-guy-image" src="/images/walking.webp" />
            </div>
            <div id="queue-progressbar-container">
                <div id="queue-progressbar" style={{width: `${progressPosition}px`}}></div>
            </div>
        </div>;
    }
}

export { QueueView };