import { Params } from "react-router-dom";
import { NavigationState } from "../../contexts";

interface BackableHeader {
    backClick: React.MouseEventHandler<HTMLAnchorElement> | null
    supportClick?: React.MouseEventHandler<HTMLAnchorElement>,
}

interface GreetingsHeader {
    profileClick: React.MouseEventHandler<HTMLDivElement>,
    notificationsClick?: React.MouseEventHandler<HTMLDivElement>,
    supportClick?: React.MouseEventHandler<HTMLAnchorElement>,
}

type AppHeader = BackableHeader | GreetingsHeader;

interface SectionRender {
    (): JSX.Element;
    (props: any): JSX.Element;
}

export abstract class View {
    abstract id: string;
    abstract route: string;
    abstract defaultRoute: boolean;
    abstract authNeeded: boolean;
    abstract header?: AppHeader;
    abstract render: SectionRender;

    params?: Params;
    navigation?: NavigationState;
    
    get parsedRoute() {
        let parsedRoute = this.route;
        if(this.params !== undefined) {
            for(let key in this.params) {
                parsedRoute = parsedRoute.replace(`:${key}`, this.params[key] || "");
            }
        }
        return parsedRoute;
    }
}